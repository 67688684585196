import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   SupplierForm extends Component{

    state = {
        id : "",
        name : "",
        contact : "",
        first_name : "",
        last_name : "",
        family_name : "",
        genre : "",
        email : "",
        tel : "",
        identity_number : "",
        title : "",
        yearId : "",
        years : [],
        password : "",
        profileId : "",
        profiles : [],
        error : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeSupplier = () =>{
        var data = {
            profileId : this.state.profileId,
            name : this.state.name,
            contact : this.state.contact,
            first_name : this.state.first_name,
            last_name : this.state.last_name,
            family_name : this.state.family_name,
            genre :  this.state.genre,
            email : this.state.email ? this.state.email : null,
            tel : this.state.tel ? this.state.tel : null,
            identity_number : this.state.identity_number,
            operation : this.state.id ? "Editer" : "Ajouter",
            yearId : this.state.yearId > 0 ? this.state.yearId : null,
        }
        
        var request = this.state.id ? axios.put('suppliers/'+this.state.id, data) : axios.post('suppliers', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    edit=()=>  {
        this.form()
        this.getYear();
        this.profiles();
    }

    async profiles(){
        var request = await axios.get('profiles');
        if(request.status === 200){
            this.setState({profiles : request.data})
            request.data.map((profile) => {
                if(profile.model == "Supplier")
                    this.setState({ profileId : profile.id})
            });
        }
    }

    form=()=>{
        
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            name : this.props.formData ? this.props.formData.name : "",
            contact : this.props.formData ? this.props.formData.contact : "",
            first_name : this.props.formData ? this.props.formData.user.first_name : "",
            last_name : this.props.formData ? this.props.formData.user.last_name : "",
            family_name : this.props.formData ? this.props.formData.user.family_name : "",
            genre : this.props.formData ? this.props.formData.user.genre : "Masculin",
            email : this.props.formData ? this.props.formData.user.email : "",
            tel : this.props.formData ? this.props.formData.user.tel : "",
            yearId : this.props.formData  ? this.props.formData.user.yearId : "",
            identity_number : this.props.formData ? this.props.formData.user.identity_number : "",
            title : this.props.formData ? "Modifier les infomations du fournisseur" : "Ajout du fournisseur",
            error : "",
        })
    }


    render(){
        var optionprofiles = [];
        this.state.profiles.map((profile) => {
            if(profile.model == "Supplier")
                optionprofiles.push({value : profile.id, label : profile.profile })
        });

        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row" style={{  fontSize :  14 + "px" }}>
                        <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="first-name-icon">Nom fournisseur</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" onChange={this.handleInput} name="name" value={this.state.name} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="home"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="contact-id-icon">Contact fournisseur</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="contact"  value={this.state.contact} id="contact-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="phone-forwarded"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Nom responsable</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="first_name"  value={this.state.first_name} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="prenom-id-icon">Prénom responsable</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="last_name"  value={this.state.last_name} id="prenom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Post-nom responsable</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="family_name"  value={this.state.family_name} id="postnom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="email-id-icon">Email responsable</label>
                                <div className="position-relative mt-1">
                                    <input type="email" className="form-control form-control-sm"  onChange={this.handleInput} name="email"  value={this.state.email} id="email-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="mail"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">Numéro d'identité</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm"  onChange={this.handleInput} name="identity_number"  value={this.state.identity_number} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">Numéro de téléphone</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm" placeholder="" onChange={this.handleInput} name="tel"  value={this.state.tel} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="phone-call"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className=" col-md-4 col-6 mt-2">
                            <label className="font-monospace fw-normal">Année scolaire</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        this.state.years.filter(option => 
                                        option.value == this.state.yearId)
                                    }
                                    defaultValue={this.state.yearId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                yearId : select.value
                                            });
                                        }
                                    }
                                    options={this.state.years}
                                />
                            </div>
                        </div>

                        <div className="form-group col-md-4 col-6 mt-2 d-none">
                            <label className="font-monospace fw-normal">Profile</label>
                            <div className="input-group">
                                <Select
                                    className="form-control"
                                    value = {
                                        optionprofiles.filter(option => 
                                        option.value == this.state.profileId)
                                    }
                                    defaultValue={this.state.profileId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                profileId : select.value,
                                            });
                                        }
                                    }
                                    options={optionprofiles}
                                />
                            </div>
                        </div>
                        
                        <div className=" col-md-4 col-6 mt-2">
                            <label className="font-monospace fw-normal">Sexe responsable</label>
                            <div className="input-group pt-2">
                                <input type={"radio"} name="genre" value={"Masculin"} checked={this.state.genre === "Masculin" ? true : false} onChange={this.handleInput} id="masculin" className="rounded m-2" /> <label htmlFor="masculin">Masculin</label>
                                <input type={"radio"} name="genre" value={"Feminin"} checked={this.state.genre == "Feminin" ? true : false} onChange={this.handleInput} id="feminin" className="rounded m-2" /> <label htmlFor="feminin">Féminin</label>
                            </div>
                            
                        </div>
                        <hr />

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeSupplier()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
        </div>
        )
    }
}
export default SupplierForm;