import React, {Component} from "react";
import axios from "axios";
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from "react-to-print";
import UserIdentity from "../../users/UserIdentity";
import Headerpdf from "../../users/Headerpdf";

class Reportcard extends Component {
    state = {
        studentreports : {
            students : []
        }
    }

    componentDidMount(){
        this.getStudentreports()
    }
    async getStudentreports(){

        var studentreports = await axios.get('/users/students/'+this.props.user)
        if(studentreports.status === 200)
        {
            this.setState({
                studentreports : studentreports.data
            })
        }
    }

    render(){
        var year = null, dataStudents = 
        this.state.studentreports.students.map((student, index) => {
            if(year == null)
                year = student.year ? student.year.year : null;
            return(
                <span  key={student.id}>
                <li className={" widget-todo-item list-group-item m-2 border rounded-3"} >
                    <div
                        className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                        <div className="widget-todo-title-area d-flex align-items-center">
                            <FeatherIcon icon="grid" className='cursor-move'></FeatherIcon>
                            <div className="checkbox checkbox-shadow">
                                <input type="checkbox" className="form-check-input" id={student.id}  />
                            </div>
                            <label htmlFor={student.id}><span className="widget-todo-title ml-50">{student.sectionclass.class.short_entitled_class} {student.sectionclass.title} # {student.sectionclass.section.short_entitled_section}</span></label>
                        </div>
                        <a href={ process.env.REACT_APP_EVIVESOMA_STAT +"/students/"+student.id} className="text-decoration-none"><span className={ student.studentsuccess === 0 ? "widget-todo-title ml-50 text-danger" : "widget-todo-title ml-50 text-success" }>{student.year ? student.year.year : ""} </span></a>
                        <div className="widget-todo-item-action d-flex align-items-center">
                            <a href="#" className="table_action" type="button" onClick={() => window.open(process.env.REACT_APP_EVIVESOMA_STAT + "/students/"+student.id,"Ratting","width=950, height=500,left=200,top=200,toolbar=no,menubar=no,scrollbars=no,resizable=0,location=no,directories=no,status=no")}>
                                <FeatherIcon icon="chevrons-right" size="16" className='cursor-move text-primary' type="button"></FeatherIcon>
                                { student.studentmax > 0 ? ((student.studentsuccess * 100 ) / student.studentmax).toFixed(1)+"%" : "0%"}
                                <FeatherIcon icon="file" size="16" className='cursor-move text-primary' type="button"></FeatherIcon>
                            </a>
                        </div>
                    </div>
                </li>
                {
                    index == this.state.studentreports.length -1 && (
                        <li className={" widget-todo-item list-group-item m-2 border border-primary rounded-3"} >
                            <div
                                className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                <div className="widget-todo-title-area d-flex align-items-center">
                                    <FeatherIcon icon="layers" className='cursor-move'></FeatherIcon>
                                    <div className="checkbox checkbox-shadow">
                                        <input type="checkbox" className="form-check-input" id={index}  />
                                    </div>
                                    <label htmlFor={student.id}><span className="widget-todo-title ml-50">Tous</span></label>
                                </div>
                                <a href={"#https://evivesomarh.vercel.app/students/list/"+this.props.user} className="text-decoration-none"><span className={"widget-todo-title ml-50 text-primary" }>{ year + " <> "+ student.scolary_year} </span></a>
                                <div className="widget-todo-item-action d-flex align-items-center">
                                    <a href={"#https://evivesomarh.vercel.app/students/list/"+this.props.user} className="text-decoration-none">
                                        <FeatherIcon icon="chevrons-right" size="16" className='cursor-move text-primary' type="button"></FeatherIcon>
                                    </a>
                                </div>
                            </div>
                        </li>
                    )
                }
                </span>
            )
        })
        return(
            <div>
                <div className="text-end">
                    <ReactToPrint
                        trigger={() => <button className="btn btn-sm btn-outline-primary rounded rounded-3" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                        content={() =>  this.componentRef}
                    />
                </div>
                <ul className="widget-todo-list-wrapper p-0" ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    {
                        <UserIdentity matricule ={this.props.matricule} name={this.props.name} classe={this.props.classe} title={this.props.title} /> 
                    }
                    <div className="print-customer">
                        { dataStudents }
                    </div>
                </ul>
            </div>
        )
    }
}
export default Reportcard;