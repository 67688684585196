import React, { Component} from "react";
import { Helmet } from 'react-helmet';
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import { Link } from 'react-router-dom';
import Listprint from "../../Listprint";
import Loading from "../../Loading";
class Report extends Component{

    state = {
        loading : false,
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Elève',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Statut',
                field: 'status',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Section',
                field: 'section',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Parent',
                field: 'parent',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Année',
                field: 'scolary_year',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            }
        ],
        rows : []

    }

    componentDidMount(){
        this.getStudents()
    }
    
    async getStudents(){
        const students = await axios.get('students');
        if(students.status == 200)
        {
            var rows = [];
            students.data.map((student, index) => {
                var commitment_date = new Date(student.createdAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                var last_name = student.user ? student.user.last_name : "";
                var first_name = student.user ? student.user.first_name : "";
                var family_name = student.user ? student.user.family_name : "";
                rows.push({
                    matricule : student.user && ( <Link to={"/students/"+student.id} className="text-primary text-decoration-none h"> {student.matricule}</Link>),
                    student : last_name + " " + family_name + " " + first_name,
                    status : student.status == 1 ? <span className="badge text-primary alert-primary" >Actif</span> : <span className={ student.status == 0 ? "badge text-danger alert-danger" : "badge text-warning alert-warning"} > { student.status == 0 ? "Abandon" : "Non actif" }</span>,
                    parent : student.user.user.last_name + " " + student.user.user.first_name,
                    classe : student.sectionclass.class.short_entitled_class + " " + student.sectionclass.title,
                    section : student.sectionclass.section.short_entitled_section,
                    scolary_year : student.year.year,
                    created_at : commitment_date
                })
             })
             this.setState({
                 rows : rows,
                 loading : true
             })
        }
    }

    render(){

        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | report students</title>
                </Helmet>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom mb-2">
                    <h5 className="h5 font-monospace">Rapport actuel</h5>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        
                        <button type="button" className="btn btn-sm btn-outline-teal dropdown-toggle">
                            <FeatherIcon icon="calendar"  size="17" className={"text-dark"}></FeatherIcon>
                            Année actuelle
                        </button>
                    </div>
                </div>

                <div>
                    <div className="overflow-hidden" style={{  fontSize : 14 + "px" }}>
                        
                        {
                            this.state.rows.length > 0 && (
                                <Listprint columns={this.state.columns} rows={this.state.rows} title={"Rapport des élèves "}/>
                            )
                        }
                        {
                            !this.state.loading && (<Loading />)
                        }
                    </div>
                </div>

            </div>
        )
    }
}
export default Report;