import axios from "axios";
import React, {Component} from "react";
import ParentfamilyForm from "./ParentfamilyForm";
import FeatherIcon from 'feather-icons-react';
import EstablishmentprofileData from "../../../components/rh/establishmentprofiles/EstablishmentprofileData";
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";
import Profile from "../profiles/Profile";
import { toast } from "react-toastify";

class   Parentfamily extends Component{

    state = {
        isModal : false,
        loading : false,
        isModalProfile : false,
        profileModal : false,
        user : "",
        userestablishprofile : "",
        formData : "",
        parentfamilies : {
                columns : [
                {
                    label: '#',
                    field: 'number',
                    sort: 'asc',
                    width: 10
                },
                {
                    label: 'Nom et prénom',
                    field: 'name',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Genre',
                    field: 'genre',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Téléphone',
                    field: 'tel',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: 'Profession',
                    field: 'profession',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: 'Adresse',
                    field: 'adress',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: '#',
                    field: 'action',
                    sort: 'asc',
                    width: 120
                }
            ],
            rows : []
        },

    }

    componentDidMount(){
        this.getParentfamilies();
    }

    async getParentfamilies(){
        this.setState({
            parentfamilies : {
                rows : [],
                columns : this.state.parentfamilies.columns
            },
        })

        var parentfamilies = await axios.get('parentfamilies');
        if(parentfamilies.status === 200)
        {
            this.setState({rows : []});
            var data = [];
            parentfamilies.data.map((parentfamily, index) => {
                
                data.push(
                    {
                        number : <span><img src={parentfamily.user && (parentfamily.user.avatar)} className="rounded img-fluid" width={parentfamily.user && (parentfamily.user.avatar && ("30"))} /></span>,
                        name : parentfamily.user && (parentfamily.user.last_name + " "+ parentfamily.user.first_name),
                        genre : parentfamily.user && (parentfamily.user.genre),
                        email : parentfamily.user && (parentfamily.user.email),
                        tel : parentfamily.user && (parentfamily.user.tel),
                        profession : parentfamily.user && (parentfamily.user.profession),
                        adress : parentfamily.user && (parentfamily.user.adress),
                        action : <span className="table_action"> 
                            {
                                this.handleCheck("Modifier parent") == true && (   
                                    <button onClick={ () => this.showModal(parentfamily)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={"13"}></FeatherIcon> </button>
                                    )
                            }
                            {
                                this.handleCheck("Supprimer parent") == true && (
                                    <button onClick={ () => this.delete(parentfamily.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                )
                            }       
                            </span>,
                    }
                )
            })

            

            this.setState({
                parentfamilies : {
                    columns : this.state.parentfamilies.columns,
                    rows : data,
                },
                loading : true,
            })
        }
    }

    showModal = (parentfamily = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : parentfamily,
        })
        this.getParentfamilies()
    }

    delete = (parentfamily) =>{
        axios.delete("parentfamilies/"+parentfamily).then((response) => {
            this.setState({ columns : []})
            this.getParentfamilies()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    showModalProfile = (userestablishprofile = null, user = null) => {
        this.setState({
            isModalProfile : !this.state.isModalProfile,
            userestablishprofile : userestablishprofile,
            user : user,
        })
        this.getParentfamilies()
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | parents students</title>
                </Helmet>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste des parents</h5>
                {
                    this.handleCheck("Ajouter parent") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary p-0 px-1 rounded-3" onClick={() => this.showModal(null)}><FeatherIcon icon="user-plus"></FeatherIcon> parent</button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="overflow-hidden">
                {
                    !this.state.loading && (<Loading />)
                }

                {
                    ( !this.state.profileModal && this.state.parentfamilies.rows.length > 0 ) && (
                        <Listprint columns={this.state.parentfamilies.columns} rows={this.state.parentfamilies.rows} title={"Liste des parents"}/>
                    )
                }
            </div>

            <ParentfamilyForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />

            <EstablishmentprofileData
                isModal={this.state.isModalProfile} 
                formData={this.state.userestablishprofile}
                fromUser = {this.state.user}
                showModal={this.showModalProfile}
             />
        </div>
        )
    }
}
export default Parentfamily;