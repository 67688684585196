import axios from "axios";
import React, {Component} from "react";
import SupplierForm from "./SupplierForm";
import FeatherIcon from 'feather-icons-react';
import EstablishmentprofileData from "../../../components/rh/establishmentprofiles/EstablishmentprofileData";
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import { toast } from "react-toastify";

class   Supplier extends Component{

    state = {
        isModal : false,
        isModalProfile : false,
        user : "",
        userestablishprofile : "",
        formData : "",
        suppliers : {
                columns : [
                {
                    label: '#',
                    field: 'number',
                    sort: 'asc',
                    width: 50
                },
                {
                    label: 'Fournisseur',
                    field: 'supplier',
                    sort: 'asc',
                    width: 200
                },
                {
                    label: 'Contact',
                    field: 'contact',
                    sort: 'asc',
                    width: 120
                },
                {
                    label: 'Responsable',
                    field: 'responsible',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Téléphone',
                    field: 'tel',
                    sort: 'asc',
                    width: 120
                },
                // {
                //     label: 'Profile',
                //     field: 'profiles',
                //     sort: 'asc',
                //     width: 120
                // },
                {
                    label: 'Action',
                    field: 'action',
                    sort: 'asc',
                    width: 120
                }
            ],
            rows : []
        },
    }

    componentDidMount(){
        this.getSuppliers();
    }

    async getSuppliers(){
        
        this.setState({
            suppliers : {
                rows : [],
                columns : this.state.suppliers.columns
            },
        })

        var suppliers = await axios.get('suppliers');
        if(suppliers.status === 200)
        {
            var data = [];
            suppliers.data.map((supplier, index) => {
                data.push(
                    {
                        number : index+1,
                        supplier : supplier.name,
                        contact : supplier.contact,
                        responsible : <span><img src={supplier.user && (supplier.user.avatar)} className="rounded img-fluid" width={supplier.user && (supplier.user.avatar && ("30"))} />{supplier.user && (supplier.user.first_name + " " +supplier.user.last_name)}</span>,
                        tel : supplier.user && (supplier.user.tel),
                        
                        action : <span className="table_action">
                                    {
                                        this.handleCheck("Modifier fournisseur") == true && (
                                            <button onClick={ () => this.showModal(supplier)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={"13"}></FeatherIcon> </button>
                                        )
                                    }
                                    {
                                        this.handleCheck("Supprimer fournisseur") == true && (
                                            <button onClick={ () => this.delete(supplier.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                        )
                                    }
                                </span>,
                    }
                )
            })
            this.setState({
                suppliers : {
                    columns : this.state.suppliers.columns,
                    rows : data
                },
            })
        }
    }

    showModal = (supplier = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : supplier,
        })
        this.getSuppliers()
    }

    delete = (supplier) =>{
        axios.delete("suppliers/"+supplier).then((response) => {
            this.setState({ columns : []})
            this.getSuppliers()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    showModalProfile = (userestablishprofile = null, user = null) => {
        this.setState({
            isModalProfile : !this.state.isModalProfile,
            userestablishprofile : userestablishprofile,
            user : user,
        })
        this.getSuppliers()
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | suppliers</title>
                </Helmet>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste des fournisseurs</h5>
                {
                    this.handleCheck("Ajouter fournisseur") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus"></FeatherIcon> fournisseur</button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="overflow-hidden">
                {
                    this.state.suppliers.rows.length > 0 && (
                        <Listprint columns={this.state.suppliers.columns} rows={this.state.suppliers.rows} title={"Liste des fournisseurs"}/>
                    )
                }
            </div>

            <SupplierForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            
            <EstablishmentprofileData
                isModal={this.state.isModalProfile} 
                formData={this.state.userestablishprofile}
                fromUser = {this.state.user}
                showModal={this.showModalProfile}
             />

        </div>
        )
    }
}
export default Supplier;