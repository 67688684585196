import axios from "axios";
import React, {Component} from "react";
import SectionclasseForm from "./SectionclasseForm";
import FeatherIcon from 'feather-icons-react';
import Classe from "../classes/Classe";
import Listprint from "../../Listprint";
import StudentLine from "../students/StudentLine";
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";
import { toast } from "react-toastify";

class   Sectionclasse extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        sections : [],
        data : {
            columns : [
                {
                    label: 'Classe',
                    field: 'classe',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Section',
                    field: 'section',
                    sort: 'asc',
                    width: 150
                },
                {
                    label: 'Eleves',
                    field: 'student',
                    sort: 'asc',
                    width: 80
                },
                {
                    label: 'Opération',
                    field: 'operation',
                    sort: 'asc',
                    width: 80
                },
                {
                    label: 'Date',
                    field: 'updated_at',
                    sort: 'asc',
                    width: 100
                },
            ],
            rows : [],
        }
    }

    componentDidMount(){
        this.getSections();
        this.getUpdated()
    }

    async getSections(){
        var sections = await axios.get('sections');
        
        if(sections.status === 200)
        {
            this.setState({
                sections : sections.data,
                loading : true
            })
        }
    }

    async getUpdated(){
        var sectionclasses = await axios.get('sectionclasses/operation/operation');
        
        if(sectionclasses.status === 200)
        {
            var data = [];
            sectionclasses.data.map((sectionclasse) =>{
                var commitment_date = new Date(sectionclasse.updatedAt);
                    commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();

                data.push(
                    {
                        classe : sectionclasse.class.short_entitled_class+ " "+sectionclasse.title,
                        section : sectionclasse.section.short_entitled_section,
                        student : sectionclasse.students.length,
                        operation : <span className={ sectionclasse.operation == "Supprimer" ? "border rounded rounded-3 border-1 border-danger text-danger size-13 px-1" : "border rounded rounded-3 border-1 border-primary size-13 px-1"}>{ sectionclasse.operation }</span>,
                        updated_at : <span className="size-13">{commitment_date}</span>,
                    }
                )
            })

            this.setState({
                data : {
                    columns : this.state.data.columns,
                    rows : data
                }
            })
        }
    }


    showModal = (sectionclasse = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : sectionclasse,
        })
        this.getSections()
    }

    delete = (sectionclasse) =>{
        axios.delete("sectionclasses/" + sectionclasse).then((response) => {
            this.getSections()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        
        var data=[], labels = [], sections =
        this.state.sections.map((section) => {

            return( 
                <div className="card mb-2 shadow rounded  overflow-hidden rounded-3 " key={section.id}>
                    <div className="card-body row">
                        <h6 className="card-title font-monospace h6">{section.entitled_section + " " + section.short_entitled_section}</h6>
                        {
                            section.sectionclasses.sort((a, b) => (a.class.short_entitled_class > b.class.short_entitled_class ? 1 : -1)).filter(dta => dta.operation != "Supprimer").map((sectionclasse) => {
                                
                                    labels.push(sectionclasse.class.short_entitled_class + " " + sectionclasse.title + " "+section.short_entitled_section)
                                    data.push(sectionclasse.students.length)
                                    return(
                                        <div className="col-md-4" key={sectionclasse.id}>
                                            <div className="card  mb-2 rounded rounded-3">
                                                <div className="card-body">
                                                    <div>
                                                        <div className="text-center m-2"> <span className="h6 rounded-3 border border-1 border-primary p-2">{sectionclasse.class.short_entitled_class}  {sectionclasse.title} </span> </div>
                                                        <div className="text-center"> <span className="  "><b>{ sectionclasse.students.length}</b>Elèves   </span> </div>
                                                        {/* <div className="text-end text-secondary size-13"> {sectionclasse.scolary_year} </div> */}
                                                        <div className="text-end text-secondary size-13"> {sectionclasse.status} </div>
                                                    </div>
                                                    <div className="text-end me-2">
                                                    {
                                                        this.handleCheck("Modifier classe") == true && (
                                                            <button onClick={ () => this.showModal(sectionclasse)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={13}></FeatherIcon> </button>
                                                        )
                                                    }

                                                    {
                                                        this.handleCheck("Supprimer classe") == true && (
                                                            <button onClick={ () => this.delete(sectionclasse.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="x-octagon" size={13}></FeatherIcon> </button>
                                                        )
                                                    }
                                                    </div> 
                                                </div>
                                            </div>
                                        </div>
                                    )
                            })
                        }
                        
                    </div>
                </div>
 
            )
        })
        return(
            <div className="container" style={{  fontSize : 12 + "px" }}>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | classes</title>
                </Helmet>
                <div className="align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h5 className="h5 font-monospace">Classes disponibles</h5>
                </div>

                <div className="row">

                    <div className="col-md-8">
                        <div className="col-md-12">
                            <div className="card shadow overflow-hidden mb-4">
                                <div className="card-body">
                                <div className="h6 border-bottom pb-2 font-monospace">Les élèves par classe</div>
                                {
                                    labels.length > 0 && ( <StudentLine labels={labels} data={data} title="Les élèves par classe" /> )
                                }
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-12">
                        {
                            this.handleCheck("Ajouter classe") == true && (
                                <div className=" text-end mb-2">
                                    <div className="btn-group me-2">
                                        <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Classe</button>
                                    </div>
                                </div>
                            )
                        }
                            <div className="row">
                                {this.state.loading > 0 ? sections : <Loading />}
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                    {
                        this.handleCheck("Afficher classe") == true && (
                            <div className="card shadow overflow-hidden mb-2">
                                <div className="card-body">
                                    <Classe />
                                </div>
                            </div>
                        )
                    }

                        <div className="card shadow overflow-hidden mb-2">
                            <div className="card-body">
                                <div className="h6 border-bottom pb-1 mb-1 text-center  font-monospace">Dernières opérations</div>
                                
                                {
                                    this.state.data.rows.length > 0 && (
                                        <Listprint columns={this.state.data.columns} rows={this.state.data.rows} title={"Dernières opérations sur les classes"} classe={"Toutes les classes"} />
                                    )
                                }
                            </div>
                        </div>

                    </div>
                </div>

                <SectionclasseForm 
                    isModal={this.state.isModal} 
                    formData={this.state.formData} 
                    showModal={this.showModal}
                />
            

        </div>
        )
    }
}
export default Sectionclasse;