import axios from "axios";
import React, {Component} from "react";
import Listprint from "../../Listprint";

class   Lesson extends Component{

    state = {
        columns : [
            {
                label: 'Section',
                field: 'section',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Cours',
                field: 'lesson',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Max',
                field: 'max',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Heures',
                field: 'week_hour',
                sort: 'asc',
                width: 200
            }
            
        ],
        rows : []
    }

    componentDidMount(){
        this.getSectionclasses();
    }

    async getSectionclasses(){
        var sectionclasselessons = await axios.get('sectionclasselessons/staff/'+this.props.staff);
        var rows = []
        
        if(sectionclasselessons.status === 200)
        { 
            var week_hour = 0;
            sectionclasselessons.data.map((sectionclass) => { 
                sectionclass.sectionclasselessons.map(sectionclasselesson => {
                    week_hour += sectionclasselesson.week_hour > 0 ? sectionclasselesson.week_hour  : 0;
                    rows.push({
                        section : sectionclass.section.short_entitled_section,
                        classe : <span>{sectionclass.class.short_entitled_class } <small className="text-primary size-13">{sectionclass.title}</small></span>,
                        lesson :  sectionclasselesson.lesson.short_entitled_lesson ,
                        max :  sectionclasselesson.max_evaluation ,
                        week_hour :  sectionclasselesson.week_hour 
                    })
                })
            })
        }
        
        this.setState({
            rows : rows, 
            week_hour : week_hour
        })
    }

    render(){
        return(
            <div>
                <div className="font-monospace text-center"><span className="text-danger">{ this.state.week_hour } heures par semaine</span> </div>
               {
                    this.state.rows.length > 0 && (<Listprint columns={this.state.columns} rows={this.state.rows} matricule={this.props.matricule} name={this.props.name} title={this.props.title + " / " +  this.state.week_hour + "  heures par semaine"}/> )
                } 
            </div>
        )
    }
}
export default Lesson;