import React, {Component} from "react";
import axios from "axios";
import FeatherIcon from 'feather-icons-react';

class UserprofileForm extends Component{

    state = {
        dataprofiles : [],
        profiles : [],
        isChecked : [],
    }

    componentDidMount(){
        this.dataprofiles()
    }

    handleInputCheckbox(e, profile) {

        this.state.isChecked[profile.id] = this.state.isChecked[profile.id] ? false : profile.id;
        var val = this.state.isChecked[profile.id];
        this.state.isChecked.splice(profile.id, 1);
        this.state.isChecked[profile.id] = val;
        
        if (this.state.isChecked[profile.id] === false) {
            this.state.profiles.splice(profile.id, 1);
        }
        else {
            this.state.profiles.push(profile.id)
        }

        this.dataprofiles();
        this.props.profiles(this.state.profiles);
    }

    async dataprofiles(){
        var request = await axios.get("profiles");
        if(request.status === 200)
        {
            this.setState({
                dataprofiles : request.data
            })
        }
    }

    render(){
        var userprofiles = 
            this.state.dataprofiles.map((profile) => {
                if(profile.model === this.props.model)
                {
                    return(
                        <span className=" align-items-center m-1 p-1 border font-monospace fw-normal rounded-3" key={profile.id}>
                            <FeatherIcon icon="key" size={"15"} className='cursor-move text-primary'></FeatherIcon> 
                            <span className="checkbox checkbox-shadow">
                                <input type="checkbox" className="form-check-input" checked={this.state.isChecked[profile.id] ? this.state.isChecked[profile.id] : false} onChange={(e) => this.handleInputCheckbox(e, profile)} id={profile.id}  />
                            </span>
                            <label htmlFor={profile.id}><span className="widget-todo-title ml-50">{profile.profile && (profile.profile)}</span></label>
                        </span>
                    )
                }
            })
        return(
            <div className="col-12 mt-2">
                <div className="form-group has-icon-left">
                    <label htmlFor="first-name-icon" className=" font-monospace fw-bolder">Profil<span className="text-danger">*</span></label>
                    <div className="position-relative mt-1">
                        <b>{userprofiles}</b>
                    </div>
                </div>
            </div>
        )
    }
}
export default UserprofileForm