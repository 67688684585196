import axios from "axios";
import FeatherIcon from 'feather-icons-react';
import React, {Component} from "react";
import '../../../assets/css/chat.css'
import { toast } from "react-toastify";

class Remind extends Component{

    state = {
        id : "",
        user : {},
        userRecever : {},
        recever_id : "",
        sms : false,
        gmail : false,
        cocher : true,
        chat : "",
        input : [],
        boxes : [],
        reminds : [],
        users : [],
        error : ""
    }

    componentDidMount(){
        var user = JSON.parse(localStorage.getItem('user'))
        this.setState({
            user : user
        })
        this.getBoxes()
        this.getReminds()
    }
    

    async getBoxes(){
        
        var boxes = await axios.get('parentfamilies')
        if(boxes.status === 200)
        {
            var val = [], input = [];
            boxes.data.map((dat) => {
                val.push(dat.user);
                input.push(dat.user.id);
            })

            this.setState({
                boxes : val,
                input : input,
                users : boxes.data,
            })
        }
    }

    handleInputCocher = (e) => {
        var  input = [];
        this.state.users.map((dat) => {
            input.push(dat.user.id);
        })

        this.setState({
            input : this.state.input.length == 0 ? input : [],
            cocher : !this.state.cocher
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    handleInputsms = (e) => {
        this.setState({
            sms: !this.state.sms
        }); 
    }

    handleInputgmail = (e) => {
        this.setState({
            gmail: !this.state.gmail
        }); 
    }


    handleInputSearch = (e) => {
        var length = e.target.value.length;
        if(length > 0)
        {
            var box = [];
            this.state.users.filter(user => user.user.first_name && user.user.first_name.toLowerCase() == e.target.value.toLowerCase() || user.user.last_name && user.user.last_name.toLowerCase() == e.target.value.toLowerCase() || user.user.email && user.user.email.toLowerCase() == e.target.value.toLowerCase() || user.user.tel && user.user.tel == e.target.value  || user.user.family_name && user.user.family_name.toLowerCase() == e.target.value.toLowerCase()).map((data) =>{
                box.push(data.user);
            });
            this.setState({
                boxes : box
            })
        }
        else{
            this.getBoxes();
        }
    }

    handleInputCheck = (e) => {

    }

    

    async getReminds()
    {
        var reminds = await axios.get('reminds');
        
        if(reminds.status == 200)
        {
            this.setState({
                reminds : reminds.data,
            })
        }
    }

    storeChat() {

        var data = {
            gmail : this.state.gmail,
            sms : this.state.sms,
            receverId : this.state.input,
            chat : this.state.chat,
            read_at : this.state.read_at,
            last : 1,
        }
        
        axios.post('chats', data).then((response) => {
            this.getBoxes()
            this.setState({
                chat : "",
                read_at : "",
                error : "",
                cocher : true
            })
            this.getReminds();
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : "une erreur s'est produite"
            })
            toast('Une erreur s est produite', { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleInputBox = (e) => {
        var data = [];
        var input = this.state.input;
        this.setState({
            input : []
        })
        var box =  (e.target.value);
        var count = input.some(item => box === item)
        if(count)
        {
            data = input.filter(item => item != box);
        }
        else{
            data = input;
            data.push(box)
        }
        
        this.setState({
            input : data
        })
        
    }

    delete = (chat) =>{
        axios.delete("chats/" + chat).then((response) => {
            this.getReminds()
        }).catch((error)=>{})
    }


    render(){
        var exist = [], font="";
        var profile = JSON.parse(localStorage.getItem('profile'));
        
        var establishment = profile ? profile.profile.establishment : {};
        return (
            <div className="container p-0">
                    <div className="h5 font-monospace">Notifier les parents</div>
                    <div className="card">
                        <div className="row g-0">
                            
                                    <div className="col-12 col-lg-5 col-xl-3 border bg-white chat-messages">

                                        <div className="px-4 d-md-block">
                                                <div className="text-primary text-end">
                                                    <input type="checkbox" checked={this.state.cocher}  onChange={this.handleInputCocher} id="check" />
                                                    <label htmlFor="check">Cocher</label>
                                                </div>
                                            <div className="d-flex align-items-center">
                                                <div className="flex-grow-1">
                                                    <input type="text" search="search" onChange={this.handleInputSearch} className="form-control my-3" placeholder="Rechercher..." />
                                                </div>
                                            </div>
                                        </div>
                                        
                                        {
                                            this.state.boxes.sort((a, b) => (a.last_name > b.last_name ? 1 : -1)).map((box) => {
                                                 
                                                if(exist.filter(dat => dat == box.id).length == 0)
                                                {
                                                    if(box.read_at){
                                                        font = ""
                                                    }
                                                    else{
                                                        font = "fw-bolder"
                                                    }
                                                    
                                                    exist.push(box.id)
                                                    return(
                                                        <a href="#" key={box.id} className={ " list-group-item list-group-item-action border-0"}>
                                                            <div className="d-flex align-items-start">
                                                                <img src={ box.avatar} className="rounded-circle mr-1" alt={ box.last_name } width="40" height="40" />
                                                                <div className="flex-grow-1 ml-3">
                                                                    <label htmlFor={box.id}> { box.last_name + " " + box.first_name} </label>
                                                                    <input type={"checkbox"} checked={this.state.input.some(d => d == box.id)}  onChange={this.handleInputBox}  value={box.id } id={box.id} className=" ms-2 rounded rounded-3" />
                                                                    <p className={font + " small"}><small>{ box.chat}</small></p>
                                                                </div>
                                                            </div>
                                                        </a>
                                                    )
                                                }
                                            })
                                        }
                                        <hr className="d-block d-lg-none mt-1 mb-0" />
                                    </div>
                             
                            <div className={"col-12 col-lg-7 col-xl-9"}>
                                <div className="py-2 px-4 border-bottom">
                                    <div className="d-flex align-items-center py-1">
                                        <div className="position-relative">
                                            <img src={ establishment.logo } className="rounded-circle mr-1" alt={ establishment.name } width="40" height="40" />
                                        </div>
                                        <div className="flex-grow-1 pl-3">
                                            <strong className="font-monospace">{ establishment.name} </strong>
                                            <div className="text-muted small"><em>Notification...</em></div>
                                        </div>
                                        <div>
                                            <button className="btn btn-primary btn-sm mr-1 "><svg xmlns="#http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-phone feather-lg"><path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path></svg></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="position-relative">
                                    <div className="chat-messages p-4">

                                        {
                                            this.state.reminds.map((remind) => {
                                                return(
                                                <div className="chat-message-left pb-4" key={remind.id}>
                                                    <div className="flex-shrink-1 bg-light rounded py-2 px-3 mr-3   rounded rounded-3">
                                                            {remind.chat} <small className="small size-10">({ remind.userchats.length })</small><small className="small bg-white px-1 text-primary border border-1 border-primary rounded rounded-3 size-10 d-none">{ remind.sms ? " sms" : "sms"}</small> <small className="text-danger" onClick={ () => this.delete(remind.id)}><FeatherIcon icon="delete" size={13} ></FeatherIcon></small>
                                                    </div>
                                                    <div>
                                                        {/* <img src={ chat.avatar} className="rounded-circle mr-1" alt="." width="40" height="40" /> */}
                                                        <div className="text-muted small text-nowrap mt-2">{remind.time}</div>
                                                    </div>
                                                </div>
                                                )
                                                     
                                            })
                                        }

                                         

                                        

                                    </div>
                                </div>
                                    <div className="flex-grow-0 py-3 px-4 border-top">
                                        <div className="text-danger">{this.state.error}</div>
                                        <input type={"checkbox"} onChange={this.handleInputsms} id="sms" name="sms" className="rounded rounded-3 text-primary d-none" /><label htmlFor="sms" className="text-primary d-none">Par SMS</label>
                                        <input type={"checkbox"} onChange={this.handleInputgmail} id="gmail" name="gmail" className="rounded rounded-3 text-primary ms-3 d-none" /><label htmlFor="gmail" className="text-primary d-none">Par mail</label>
                                        <div className="input-group">
                                            <textarea className="form-control"  onChange={this.handleInput} name="chat" value={this.state.chat}  placeholder="Taper l'information"/>
                                        </div>
                                        <div className="text-end">
                                            <button className="btn btn-primary btn-sm" type="button" onClick={ () => this.storeChat()}>Envoyer</button>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
        )
    }
}
export default Remind