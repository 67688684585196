import axios from "axios";
import React, {Component} from "react";
import ReactToPrint from "react-to-print";
import FeatherIcon from 'feather-icons-react';
import Headerpdf from "../../users/Headerpdf";
import UserIdentity from "../../users/UserIdentity";
import { Link } from "react-router-dom";

class Profilefile extends Component{

    state = {
        student : {
            user : {
                user : {}
            },
            father : {},
            monther : {},
        },
        reportcard : true,
        schoolfee : true,
        studentschoolfees : null,
        labels : [],
        months : [],
    }
    
    componentDidMount(){
        this.getUser()
    }
 
    handleInput = (e) => {
        this.setState({
            [e.target.name]: !this.state.schoolfee
        }); 
    }

    handleInputR = (e) => {
        this.setState({
            [e.target.name]: !this.state.reportcard
        }); 
    }

    async getUser(){
        var student = await axios.get("students/"+this.props.match.params.studentId);
        
        if(student.status === 200)
        {
            this.setState({
                student : student.data
            })
            
            this.getReportcards();
            this.getStudentschoolfees();
            this.getMonth();
        }
    }

    async getMonth(){
        var months = await axios.get("months");
        
        if(months.status === 200)
        {
            this.setState({
                months : months.data
            })
        }
    }

    async getReportcards(){
        var evaluations = await axios.get('/student/reportcards/' + this.props.match.params.studentId);
        
        if(evaluations.status === 200)
        {
            var data = [], max = 1, rows = [] , labels = [], points =[];
            if(evaluations.data.reportcards.length > 0)
            {
                evaluations.data.reportcards.map((evaluation) => {
                    var noteD = 0, max = 0;
                    
                    evaluation.notes.map(note => {
                        
                        noteD += note.note > 0 ? note.note : noteD;
                        max += note.evaluation.max > 0 ? note.evaluation.max : max;
                            rows.push(
                                {
                                    Periode : evaluation.periode.id + "P",
                                    lesson : evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.short_entitled_lesson),
                                    note : note.note +" / "+ max,
                                    staff : evaluation.sectionclasselesson && (evaluation.sectionclasselesson.staff && (evaluation.sectionclasselesson.staff.user.first_name + " " +evaluation.sectionclasselesson.staff.user.last_name)),
                                    created_at : <small>{note.createdAt.slice(0, 10)}</small>
                                }
                            )
                    })
                    if(max > 0 && evaluation.sectionclasselesson.lesson && evaluation.sectionclasselesson.lesson.short_entitled_lesson)
                    {
                        if(labels.filter(data => data.id == evaluation.sectionclasselesson.lesson.id).length == 0)
                        {
                            labels.push({
                                note :  noteD,
                                max : max,
                                lesson : evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.entitled_lesson),
                                staff : evaluation.sectionclasselesson.staff && ( evaluation.sectionclasselesson.staff.user.last_name + " " + evaluation.sectionclasselesson.staff.user.first_name),
                                id : evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.id),
                            });
                            noteD = (noteD * 100) /max;
                        }
                        else{
                            labels.map((val, index) => {
                                if( val.id == evaluation.sectionclasselesson.lesson.id)
                                {
                                    labels[index].note =  labels[index].note ? labels[index].note > 0 ? labels[index].note + noteD : noteD : noteD
                                    labels[index].max =  labels[index].max ? labels[index].max > 0 ? labels[index].max + max : max : max
                                    labels[index].lesson = evaluation.sectionclasselesson.lesson.entitled_lesson;
                                    labels[index].staff = evaluation.sectionclasselesson.staff && ( evaluation.sectionclasselesson.staff.user.last_name + " " + evaluation.sectionclasselesson.staff.user.first_name );
                                    labels[index].id = evaluation.sectionclasselesson.lesson.id
                                }
                            })
                        } 

                    }
                    
                    
                })

            }
            
            this.state.student.reportcards.filter(data => data.periode.id == 1).map((val) =>{
                if(labels.filter(lab => lab.id == val.sectionclasselesson.lesson.id).length == 0)
                {
                    labels.push({
                        id : val.sectionclasselesson.lesson.id,
                        lesson : val.sectionclasselesson.lesson.entitled_lesson,
                        staff : val.sectionclasselesson && (val.sectionclasselesson.staff && (val.sectionclasselesson.staff.user.first_name + " " +val.sectionclasselesson.staff.user.last_name)),
                        note : 0,
                        max :  0,
                        pourcent : 0,
                    })
                }
            })
            this.setState({
                labels : labels,
            }) 
        }
    }

    async getStudentschoolfees(){
        var studentschoolfees = await axios.get('/students/schoolfees/'+ this.props.match.params.studentId)
        if(studentschoolfees.status === 200)
        {
            this.setState({
                studentschoolfees : studentschoolfees.data
            })
        }
    }


    render(){
        var profile = JSON.parse(localStorage.getItem('profile'))
        var matricule =this.state.student.matricule; 
        var last_name = this.state.student.user ? this.state.student.user.last_name : "";
        var first_name = this.state.student.user ? this.state.student.user.first_name : "";
        var family_name = this.state.student.user ? this.state.student.user.family_name : "";
        var classe= this.state.student && (this.state.student.sectionclass && (this.state.student.sectionclass.class.short_entitled_class + " " + this.state.student.sectionclass.title )); 
        var section= this.state.student && (this.state.student.sectionclass && ( this.state.student.sectionclass.section.entitled_section )); 
        return(
            
        <div className="container">
                <h5 className="h5 font-monospace font-monospace">Fiche de l'élève </h5>
                <div className="text-end">
                    <Link to={"/students/" + this.props.match.params.studentId} className=" mx-3 border border-primary py-0 px-1 rounded rounded-3 text-decoration-none"> voir profil élève</Link>
                    <ReactToPrint
                        trigger={() => <button className="btn btn-sm btn-outline-primary rounded rounded-3" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <small className="small"> Imprimer</small></button>}
                        content={() =>  this.componentRef}
                    />
                </div>
            <div className="row" ref={el => (this.componentRef = el)}>
                  <Headerpdf />
                    {
                       <UserIdentity  title={"Fiche de l'élève"}/> 
                    }
                <div className="col-md-12">
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body  row">
                            <div className="col-4 overflow-hidden">
                                <div className="bg-secondary text-center text-white font-monospace fw-bolder my-2">Identité de l'élève</div>
                                <div className="d-flex">
                                    <div className="image mr-3">
                                        <img src={this.state.student && (this.state.student.user.avatar)} className="img-fluid" width={this.state.student && (this.state.student.user.avatar && ("150"))} /> 
                                    </div>
                                    <div className="mt-0 ms-2">
                                        <div  className=" font-monospace">
                                            <div><span className="fw-bolder">Nom : </span> {last_name }</div>
                                            <div><span className="fw-bolder">Prénom : </span> { first_name }</div>
                                            <div><span className="fw-bolder">Post-nom : </span> { family_name}</div>
                                            <div><span className="fw-bolder">Matricule : </span> {this.state.student && (this.state.student.matricule)}</div>
                                            <div><span className="fw-bolder">Identité : </span> {this.state.student && (this.state.student.user.identity_number)}</div>
                                            <div><span className="fw-bolder">Contact : </span> {this.state.student && (this.state.student.user.tel)},  {this.state.student && (this.state.student.user.email)}</div>
                                            <div><span className="fw-bolder">adress : </span> {this.state.student && (this.state.student.user.adress)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4 overflow-hidden">
                                <div className="bg-secondary text-center text-white font-monospace fw-bolder my-2">Identité Père</div>
                                <div className="d-flex">
                                    <div className="image mr-3">
                                        <img src={this.state.student.father && (this.state.student.father.avatar)} className="img-fluid" width={this.state.student.father && (this.state.student.father.avatar && ("150"))} /> 
                                    </div>
                                    
                                    <div className="mt-0 ms-2">
                                        <div  className="font-monospace">
                                            <div><span className="fw-bolder">Nom : </span> {this.state.student.father && ( this.state.student.father.last_name)} </div>
                                            <div><span className="fw-bolder">Prénom : </span> {this.state.student.father && ( this.state.student.father.first_name)} </div>
                                            <div><span className="fw-bolder">Identité : </span> {this.state.student.father && (this.state.student.father.identity_number)}</div>
                                            <div><span className="fw-bolder">Contact : </span> {this.state.student.father && (this.state.student.father.tel)},  {this.state.student.father && (this.state.student.father.email)}</div>
                                            <div><span className="fw-bolder">adress : </span> {this.state.student.father && (this.state.student.father.adress)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-4 overflow-hidden">
                                <div className="bg-secondary text-center text-white font-monospace fw-bolder my-2">Identité Mère</div>
                                <div className="d-flex">
                                    <div className="image mr-3">
                                        <img src={this.state.student.monther && (this.state.student.monther.avatar)} className="img-fluid" width={this.state.student.monther && (this.state.student.monther.avatar && ("150"))} /> 
                                    </div>
                                    <div className="mt-0 ms-2">
                                        <div  className="font-monospace">
                                            <div><span className="fw-bolder">Nom : </span> {this.state.student.monther && ( this.state.student.monther.last_name)} </div>
                                            <div><span className="fw-bolder">Prénom : </span> {this.state.student.monther && ( this.state.student.monther.first_name)} </div>
                                            <div><span className="fw-bolder">Identité : </span> {this.state.student.monther && (this.state.student.monther.identity_number)}</div>
                                            <div><span className="fw-bolder">Contact : </span> {this.state.student.monther && (this.state.student.monther.tel)},  {this.state.student.monther && (this.state.student.monther.email)}</div>
                                            <div><span className="fw-bolder">adress : </span> {this.state.student.monther && (this.state.student.monther.adress)}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 row">
                                <div className="bg-secondary text-center text-white font-monospace fw-bolder my-2">Etablissement et tuteur</div>
                                <div className="col-4 overflow-hidden">
                                    <div className="d-flex">
                                        <div className="mt-0 ms-2">
                                            <div  className=" font-monospace">
                                                <div><span className="fw-bolder">Classe : </span> { classe} </div>
                                                <div><span className="fw-bolder">Section : </span> { section } </div>
                                                <div><span className="fw-bolder">Année : </span> {this.state.student && (this.state.student.year ? this.state.student.year.year : "")} </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4 overflow-hidden">
                                    <div className="d-flex">
                                        <div className="image mr-3">
                                            <img src={profile.profile.establishment.logo } className="img-fluid" width={this.state.student && (this.state.student.user.user.avatar && ("80"))} /> 
                                        </div>
                                        <div className="mt-0 ms-2">
                                            <div  className=" font-monospace">
                                                <div><span className="fw-bolder">Etablissement : </span> { profile.profile.establishment.name } </div>
                                                <div><span className="fw-bolder">Type : </span> { profile.profile.establishment.type }</div>
                                                <div><span className="fw-bolder">Contact : </span> { profile.profile.establishment.email} , { profile.profile.establishment.tel}</div>
                                                <div><span className="fw-bolder">adress : </span> { profile.profile.establishment.region}, { profile.profile.establishment.municipality} { profile.profile.establishment.city}, { profile.profile.establishment.other_adress}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="col-4 overflow-hidden">
                                    <div className="d-flex">
                                        <div className="image mr-3">
                                            <img src={this.state.student && (this.state.student.user.user.avatar)} className="img-fluid" width={this.state.student && (this.state.student.user.user.avatar && ("150"))} /> 
                                        </div>
                                        <div className="mt-0 ms-2">
                                            <div  className="font-monospace">
                                                <div><span className="fw-bolder">Nom : </span> {this.state.student && ( this.state.student.user.user.last_name)} </div>
                                                <div><span className="fw-bolder">Prénom : </span> {this.state.student && ( this.state.student.user.user.first_name)} </div>
                                                <div><span className="fw-bolder">Identité : </span> {this.state.student && (this.state.student.user.user.identity_number)}</div>
                                                <div><span className="fw-bolder">Contact : </span> {this.state.student && (this.state.student.user.user.tel)},  {this.state.student && (this.state.student.user.user.email)}</div>
                                                <div><span className="fw-bolder">adress : </span> {this.state.student && (this.state.student.user.user.adress)}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>

                            <div className="col-md-12 font-monospace">
                                <div className="text-center text-primary table_action">
                                    <input type={"checkbox"} className="rounded rounded-3" name="reportcard" value={this.state.reportcard} id="reportcard" onChange={ this.handleInputR } /> <label className="" htmlFor="reportcard">Afficher les parcours scolaire</label>
                                 </div>
                                <div className={this.state.reportcard ? "bg-secondary text-center text-white font-monospace fw-bolder my-2" : "bg-secondary text-center text-white font-monospace fw-bolder my-2 d-none"}>Parcours scolaire</div>
                                 <div className={this.state.reportcard ? "table-responsive" : "table-responsive d-none" }>
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>N°</th>
                                                <th>Cours</th>
                                                <th>Note</th>
                                                <th>Max</th>
                                                <th>Pourcent</th>
                                                <th>Tutilaire</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.labels.sort((a, b) => (a.max < b.max ? 1 : -1)).map((label, index) =>{
                                                    return(
                                                        <tr key={index}>
                                                            <td>{ index+1 }</td>
                                                            <td>{ label.lesson }</td>
                                                            <td align="right" className={ label.note < (label.max /2) ? "text-danger" : ""}>{ label.note > 0 ? label.note : "" }</td>
                                                            <td align="right">{ label.max > 0 ? label.max : "" }</td>
                                                            <td align="right">{ label.note ? (label.note > 0 && label.note > 0) ? ((label.note * 100) / label.max).toFixed(1) + "%" : "" : ""  }</td>
                                                            <td align="right">{ label.staff }</td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <th colSpan={4}>
                                                    Total
                                                </th>
                                                <td align="right" className="fw-bolder">
                                                    {
                                                        this.state.student &&( ((this.state.student.studentsuccess * 100) / this.state.student.studentmax).toFixed(1) )
                                                    }%
                                                </td>
                                                <td>
                                                    <a href="#" className="table_action" type="button" onClick={() => window.open(process.env.REACT_APP_EVIVESOMA_STAT + "/students/"+this.state.student.id,"Ratting","width=950, height=500,left=200,top=200,toolbar=no,menubar=no,scrollbars=no,resizable=0,location=no,directories=no,status=no")}>Bulletin</a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                 </div>
                            </div>

                            <div className="col-md-12 font-monospace">
                                 <div className="text-center text-primary table_action">
                                    <input type={"checkbox"} className="rounded rounded-3" name="schoolfee" value={this.state.schoolfee} id="schoolfee" onChange={ this.handleInput } /> <label className="" htmlFor="schoolfee">Afficher les paiements scolaire</label>
                                 </div>
                                <div className={this.state.schoolfee ? "bg-secondary text-center text-white font-monospace fw-bolder my-2" : "bg-secondary text-center text-white font-monospace fw-bolder my-2 d-none"}>Paiements scolaire</div>
                                 <div className={this.state.schoolfee ? "table-responsive" : "table-responsive d-none" }>
                                    <table className="table table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Mois</th>
                                                <th>Montant</th>
                                                <th>Paiement</th>
                                                <th>N° fact</th>
                                                <th>Année</th>
                                                <th>Date</th>
                                                <th>Responsable</th>
                                                <th>Comptable</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.studentschoolfees != null ?(
                                                    this.state.months.map(month =>{
                                                        return(<>
                                                            {
                                                        
                                                                this.state.studentschoolfees.schoolfees.filter(mon => mon.month.id == month.id).map((schoolfee, index) =>{
                                                                    return(
                                                                        <tr key={schoolfee.id}>
                                                                            {
                                                                                index == 0 &&(
                                                                                    <td align="center" className="fw-bolder" rowSpan={this.state.studentschoolfees.schoolfees.filter(mon => mon.month.id == month.id).length}>{month.month  }</td>
                                                                                )
                                                                            }
                                                                            <td align="right">{ schoolfee.pay_mount + ""+ schoolfee.sectionschoolfeecategory.currency }</td>
                                                                            <td align="">{ schoolfee.sectionschoolfeecategory.schoolfeecategory.category }</td>
                                                                            <td align="right">{ schoolfee.invoice }</td>
                                                                            <td align="">{ schoolfee.year.year }</td>
                                                                            <td align="right">{ schoolfee.createdAt.slice(0, 10) }</td>
                                                                            <td align="right">{ schoolfee.user.last_name +  " " + schoolfee.user.first_name }</td>
                                                                            <td align="right">{ schoolfee.staff.user.last_name +  " " + schoolfee.staff.user.first_name }</td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                            {
                                                                this.state.studentschoolfees.schoolfees.filter(mon => mon.month.id == month.id).length == 0 &&(
                                                                    <tr key={month.id}>
                                                                        <td align="center" className="fw-bolder">{ month.month}</td>
                                                                        <td align="center" className="fw-bolder"></td>
                                                                        <td align="center" className="fw-bolder"></td>
                                                                        <td align="center" className="fw-bolder"></td>
                                                                        <td align="center" className="fw-bolder"></td>
                                                                        <td align="center" className="fw-bolder"></td>
                                                                        <td align="center" className="fw-bolder"></td>
                                                                        <td align="center" className="fw-bolder"></td>
                                                                    </tr>
                                                                )
                                                            }
                                                            </>
                                                        )
                                                    })
                                                ) : ""
                                            }
                                        </tbody>
                                    </table>
                                 </div>
                            </div>
                             
                        </div>
                    </div>
 
                    
                </div>
                
            </div>

        </div>
        )
    }
}
export default  Profilefile