import axios from "axios";
import React, {Component} from "react";
import ClasseForm from "./ClasseForm";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   Classe extends Component{

    state = {
        isModal : false,
        formData : "",
        classes : [],
    }

    componentDidMount(){
        this.getClasses();
    }

    async getClasses(){
        var classes = await axios.get('classes');
        if(classes.status === 200)
        {
            this.setState({classes : classes.data}) 
        }
    }

    showModal = (classe = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : classe,
        })
        this.getClasses()
    }

    delete = (classe) =>{
        axios.delete("classes/" + classe).then((response) => {
            this.getClasses()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){
        var classes =
        this.state.classes.sort((a, b) => (a.short_entitled_class > b.short_entitled_class ? 1 : -1)).map((classe) => {
            return(
                <li className="widget-todo-item list-group-item m-2 border rounded-3" key={classe.id}>
                    <div
                        className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                        <div className="widget-todo-title-area d-flex align-items-center">
                            <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                            <label htmlFor={classe.id}><span className="widget-todo-title ml-50">{classe.short_entitled_class} {classe.entitled_class}</span></label>
                        </div>
                        {
                            this.handleCheck("Modifier classe") == true && (
                                <div className="widget-todo-item-action d-flex align-items-center">
                                    <FeatherIcon icon="edit-2" size="16" className='cursor-move text-primary' type="button" onClick={() => this.showModal(classe)}></FeatherIcon>
                                </div>
                            )
                        }
                    </div>
                </li>
            )
        })
        return(
            <div>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h6 className="h6 text-dark font-monospace">Type classe</h6>
                {
                    this.handleCheck("Ajouter classe") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon></button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className=" mb-4 overflow-hidden">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        { classes }
                    </ul>
                </div>
            </div>

            <ClasseForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            

        </div>
        )
    }
}
export default Classe;