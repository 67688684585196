import axios from "axios";
import React, {Component} from "react";

class Country extends Component{

    state = {
        countries : []
    }

    componentDidMount(){
        this.getEstablishment()
    }
    async getEstablishment(){
        var countries = await axios.get('countries')
        if(countries.status === 200)
        {
            this.setState({
                countries : countries.data
            })
        }
    }
    render(){
        return(
            <div>
                <h5 className="h5 font-monospace">Localisations</h5>
                <div>

                </div>
            </div>
        )
    }
}
export default Country