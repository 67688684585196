import axios from "axios";
import React, {Component} from "react";
import YearForm from "./YearForm";
import FeatherIcon from 'feather-icons-react';
import StudentBar from "../students/StudentBar";
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";
import ArchiveForm from "./ArchiveForm";
import { toast } from "react-toastify";

class Year extends Component{

    state = {
        isModal : false,
        isModalArchive : false,
        loading : false,
        formData : "",
        years : [],
    }

    componentDidMount(){
        this.getYears();
    }

    async getYears(){
        var years = await axios.get('yearslistetabli');
         
        if(years.status === 200)
        {   
            this.setState({years : years.data, loading : true}) 
        }
    }

    showModal = (year = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : year,
        })
        if(year == null)
        this.getYears()
    }

    showModalArchive = (year = null) => {
        this.setState({
            isModalArchive : !this.state.isModalArchive,
            formData : year,
        })
        this.getYears()
    }

    delete = (year) =>{
        axios.delete("years/" + year).then((response) => {
            this.getYears()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return  permissions.some(item => val === item.permission);
    }

    render(){
        var students = 0, mal = 0, fille=0, parent = 0, staff = 0, supplier = 0, section = 0, sectionclasse = 0, actif = 0, nonactif = 0, abandon = 0;
        var labels = [], data = [];
        
        
        var years =
        this.state.years?.data?.map((year) => {
             
            mal = this.state.years?.students.filter(student => student.user.genre === "Masculin" && student.operation != "Supprimer"  && student.status != 0 ).length
            fille = this.state.years?.students.filter(student => student.user.genre === "Feminin" && student.operation != "Supprimer"  && student.status != 0 ).length
            abandon = this.state.years?.students.filter(student => student.status === 0).length
            actif = this.state.years?.students.filter(student => student.status === 1).length
            nonactif = this.state.years?.students.filter(student => student.status === 2).length
            students = this.state.years?.students.filter(student => student.operation != "Supprimer").length
            parent = year.parentfamilies?.length
            staff = year.staffs?.length
            supplier = year.suppliers?.length
             sectionclasse = year.sectionclasses?.length
            labels.push(year.year)
            data.push(students)
            return(
                <div className="card shadow mb-3">
                    <div className="card-body">
                        <div  key={year.id}>
                            <li className="widget-todo-item list-group-item m-2 border border-primary rounded-3  mb-2">
                                <div
                                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                    <div className="widget-todo-title-area d-flex align-items-center">
                                        <FeatherIcon icon="package" className='cursor-move'></FeatherIcon> 
                                        <label htmlFor={year.id}><span className="widget-todo-title ml-50 fw-bolder" >{year.year} <span className="text-success small"> { year.status == 1 ? "Archivée" : "" } </span> </span></label>
                                    </div>
                                    {
                                        (this.handleCheck("Modifier année") == true && year.status == 0) && (
                                            <div className="widget-todo-item-action d-flex align-items-center">
                                                <b><FeatherIcon icon="edit-2" size="16" className='cursor-move text-primary' type="button" onClick={() => this.showModal(year)}></FeatherIcon>Editer </b>
                                                <b><FeatherIcon icon="database" size="16" className='cursor-move text-primary' type="button" onClick={() => this.showModalArchive(year)}></FeatherIcon>Archiver </b>
                                            </div>
                                        )
                                    }
                                </div>
                            </li>
                            <div className="col-md-12 fluid-content row">
                                
                                    <div className="col-md-6">
                                        <div className="card  overflow-hidden rounded rounded-3 mb-3">
                                            <div className="card-body py-3 px-auto">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <FeatherIcon icon={"user"} size='40'></FeatherIcon>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3 border-1 border-start">
                                                        <div className="text-dark py-1 text-center"><b>{ students }</b> élèves</div>
                                                        <div className="row">
                                                            <div className="col-md-4">
                                                                <span className="badge text-primary alert-primary">{actif} Actif</span>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <span className="badge text-warning alert-warning">{nonactif} Non actif</span>
                                                            </div>
                                                            <div className="col-md-4">
                                                                <span className="badge text-danger alert-danger">{abandon} Abandon</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card  overflow-hidden rounded rounded-3 mb-3">
                                            <div className="card-body py-3 px-auto">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <FeatherIcon icon={"users"} size="40" ></FeatherIcon>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3 border-1 border-start">
                                                        <div className="text-center py-1 border-bottom"><b>{ fille}</b> Filles</div>
                                                        <div className="text-dark py-1 text-center"><b>{mal}</b> Garcons</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card  overflow-hidden rounded rounded-3 mb-2">
                                            <div className="card-body py-2 px-auto">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <FeatherIcon icon={"users"} size="40" ></FeatherIcon>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3 text-center border-1 border-start">
                                                        <b>{ parent }</b> Parents
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card  overflow-hidden rounded rounded-3 mb-2">
                                            <div className="card-body py-2 px-auto">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <FeatherIcon icon={"users"} size="40" ></FeatherIcon>
                                                    </div>
                                                    <div className="flex-grow-1 text-center ms-3 border-1 border-start">
                                                        <b>{ supplier}</b> Fournisseurs
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card  overflow-hidden rounded rounded-3 mb-3">
                                            <div className="card-body py-3 px-auto">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <FeatherIcon icon={"users"} size="40" ></FeatherIcon>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3 border-1 border-start">
                                                        <div className="text-dark py-1 text-center"><b>{staff}</b> Personnel</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card  overflow-hidden rounded rounded-3 mb-2">
                                            <div className="card-body py-3 px-auto">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0">
                                                        <FeatherIcon icon={"box"} size="40" ></FeatherIcon>
                                                    </div>
                                                    <div className="flex-grow-1 text-center ms-3 border-1 border-start">
                                                        <b>{ sectionclasse}</b> classes
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                            </div>
                        </div>
                    </div>
                </div>
            )
        })
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | scolary year</title>
                </Helmet>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Année scolaire</h5>
                {
                    this.handleCheck("Ajouter année") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Année scolaire</button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className=" mb-4 overflow-auto">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        {this.state.loading > 0 ? years : <span class="text-primary float-end"> <Loading/>  veuillez patienter  un moment</span>} 
                    </ul>
                </div>
            </div>

            <div className="mt-4">
                <div className="card shadow mb-2">
                    <div className="card-body overflow-hidden">
                        <h6 className="h6 font-monospace text-center">Statistique des élèves par année scolaire</h6>
                        {
                            labels.length > 0 && (
                                <StudentBar
                                    labels={labels}
                                    data={data}
                                    title={"Statistique élèves  par année scolaire"}
                                />
                            
                            )
                        }
                    </div>
                </div>
                
                
            </div>

            <YearForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            <ArchiveForm 
                isModal={this.state.isModalArchive} 
                formData={this.state.formData} 
                showModal={this.showModalArchive}
            />
            

        </div>
        )
    }
}
export default Year;