import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import ReactToPrint from "react-to-print";
import Headerpdf from "../../users/Headerpdf";

class StaffShendule extends Component{

    state = {
        periode : null,
    }

    render(){
        return(
                this.props.section.sectionclasses.map((sectionclass) =>{
                    if(this.props.schedules.filter(schedule => schedule.sectionclasselesson.sectionclass.id == sectionclass.id ).length > 0)
                    {
                        return(
                            <li className="widget-todo-item list-group-item m-2 mb-4 border rounded-3 ">
                                <div
                                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                    <div className="widget-todo-title-area d-flex align-items-center">
                                    <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                                        <label htmlFor={sectionclass.id}><span className="widget-todo-title ml-50">{sectionclass.class.short_entitled_class}  <span className="text-primary">{sectionclass.title}</span> { this.props.section.short_entitled_section} </span></label>
                                    </div>
                                    <div className="widget-todo-item-action d-flex align-items-center">
                                        <div className="text-end mb-2 mb-md-0">
                                            <div className="btn-group me-2">
                                                <ReactToPrint
                                                    trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                                                    content={() =>  this.componentRef}
                                                />
                                            </div>
                                        </div>
                                        {this.props.section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                                    </div>
                                </div>
                                <div className="table-responsive m-2" ref={el => (this.componentRef = el)}>

                                    <Headerpdf />
                                <div className="userIdentity">
                                    <div className="text-center h6">
                                        <label htmlFor={sectionclass.id}><span className="widget-todo-title ml-50">Horaire {sectionclass.class.short_entitled_class} {sectionclass.class.entitled_class} <span className="text-primary">{sectionclass.title}</span> { this.props.section.short_entitled_section} </span></label>
                                    </div>
                                </div>
                                    <table className="table table-striped table-sm">
                                        <thead>
                                            <tr>
                                            {
                                                this.props.days.map((day) => {
                                                    return(<th className="border" scope="col" key={day.id +"evivesoma"}> {day.day}</th>)
                                                    })
                                            }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.props.days.map((day) => {
                                                    return (
                                                            <td className="border" key={day.id}>
                                                                {
                                                                    this.props.schedules.filter(schedule => this.state.periode && schedule.periode.id === this.state.periode  && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && schedule.dayId == day.id || !this.state.periode && schedule.sectionclasselesson.sectionclass.id == sectionclass.id && schedule.dayId == day.id).map((schedule) => {
                                                                    return(
                                                                            <tr className="" key={schedule.id}>
                                                                                <td type="button" title={"modifier " + schedule.date_start + " au " + schedule.date_end } >
                                                                                    { schedule.sectionclasselesson.lesson.short_entitled_lesson} <small className="fw-lighter text-primary small" style={{ fontSize : 11 +"px"}}>{schedule.periode.id != 3 && schedule.periode.id != 6 && schedule.periode.id != 7 ? "("+schedule.periode.id + "P)" : "" }{schedule.periode.id == 3 ? "(Exa1)" : "" }{schedule.periode.id == 6 ? "(Exa2)" : "" }{schedule.periode.id == 7 ? "(2°S)" : "" }</small> 
                                                                                    <span className="fw-lighter" style={{ fontSize : 11 +"px"}}> { schedule.time_start ? schedule.time_start.slice(0, 5) : "" } { schedule.time_end ? schedule.time_end.slice(0, 5) : "" }</span>
                                                                                </td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </td>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </li>
                        )
                    }
                })
            )
    }
}

export default StaffShendule