import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import UserprofileForm from "../profiles/UserprofileForm";
import { toast } from "react-toastify";

class   StaffForm extends Component{

    state = {
        id : "",
        matricule : null,
        first_name : "",
        last_name : "",
        family_name : "",
        genre : "Maculin",
        email : null,
        tel : null,
        identity_number : "",
        datebirth : "",
        title : "",
        password : "",
        yearId : "",
        nationality : "",
        diploma : "",
        seniority : "",
        date_function : "",
        commitment_date  : "",
        marital_status  : "",
        salary  : null,

        years : [],
        profiles : [],
        error : "",
    }
    
    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeStaff = () =>{
        var data = {
            user : {
                first_name : this.state.first_name,
                last_name : this.state.last_name,
                family_name : this.state.family_name,
                genre :  this.state.genre,
                email : this.state.email ? this.state.email : null,
                tel : this.state.tel ? this.state.tel : null,
                identity_number : this.state.identity_number ? this.state.identity_number : null,
                datebirth : this.state.datebirth ? this.state.datebirth : null,
                nationality : this.state.nationality,
                yearId : this.state.yearId > 0 ? this.state.yearId : null,
                marital_status : this.state.marital_status
            },
            profiles : this.state.profiles,
           staff : {
                matricule : this.state.matricule ? this.state.matricule : null,
                diploma : this.state.diploma,
                seniority : this.state.seniority,
                date_function : this.state.date_function ? this.state.date_function : new Date(),
                commitment_date  : this.state.commitment_date ? this.state.commitment_date : new Date(),
                salary : this.state.salary > 0 ? this.state.salary : null,
                operation : this.state.id ? "Editer" : "Ajouter",
                yearId : this.state.yearId > 0 ? this.state.yearId : null,
                password :  "123"
           },
            
        }
        var request = this.state.id ? axios.put('staffs/'+this.state.id, data) : axios.post('staffs', data);
        request.then((response) =>{
            this.props.showModal()
            this.form()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    edit=()=>  {
        this.form()
        this.getYear();
    }

    form=()=>{
        
        var date_function =  new Date(this.props.formData ? this.props.formData.date_function : null);
        var mois = date_function.getMonth()+1;
            mois = mois >= 10 ? mois : "0"+mois;
        var jour = date_function.getDate() >= 10 ? date_function.getDate() : "0"+date_function.getDate();
        date_function = date_function.getFullYear()  + "-" + (mois) + "-" + jour;

        var datebirth = this.props.formData ?  new Date(this.props.formData.user.datebirth) : new Date();
        mois = datebirth.getMonth()+1;
            mois = mois >= 10 ? mois : "0"+mois;
        jour = datebirth.getDate() >= 10 ? datebirth.getDate() : "0"+datebirth.getDate();
        datebirth = datebirth.getFullYear()  + "-" + (mois) + "-" + jour;

        var commitment_date = new Date(this.props.formData ? this.props.formData.commitment_date : null);
        mois = commitment_date.getMonth()+1;
            mois = mois >= 10 ? mois : "0"+mois;
        jour = commitment_date.getDate() >= 10 ? commitment_date.getDate() : "0"+commitment_date.getDate();
        commitment_date = commitment_date.getFullYear()  + "-" + (mois) + "-" + jour;

        
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            matricule : this.props.formData ? this.props.formData.matricule : null,
            first_name : this.props.formData ? this.props.formData.user.first_name : "",
            last_name : this.props.formData ? this.props.formData.user.last_name : "",
            family_name : this.props.formData ? this.props.formData.user.family_name : "",
            genre : this.props.formData ? this.props.formData.user.genre : "",
            email : this.props.formData ? this.props.formData.user.email : null,
            tel : this.props.formData ? this.props.formData.user.tel : null,
            identity_number : this.props.formData ? this.props.formData.user.identity_number : "",
            nationality : this.props.formData ? this.props.formData.user.nationality : "",
            marital_status : this.props.formData ? this.props.formData.user.marital_status : "",
            yearId : this.props.formData  ? this.props.formData.yearId : null,
            diploma : this.props.formData  ? this.props.formData.diploma : "",
            seniority : this.props.formData  ? this.props.formData.seniority : "",
            date_function : this.props.formData ? this.props.formData.date_function  ?  date_function  : null : null,
            commitment_date : this.props.formData ? this.props.formData.commitment_date ? commitment_date : null  : null,
            salary : this.props.formData  ? this.props.formData.salary : null,
            datebirth : this.props.formData ? this.props.formData.user.datebirth ? datebirth : null : null,
            title : this.props.formData ? "Modifier les informations du personnel" : "Ajout du personnel",
            error: "",
            
        })
    }

    profiles = (profile = false) =>{
        this.setState({
            profiles : (profile)
        })
    }

    render(){
        
        var optionMarital_status = [];
        optionMarital_status.push({ value : "Célibataire", label : "Célibataire"})
        optionMarital_status.push({ value : "Marié(e)", label : "Marié(e)"})
        optionMarital_status.push({ value : "Divorcé(e)", label : "Divorcé(e)"})
        optionMarital_status.push({ value : "Veuf(ve)", label : "Veuf(ve)"})
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">

                        <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                        <div className="col-md-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Nom<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="last_name"  value={this.state.last_name} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="prenom-id-icon">Prénom <span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="first_name"  value={this.state.first_name} id="prenom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Post-nom </label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="family_name"  value={this.state.family_name} id="postnom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="email-id-icon">Email </label>
                                <div className="position-relative mt-1">
                                    <input type="email" className="form-control form-control-sm"  onChange={this.handleInput} name="email"  value={this.state.email} id="email-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="mail"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="contact-id-icon">Salaire mensuel</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm"  onChange={this.handleInput} name="salary"  value={this.state.salary} id="contact-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="dollar-sign"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="contact-id-icon">N° matricule<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="matricule"  value={this.state.matricule} id="contact-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">N° d'identité<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm"  onChange={this.handleInput} name="identity_number"  value={this.state.identity_number} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">N° de téléphone<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm" placeholder="" onChange={this.handleInput} name="tel"  value={this.state.tel} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="phone-call"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">Nationnalité</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder="" onChange={this.handleInput} name="nationality"  value={this.state.nationality} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="flag"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">Diplome</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder="" onChange={this.handleInput} name="diploma"  value={this.state.diploma} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="file"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">Ancienneté</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder="" onChange={this.handleInput} name="seniority"  value={this.state.seniority} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="box"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="first-name-icon">Date de naissance</label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control form-control-sm" onChange={this.handleInput} name="datebirth" value={this.state.datebirth} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="calendar"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="first-name-icon">Date engagement</label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control form-control-sm" onChange={this.handleInput} name="commitment_date" value={this.state.commitment_date} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="calendar"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="first-name-icon">Date entrée en fonction </label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control form-control-sm" onChange={this.handleInput} name="date_function" value={this.state.date_function} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="calendar"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Année scolaire<span className="text-danger">*</span></label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        this.state.years.filter(option => 
                                        option.value == this.state.yearId)
                                    }
                                    defaultValue={this.state.yearId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                yearId : select.value
                                            });
                                        }
                                    }
                                    options={this.state.years}
                                />
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Etat civil</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionMarital_status.filter(option => 
                                        option.value == this.state.marital_status)
                                    }
                                    defaultValue={this.state.marital_status}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                marital_status : select.value
                                            });
                                        }
                                    }
                                    options={optionMarital_status}
                                />
                            </div>
                        </div>
                        
                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Sexe<span className="text-danger">*</span></label>
                            <div className="input-group pt-2">
                                <input type={"radio"} name="genre" value={"Masculin"} checked={this.state.genre === "Masculin" ? true : false} onChange={this.handleInput} id="masculin" className="rounded m-2" /> <label htmlFor="masculin">Masculin</label>
                                <input type={"radio"} name="genre" value={"Feminin"} checked={this.state.genre == "Feminin" ? true : false} onChange={this.handleInput} id="feminin" className="rounded m-2" /> <label htmlFor="feminin">Féminin</label>
                            </div>
                        </div>
                        <hr />

                        <UserprofileForm profiles={this.profiles} model={"Staff"} />
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-danger text-left">{this.state.error}</div>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeStaff()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
        </div>
        )
    }
}
export default StaffForm;