import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';

class   SchoolfeeDetail extends Component{

    render(){
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">Detail du paiement</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">

                    <div className="form-group col-md-6">
                        <label className="fw-bolder font-monospace">élève</label>
                        <div className="border m-1 p-1 rounded rounded-3">
                            
                            <div className="input-group">
                                <div className="d-md-flex ">
                                    <div className="image mr-3">
                                        <img src={this.props.student && (this.props.student.user.avatar)} className="rounded-circle img-fluid" width={this.props.student && (this.props.student.user.avatar && ("60"))} /> 
                                    </div>
                                    <div className="mt-4">
                                        <div><b>{this.props.student && (this.props.student.matricule ? this.props.student.matricule : "") }  {this.props.student && (this.props.student.user.identity_number ?  this.props.student.user.identity_number : "")}</b></div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div>
                                    <FeatherIcon icon="user" size="16" className={"text-primary "}></FeatherIcon>{this.props.student && (this.props.student.user.last_name + " "+ this.props.student.user.family_name + " "+ this.props.student.user.first_name)} 
                                </div>
                                <div>
                                    <a href={"tel:"+this.props.student && (this.props.student.user.tel)} className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon>{this.props.student && (this.props.student.user.tel)}</a> </div>
                                <div>
                                    <a href={"mailto:"+this.props.student && (this.props.student.user.email)} className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.props.student && (this.props.student.user.email)}</a> </div>
                            
                                <div>
                                    <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.props.student && (this.props.student.user.adress)}
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <label className="fw-bolder font-monospace">Paiement</label>
                        <div className="border m-1 p-1 rounded rounded-3">
                            <div className="text-end"><small>du { this.props.student.createdAt.slice(0, 10)}</small></div>
                            <div className="text-center"><span className={ this.props.student.remaining_amount > 0 ? "widget-todo-title ml-50 border border-danger rounded rounded-3 p-1 m-1 text-danger" : "widget-todo-title ml-50  border border-primary rounded rounded-3 p-1 m-1 text-primary" }>{this.props.student.pay_mount} {this.props.student.sectionschoolfeecategory.currency} </span></div>
                            <div><span className="fw-normal"><FeatherIcon icon="folder" size="16" className={"text-primary "}></FeatherIcon> Reste :</span> <span className="widget-todo-title ml-50">{this.props.student.remaining_amount} {this.props.student.remaining_amount > 0 ? this.props.student.sectionschoolfeecategory.currency : ""} </span></div>
                            <div><span className="fw-normal"><FeatherIcon icon="credit-card" size="16" className={"text-primary "}></FeatherIcon> Section :</span> <span className="widget-todo-title ml-50">{this.props.student.sectionschoolfeecategory.section.entitled_section}</span></div>
                            {/* <div><span className="fw-normal"><FeatherIcon icon="box" size="16" className={"text-primary "}></FeatherIcon> Classe :</span> <span className="widget-todo-title ml-50">{this.props.student.sectionclass.class.entitled_class} {this.props.student.classe.title}</span></div> */}
                            <div><span className="fw-normal"><FeatherIcon icon="calendar" size="16" className={"text-primary "}></FeatherIcon> Mois : </span> <span className="widget-todo-title ml-50">{this.props.student.month.month}</span></div>
                            <div><span className="fw-normal"><FeatherIcon icon="calendar" size="16" className={"text-primary "}></FeatherIcon> Année :</span> <span className="widget-todo-title ml-50">{this.props.student.year.year}</span></div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="fw-bolder font-monospace">Responsable de l'élève</label>
                        <div className="border m-1 p-1 rounded rounded-3">
                            
                            <div className="input-group">
                                <div className="d-md-flex ">
                                    <div className="image mr-3">
                                        <img src={this.props.student && (this.props.student.user.avatar)} className="rounded-circle img-fluid" width={this.props.student && (this.props.student.user.avatar && ("60"))} /> 
                                    </div>
                                    <div className="mt-4">
                                        <div><b>{this.props.student && (this.props.student.user.matricule && (this.props.student.user.matricule) + "/" + this.props.student.user.identity_number && (this.props.student.user.identity_number))}</b></div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div>
                                    <FeatherIcon icon="user" size="16" className={"text-primary "}></FeatherIcon>{this.props.student && (this.props.student.user.first_name + " "+ this.props.student.user.last_name)} 
                                </div>
                                <div>
                                    <a href={"tel:"+this.props.student && (this.props.student.user.phone_number)} className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon>{this.props.student && (this.props.student.user.phone_number)}</a> </div>
                                <div>
                                    <a href={"mailto:"+this.props.student && (this.props.student.user.email)} className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.props.student && (this.props.student.user.email)}</a> </div>
                            
                                <div>
                                    <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.props.student && (this.props.student.user.adress)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <label className="fw-bolder font-monospace">Personnel</label>
                        <div className="border m-1 p-1 rounded rounded-3">
                            
                            <div className="input-group">
                                <div className="d-md-flex ">
                                    <div className="image mr-3">
                                        <img src={this.props.student && (this.props.student.staff.user.avatar)} className="rounded-circle img-fluid" width={this.props.student && (this.props.student.staff.user.avatar && ("60"))} /> 
                                    </div>
                                    <div className="mt-4">
                                        <div><b>{this.props.student && (this.props.student.staff.user.matricule && (this.props.student.staff.user.matricule) + "/" + this.props.student.staff.user.identity_number && (this.props.student.staff.user.identity_number))}</b></div>
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <div>
                                    <FeatherIcon icon="staff.user" size="16" className={"text-primary "}></FeatherIcon>{this.props.student && (this.props.student.staff.user.first_name + " "+ this.props.student.staff.user.last_name)} 
                                </div>
                                <div>
                                    <a href={"tel:"+this.props.student && (this.props.student.staff.user.phone_number)} className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon>{this.props.student && (this.props.student.staff.user.phone_number)}</a> </div>
                                <div>
                                    <a href={"mailto:"+this.props.student && (this.props.student.staff.user.email)} className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.props.student && (this.props.student.staff.user.email)}</a> </div>
                            
                                <div>
                                    <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.props.student && (this.props.student.staff.user.adress)}
                                </div>
                            </div>
                        </div>
                    </div>
 

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default SchoolfeeDetail;