import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import { toast } from "react-toastify";

class Section extends Component{

    state = {
        rowsections : [],
        rowsectionclasses : [],
        error : "",
        columnsections : [
            {
                label: 'Section',
                field: 'section',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Date',
                field: 'updated_at',
                sort: 'asc',
                width: 50
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 50
            }
        ],
        
        columnsectionclasses : [
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Date',
                field: 'updated_at',
                sort: 'asc',
                width: 50
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 50
            }
        ],
    }

    componentDidMount(){
        this.getSections()
        this.getSectionclasses()
    }

    async getSections(){
        var data = await axios.get("sections/operation/Supprimer");
        if(data.status === 200)
        {
            var rows = [], somme = 0;
            data.data.map((section) => {
                var commitment_date = new Date(section.updatedAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                
                    somme = 0;
                    section.sectionclasses.map((classe) => {
                        somme += classe.students.length
                    })
                rows.push(
                    {
                        section : section.short_entitled_section,
                        classe : section.sectionclasses.length ,
                        student : somme,
                        updated_at : <small className="fw-light size-13">{ commitment_date }</small>,
                        action : 
                        <span className="table_action">    
                            {
                                this.handleCheck("Modifier section") == true && (
                                    <button onClick={ () => this.restaure("sections", section.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer section") == true && (
                                    <button onClick={ () => this.delete("sections", section.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="x-octagon" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            </span>,
                    }
                )
            })

            this.setState({
                rowsections : rows
            })
        }
    }
    

    async getSectionclasses(){
        var data = await axios.get("sectionclasses/operation/Supprimer");
        
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((sectionclasse) => {
                var commitment_date = new Date(sectionclasse.updatedAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                
                rows.push(
                    {
                        classe : sectionclasse.class.short_entitled_class +" "+ sectionclasse.title + " " + sectionclasse.section.short_entitled_section ,
                        student : sectionclasse.students.length,
                        updated_at : <small className="fw-light size-13">{ commitment_date }</small>,
                        action : 
                        <span className="table_action">    
                            {
                                this.handleCheck("Modifier classe") == true && (
                                    <button onClick={ () => this.restaure("sectionclasses", sectionclasse.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer classe") == true && (
                                    <button onClick={ () => this.delete("sectionclasses", sectionclasse.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="x-octagon" size={"13"}></FeatherIcon> </button>
                                )
                            }
                        </span>,
                    }
                )
            })

            this.setState({
                rowsectionclasses : rows
            })
        }
    }
    

    delete = (model, data) =>{
        axios.delete(model+"/"+data).then((response) => {
            this.setState({
                rowsections : [],
                rowsectionclasses : [],
            })
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.getSections()
            this.getSectionclasses()
        }).catch((error)=>{
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }


    restaure = (model, data) =>{
        axios.put("restaure/"+ model +"/" +data).then((response) => {
            this.setState({
                rowsections : [],
                rowsectionclasses : [],
            })
            this.getSections()
            this.getSectionclasses()
        }).catch((error)=>{})
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        return(
            <div>
                <div className="row">
                    <div className="col-md-7">
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h5 className="h5 font-monospace">Corbeille section</h5>
                                </div>
                                {
                                    this.state.rowsections.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowsections} 
                                            columns={this.state.columnsections} 
                                            title={"Corbeille section"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    
                    </div>

                    <div className="col-md-5">
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                            <div className="text-center text-danger">{ this.state.error }</div>
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h5 className="h5 font-monospace">Corbeille classe</h5>
                                </div>
                                {
                                    this.state.rowsectionclasses.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowsectionclasses} 
                                            columns={this.state.columnsectionclasses} 
                                            title={"Corbeille classe"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                </div>

                
                        
            </div>
        )
    }
}

export default Section;