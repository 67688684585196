import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import StaffForm from "./StaffForm";
import FeatherIcon from 'feather-icons-react';
import EstablishmentprofileData from "../../../components/rh/establishmentprofiles/EstablishmentprofileData";
import LessonStaff from "./LessonStaff";
import { Helmet } from 'react-helmet';
import Listprint from "../../Listprint";
import Loading from "../../Loading";
import Profile from "../profiles/Profile";
import { toast } from "react-toastify";

class   Staff extends Component{
    state = {
        isModal : false,
        loading : false,
        profileModal : false,
        profile : "",
        isModalProfile : false,
        user : "",
        userprofile : "",
        formData : "",
        change : true,
        staffs : [],
        formDataProfile : {},
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Nom',
                field: 'staff',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Sexe',
                field: 'genre',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date naissance',
                field: 'datebirth',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Nat',
                field: 'nationality',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date Engag',
                field: 'commitment_date',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Entrée fonct',
                field: 'date_function',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Anc',
                field: 'seniority',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Etat',
                field: 'marital_status',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Diplome',
                field: 'diploma',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Fonct',
                field: 'profile',
                sort: 'asc',
                width: 120
            },
        ],
    }

    componentDidMount(){
        this.getStaffs();
    }

    async getStaffs(){
        var staffs = await axios.get('staffs');
        if(staffs.status === 200)
        {
            this.setState({
                staffs : []
            })
            this.setState({staffs : staffs.data, loading : true}) 
        }
    }

    showModal = (staff = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : staff,
        })
        this.getStaffs()
    }

    delete = (staff) =>{
        axios.delete("staffs/"+staff).then((response) => {
            this.setState({ staffs : [],  loading : false})
            this.getStaffs()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    showModalProfile = (userprofile = null, user = null) => {
        this.setState({
            isModalProfile : !this.state.isModalProfile,
            userprofile : userprofile,
            user : user,
        })
        this.getStaffs()
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
        return  permissions.some(item => val === item.permission);
    }

    change = () => {
        this.setState({
            change : !this.state.change,
            profileModal : false
        })
    }

    changeProfile = () => {
        this.setState({
            profileModal : !this.state.profileModal
        })
    }


    render(){

        var rows = [], staffs =
        this.state.staffs.map((staff) => {
            
            var family_name = staff.user.family_name ? staff.user.family_name : "";
            var first_name = staff.user.first_name ? staff.user.first_name : "";
            var date_function =  new Date(staff.date_function);
            date_function = date_function.getDate() + "-" + date_function.getMonth() + "-" + date_function.getFullYear();
            var datebirth = new Date(staff.datebirth);
            datebirth = datebirth.getDate() + "-" + datebirth.getMonth() + "-" + datebirth.getFullYear();
            var commitment_date = new Date(staff.commitment_date);
            commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();

            rows.push({
                matricule : staff.user && (<Link to={'/staffs/'+staff.id}  className="text-decoration-none">{staff.matricule}</Link>),
                staff : staff.user && (staff.user.last_name + " " + family_name + " " + first_name),
                genre : staff.user && (staff.user.genre),
                datebirth : staff.datebirth ? datebirth : "",
                nationality : staff.user && (staff.user.nationality),
                commitment_date: staff.commitment_date ? commitment_date : "",
                date_function : staff.date_function ? date_function : "",
                seniority: staff.seniority,
                marital_status : staff.user && (staff.user.marital_status),
                diploma : staff.diploma,
                profile : staff.user && (
                    staff.user.userprofiles &&(
                        staff.user.userprofiles.map((userprofile) =>{
                            if(userprofile.status === 1)
                            {
                                return(
                                    userprofile.profile.profile+ ", "
                                )
                            }
                        })
                    )
                )
            })
            

            return(
                <div className="col-md-4 col" key={staff.id}>
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body d-md-flex">
                            <div className="image mr-3">
                                <Link to={'/staffs/'+staff.id}  className="text-decoration-none">
                                    <img src={staff.user && (staff.user.avatar)} className="rounded-circle img-fluid" width={staff.user && (staff.user.avatar && ("100"))} />
                                </Link>
                            </div>
                            <div>
                                <div><Link to={'/staffs/'+staff.id}  className="text-decoration-none"><b>{ staff.matricule } / {staff.user.identity_number}</b></Link></div>
                                <div>{staff.user && (staff.user.last_name + " "+ first_name)}</div>
                                <div><a href={"tel:"+staff.user && (staff.user.tel)} rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16"></FeatherIcon> {staff.user && (staff.user.tel)}</a> </div>
                                <div><a href={"mailto:"+staff.user && (staff.user.email)} rel="noopener noreferrer" target="_blank" className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16"></FeatherIcon> {staff.user && (staff.user.email)}</a> </div>
                                <div><span className="text-info"> { staff.user && (staff.user.genre) } </span> <span className="border-1 border-primary border rounded rounded-3 px-1 py-0"> { staff.user && (staff.user.marital_status) } </span> </div>
                                <div className="text-end m-1">
                                    {
                                        staff.user && (
                                            staff.user.userprofiles &&(
                                                staff.user.userprofiles.map((userprofile) =>{
                                                    if(userprofile.status == 1)
                                                    {
                                                        return(
                                                            <span key={userprofile.profile.id} className="badge bg-success small rounded-3 mx-1" type="button" onClick={() => this.showModalProfile(userprofile, staff.user)}>{userprofile.profile.profile}, </span>
                                                        )
                                                    }
                                                })
                                            )
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                            <div className="text-end me-2 d-end mt-2">
                            {
                                this.handleCheck("Modifier personnel") == true && (
                                    <button onClick={ () => this.showModal(staff)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" className={"d-inline"} size="13"></FeatherIcon><span className="d-inline">Editer</span></button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer personnel") == true && (
                                    <button onClick={ () => this.delete(staff.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" className={"d-inline"} size="13"></FeatherIcon><span className="d-inline">Supprimer</span></button>
                                )
                            }
                            </div>
                    </div>
                </div>
            )
        })
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | staff</title>
                </Helmet>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste du personnel
                {
                    !this.state.change ? 
                    <span className="text-primary me-2 h6 border border-1 px-1 rounded rounde-3" type="button" onClick={ () => this.change()}><FeatherIcon icon={"box"} size={16} className="d-inline"></FeatherIcon>Block </span>
                    :
                    <span className="text-primary me-2 h6 border border-1 px-1 rounded rounde-3" type="button" onClick={ () => this.change()}><FeatherIcon icon={"list"} size={16} className="d-inline"></FeatherIcon>Liste </span>
                }
                {
                    this.handleCheck("Afficher profil") == true && (
                    <span className="text-primary me-2 h6 border border-1 px-1 rounded rounde-3" type="button" onClick={ () => this.changeProfile()}><FeatherIcon icon={"lock"} size={16} className="d-inline"></FeatherIcon>Profil </span>
                )}
                
                </h5>
                {
                    this.handleCheck("Ajouter personnel") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm p-0 px-1 btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="user-plus"></FeatherIcon> personnel</button>
                            </div>
                        </div>
                    )
                }
            </div>

            {
                this.state.profileModal && (
                    <Profile model="Staff" />
                )
            }
            
            
            {
                (this.state.change && !this.state.profileModal) && ( 
                    <div className="row">
                        {
                            this.state.loading ? staffs : <Loading />
                        }
                    </div>
                )
            }

            {
                (!this.state.change && rows.length > 0 && !this.state.profileModal) && ( 
                    <div className="overflow-hidden">
                        <Listprint rows={rows} columns={this.state.columns} title={"MISE EN PLACE DU PERSONNEL ENSEIGNANT ET ADMINISTRATIF"} />
                    </div>
                )
            }

            <div>
                <div className="col-md-12">
                    <LessonStaff title={"Statistique des cours par personnel"} />
                </div>
            </div>
            
            <StaffForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
            
            <EstablishmentprofileData
                isModal={this.state.isModalProfile} 
                formData={this.state.userprofile}
                fromUser = {this.state.user}
                showModal={this.showModalProfile}
             />



        </div>
        )
    }
}
export default Staff;