import React, {Component} from "react";
import axios from "axios";
import FeatherIcon from 'feather-icons-react';
import Modal from "react-bootstrap/Modal";

class EstablishmentprofileData extends Component{
    
    state = {
        reason : "",
        error  : "",
    }
    storeProfile = (type = null)=>{
        var data = {
            reason : this.state.reason,
            status : type == 1 ? 0 : 1,
        }
        
        var request = axios.put('userprofiles/'+this.props.formData.id, data) ;
        request.then((response) =>{
            this.props.showModal(false, false)
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
        })
    }

    edit = () =>{
        this.form();
    }

    form = () =>{
        this.setState({
            reason : this.props.formData ? this.props.formData.reason : ""
        })
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        return(
            <div>
                <Modal
                    show={this.props.isModal} 
                    onHide={this.props.isModal}
                    onEntered={this.edit}
                    backdrop={"static"}>
                    <Modal.Header>
                        <Modal.Title className="h6 font-monospace">Accès <span className="text-primary"> { this.props.formData && (this.props.formData.profile.profile)} </span> { this.props.fromUser && (this.props.fromUser.first_name + " "+ this.props.fromUser.last_name)} </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                    <label>Motif</label>
                    <textarea
                        value={this.state.reason}
                        onChange={this.handleInput}
                        name="reason"
                        rows={3}
                        cols={5}
                        className="form-control  "
                    />
                    <span className="text-danger text-small small">{ this.state.error }</span>
                    </Modal.Body>
                    <Modal.Footer>
                    {
                        this.handleCheck("Supprimer profil") == true && (
                            <button onClick={ () => this.storeProfile(1)} className="btn btn-outline-danger btn-sm"><FeatherIcon icon="delete"></FeatherIcon> supprimer</button>
                        )
                    }
                    <button onClick={ () => this.props.showModal(false, false)} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    {
                        this.handleCheck("Modifier profil") == true && (
                            <button onClick={() => this.storeProfile()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                        )
                    }
                    </Modal.Footer>
                </Modal> 
            </div>
        )
    }
}

export default EstablishmentprofileData;