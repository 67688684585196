import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import axios from "axios";
import Modal from "react-bootstrap/Modal";
import Loading from "../pages/Loading";

class Sidebar extends Component{

  state = {
    countData : 0,
    modal : false,
    isModal : false,
    table : []
  }

  componentDidMount(){
    this.getData()
  }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

  async getData(){
    var data = await axios.get('datamigrates');
    if(data.status === 200)
    {
      this.setState({
        countData : data.data.length
      })
    }
  }

  refresh = () => {
    this.setState({
      modal : true,
      isModal : false
    })
    axios.get('datamigrates/pull').then((response) => {
      
        this.setState({
          modal : true,
          isModal : true
        })
    }).catch((error) => {
      console.log(error)
    })
    this.refreshsend()
  }

  refreshsend = () => {
    this.setState({
      modal : true,
      isModal : false
    })
    axios.get('datamigrates/push').then((response) => {
        this.setState({
          modal : true,
          isModal : true
        })
    }).catch((error) => {
      console.log(error)
      // this.refresh()
    })
  }

  modal = () =>{
    this.setState({
      modal : false,
      isModal : false
    })
  }

  logout = (e) => {
    axios.post('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
    window.localStorage.clear();
    window.location.replace(process.env.REACT_APP_EVIVESOMA_RH)
}

change = (val) => {
  this.setState({
    table : []
  })
  var table = [];
  table.push(val)
  this.setState({
    table : table
  })
  this.getData()
}

    render(){
      
        return(

          <div className="col-auto col-md-3 col-xl-2 bg-white p-0 border border-top-0">
                <div className="sticky-top d-flex flex-column text-white">
                    <a href="/" className="text-white text-decoration-none shadow text-lef font-weight-bold"  style={{ backgroundColor : "rgb(0, 108, 189)", fontWeight : 600}}>
                        <span className="fs-4 d-sm-inline"><img src={ process.env.REACT_APP_APIEVIVESOMA +'/files/evivesoma.png'} alt="logo EviveSoma" className="" height={40+"px"}  /></span>
                        <span className="ms-2 fs-6 d-none d-sm-inline">{ process.env.REACT_APP_NAME }</span>
                    </a>
                    
                    <ul className="nav nav-pills flex-column mb-sm-auto ms-2 mt-2" id="menu">

                    {
                      this.handleCheck("Afficher tableau de bord") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2"+ this.state.table.filter(data => data == "/").map((val) => { return "active" })} onClick={() => this.change("/")} aria-current="page" to={"/"}>
                            <FeatherIcon icon="airplay" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Dashboard</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher élève") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "students").map((val) => { return "active" })} onClick={() => this.change("students")} aria-current="page" to={"/students"}>
                            <FeatherIcon icon="users" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Elèves</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher élève") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "identifications").map((val) => { return "active" })} onClick={() => this.change("identifications")} aria-current="page" to={"/identifications"}>
                            <FeatherIcon icon="user-check" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Identification</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher parent") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "parentfamilies").map((val) => { return "active" })} onClick={() => this.change("parentfamilies")} aria-current="page" to={"/parentfamilies"}>
                            <FeatherIcon icon="user" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Parents</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher classe") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "classes").map((val) => { return "active" })} onClick={() => this.change("classes")} aria-current="page" to={"/classes"}>
                            <FeatherIcon icon="box" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Classe</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher section") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "sections").map((val) => { return "active" })} onClick={() => this.change("sections")} aria-current="page" to={"/sections"}>
                            <FeatherIcon icon="credit-card" size={"20"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Section</span>
                          </Link>
                        </li>
                      )
                    }


                    {
                      this.handleCheck("Afficher personnel") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "staffs").map((val) => { return "active" })} onClick={() => this.change("staffs")} aria-current="page" to={"/staffs"}>
                            <FeatherIcon icon="users" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Personnel</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher etablissement") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "establishments").map((val) => { return "active" })} onClick={() => this.change("establishments")} aria-current="page" to={"/establishments"}>
                            <FeatherIcon icon="home" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Etablissement</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher localisation") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "parentfamilies").map((val) => { return "active" })} onClick={() => this.change("parentfamilies")} aria-current="page" to={"/localisations"}>
                            <FeatherIcon icon="flag" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">localisations</span>
                          </Link>
                        </li>
                      )
                    }
                    
                    {
                      this.handleCheck("Afficher fournisseur") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "suppliers").map((val) => { return "active" })} onClick={() => this.change("suppliers")} aria-current="page" to={"/suppliers"}>
                            <FeatherIcon icon="users" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Fournisseurs</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher congé") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "leaves").map((val) => { return "active" })} onClick={() => this.change("leaves")} aria-current="page" to={"/leaves"}>
                            <FeatherIcon icon="calendar" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Congé</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Profil admin") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "reminds").map((val) => { return "active" })} onClick={() => this.change("reminds")} aria-current="page" to={"/reminds"}>
                            <FeatherIcon icon="bell" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Rappel</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Ajouter élève") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "sms").map((val) => { return "active" })} onClick={() => this.change("sms")} aria-current="page" to={"/sms"}>
                            <FeatherIcon icon="mail" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">SMS</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher rapport") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "reports").map((val) => { return "active" })} onClick={() => this.change("reports")} aria-current="page" to={"/reports"}>
                            <FeatherIcon icon="archive" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Rapports</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher corbeille") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "trash").map((val) => { return "active" })} onClick={() => this.change("trash")} aria-current="page" to={"/trash"}>
                            <FeatherIcon icon="trash" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Corbeille</span>
                          </Link>
                        </li>
                      )
                    }

                    {
                      this.handleCheck("Afficher archive") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "+ this.state.table.filter(data => data == "archives").map((val) => { return "active" })} onClick={() => this.change("archives")} aria-current="page" to={"/archives"}>
                            <FeatherIcon icon="database" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Archive</span>
                          </Link>
                        </li>
                      )
                    }

                    {/* {
                      this.handleCheck("Afficher tableau de bord") == true && (
                        <li className="nav-item">
                          <Link className={"nav-link p-2 "}  onClick={() => this.refresh()}>
                            <FeatherIcon icon="share-2" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Refresh ({this.state.countData > 0 ? this.state.countData : "0"})</span>
                          </Link>
                        </li>
                      )} */}

                        <li className="nav-item">
                          <Link className="nav-link p-2" onClick={() => this.logout()} aria-current="page" to={"#"}>
                            <FeatherIcon icon="lock" size={"16"}></FeatherIcon>
                            <span className="ms-2 d-none d-sm-inline">Déconnexion</span>
                          </Link>
                        </li>

                    </ul>
                </div>
                
                <Modal
                  show={this.state.modal} 
                  onHide={this.state.modal}
                  backdrop={"static"}>
                  <Modal.Header>
                      <Modal.Title className="h6 font-monospace">Actualisation des données</Modal.Title>
                  </Modal.Header>
                    <Modal.Body>
                        <div className="text-center font-monospace">{ this.state.isModal ? "" : <div><Loading /></div>}</div>
                      </Modal.Body>
                    <Modal.Footer>
                      {
                        this.state.isModal && (
                          <button onClick={() => this.modal() } className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        )
                      }
                    </Modal.Footer>
                </Modal>
            </div>

 
             
        )
    }
}
export default Sidebar;