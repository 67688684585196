import React, {Component} from "react";

class Headerpdf extends Component{

    render(){
        var profile = JSON.parse(localStorage.getItem('profile'))
        return(
            <div className="userIdentity">
                <div className="header">
                    <div className="d-flex bd-highlight">
                        <div className="p-0 bd-highlight">
                            <img src={profile.profile.establishment.logo} alt=""  className="rounded img-fluid" style={{ width:70+"px" }} />
                        </div>
                        <div className="p-0 flex-grow-1 bd-highlight text-center">
                            <div className="">
                                <div  className=" " style={{ fontSize : 12 }}>
                                    MINISTER DE L'ENSEIGNEMENT PRIMAIRE SECONDAIRE<br />ET TECHNIQUE (EPST)
                                </div>
                                <div className="" style={{ fontSize : 12 }}>{ profile.profile.establishment.name}</div>
                                <div className="text-secondary " style={{ fontSize : 12 }}>{"<<"} { profile.profile.establishment.short_name} {">>"}</div>
                                <div className="" style={{ fontSize : 12 }}>Ecole { profile.profile.establishment.type} agréée sous l'arrêté ministérial</div>
                                <div className="" style={{ fontSize : 12 }}>{profile.profile.establishment.identifiant_agree}</div>
                            </div>
                        </div>
                        <div className="p-0 bd-highlight">
                            <img src={"/assets/drc.png"} alt=""  className="rounded img-fluid" style={{ width:70+"px" }} />
                        </div>
                    </div> 
                    <div className=" ">
                        <small>
                            <center style={{ fontSize : 10, color : 'blue'}}>
                                { profile.profile.establishment.email} { profile.profile.establishment.tel} / { profile.profile.establishment.city}<br />
                                { profile.profile.establishment.short_name} { profile.profile.establishment.slogan}
                            </center>
                        </small>
                    </div>
                    
                </div>
            </div>
        )
    }
}

export default Headerpdf