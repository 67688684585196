import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import StudentForm from "../students/StudentForm";
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";
import YearForm from "../years/YearForm";

class ArchiveDetail extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        isModal : false,
        isModalArchive : false,
        year : "",
        formData : {},
        formDataarchive : {},
        classe : "",
        students : [],
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Statut',
                field: 'status',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Responsable',
                field: 'responsible',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Archiver',
                field: 'created_at',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getArchives();
        this.getYear();
    }

    async getYear(){
        var year = await axios.get('years/' + this.props.match.params.year)
        if(year.status === 200)
        {
            this.setState({ year : year.data})
        }
    }

    async getArchives(){
        var students = await axios.get('students/year/' + this.props.match.params.year)
        this.setState({
            students : [],
            loading : false,
        })
        if(students.status === 200){
            this.setState({
                students : students.data,
                loading : true
            })
        }
    }

    showModal = (student = null, classe=false) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : student,
            classe : classe ? student : null
        })
        this.getArchives()
    }

    showModalArchive = (year = null) => {
        this.setState({
            isModalArchive : !this.state.isModalArchive,
            formDataarchive : year,
        })
        this.getArchives()
    }
    
  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        var rows = []

        var count = 0, rows = [],  students = "", sections = [], sectionclasses = [];

        this.state.students.map((student) => {
            count = sections.filter(data => data.id == student.sectionclass.section.id).length;
            if(count === 0)
                sections.push( student.sectionclass.section);
        });

        this.state.students.map((student) => {
            count = sectionclasses.filter(data => data.id == student.sectionclass.id).length;
            if(count === 0)
                sectionclasses.push(student.sectionclass);
        });

        students =
        sections.map((section) => {
            return(
                <div className="card m-2 shadow rounded  rounded-3 " key={section.id}>
                    <div className="card-body">
                        <h6 className="fw-bolder font-monospace text-center">{section.entitled_section + " "+section.short_entitled_section}</h6>
                        
                        {
                            sectionclasses.filter(data => data.section.id == section.id).map((sectionclasse) => {
                                rows = []
                                return(
                                    <div className="col-md-12 mb-4" key={sectionclasse.id}>
                                        <div className="card  mb-2 rounded rounded-3">
                                            <div className="card-body">

                                                <div className="text-center m-2 font-monospace "> 
                                                    <span className="p-1 px-2 border border-1 border-dark rounded rounded-3">{sectionclasse.class.short_entitled_class} {sectionclasse.title} <small>{section.short_entitled_section} </small> </span> 
                                                </div>
                                                <div className="text-end text-primary"> {sectionclasse.status} </div>
                                                </div>
                                                {
                                                    this.state.students.filter(studen => studen.sectionclass.id === sectionclasse.id).map((student) => {
                                                        var commitment_date = new Date(student.year.updatedAt);
                                                        commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                                                        var last_name = student.user ? student.user.last_name : "";
                                                        var first_name = student.user ? student.user.first_name : "";
                                                        var family_name = student.user ? student.user.family_name : "";
                                                        rows.push(
                                                            {
                                                                matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none">{student.matricule}</Link>),
                                                                student : last_name + " " + family_name + " " + first_name,
                                                                status : student.status == 1 ? <span className="badge text-primary alert-primary" >Actif</span> : <span className={ student.status == 0 ? "badge text-danger alert-danger" : "badge text-warning alert-warning"} > { student.status == 0 ? "Abandon" : "Non actif" }</span>,
                                                                responsible : student.user && (student.user.user && (student.user.user.first_name + " " +student.user.user.last_name)),
                                                                created_at : <small className="fw-light">{ commitment_date }</small>,
                                                            }
                                                        )
                                                    })
                                                }
                                                
                                                <div>
                                                {
                                                    rows.length > 0 && (
                                                        <Listprint columns={this.state.columns} rows={rows} title={"Archive des élèves de " + sectionclasse.class.short_entitled_class + " "+ sectionclasse.title + "  "+ section.short_entitled_section +" # "+ this.state.year.year}/>
                                                    )
                                                }                                                                    
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div> 
            )
        })
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | archived data</title>
                </Helmet>
            <div className=" pt-3 pb-2 mb-3 border-bottom">
                <h6 className="h5 font-monospace">Archive scolaire resources</h6>
            </div>

            <div className=" mb-4 overflow-auto">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        <div className="overflow-hidden">
                            <li className="widget-todo-item list-group-item m-2 border border-secondary rounded-3 shadow mb-2">
                                <div
                                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                    <div className="widget-todo-title-area d-flex align-items-center">
                                        <FeatherIcon icon="package" className='cursor-move'></FeatherIcon> 
                                        <label><span className="widget-todo-title ml-50 fw-bolder" >{this.state.year ? this.state.year.year : "" } ({ this.state.students.length}) </span></label>
                                    </div>
                                    {
                                        this.handleCheck("Modifier année") == true && (
                                            <div className="widget-todo-item-action d-flex align-items-center">
                                                <b><FeatherIcon icon="edit-2" size="16" className='cursor-move text-primary' type="button" onClick={() => this.showModalArchive(this.state.year)}></FeatherIcon></b>
                                            </div>
                                        )
                                    }
                                </div>
                            </li>
                            <div className="col-md-12 fluid-content">
                            {this.state.loading > 0 ? students : <Loading/>} 
                        </div>
                    </div>
                    </ul>
                </div>
            </div>
            
            <YearForm 
                isModal={this.state.isModalArchive} 
                formData={this.state.formDataarchive} 
                showModal={this.showModalArchive}
                archive = {true}
            />

            <StudentForm
                isArchived = {true}
                isModal={this.state.isModal} 
                formData={this.state.formData}
                classe={this.state.classe}
                showModal={this.showModal}
            />
        </div>
        )
    }
}
export default ArchiveDetail;