import React, {Component} from "react";
import axios from "axios";
import {Pie, Doughnut} from 'react-chartjs-2';
import {Chart, ArcElement} from 'chart.js'
import StudentList from "./rh/students/StudentList";
import StudentBar from "./rh/students/StudentBar";
import StudentLine from "./rh/students/StudentLine";
import FeatherIcon from 'feather-icons-react';
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import ReactToPrint from "react-to-print";
import Headerpdf from "./users/Headerpdf";
import UserIdentity from "./users/UserIdentity";
Chart.register(ArcElement);

class Dashboard extends Component{
    
    state = {
      sections : [],
        pie : { labels : [], datasets : [] },
        parentfamilies : 0,
        suppliers : 0,
        staffs : 0
      }

      componentDidMount(){
        this.refreshToken();
        this.getsections()
        this.getStaffs()
        this.getSuppliers()
        this.getParentfamilies()
      }

      async refreshToken () {
        try{
          // await axios.get("sendsms");
          var token = await axios.get('token');
          if(token.status === 200)
          {
              var user = (token.data.data);
              localStorage.setItem("user", JSON.stringify(user));
              //console.log(token.data)
              localStorage.setItem("REACT_APP_EVIVESOMA_RH", token.data.ipAddress ? "http://" +token.data.ipAddress + ":8080/csedafstat" : "Wi-Fi not connect")
          }
          else{
              axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
              window.localStorage.clear();
              window.location.replace(process.env.REACT_APP_EVIVESOMA_RH + `/login`)
          }
        }catch(error) {
            axios.delete('logout').then((re) => { console.log("disconnect") }).catch((erro) => { console.log("error") });
            window.localStorage.clear();
            window.location.replace(process.env.REACT_APP_EVIVESOMA_RH + `/login`)
        }
    }
    
    async getsections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data}) 
        }
    }

      async getStaffs(){
        var staffs = await axios.get('staffs');
        if(staffs.status === 200)
        {
            this.setState({staffs : staffs.data.length}) 
        }
    }

    async getParentfamilies(){
        var parentfamilies = await axios.get('parentfamilies');
        if(parentfamilies.status === 200)
        {
            this.setState({parentfamilies : parentfamilies.data.length}) 
        }
    }

    async getSuppliers(){
      var suppliers = await axios.get('suppliers');
      if(suppliers.status === 200)
      {
          this.setState({suppliers : suppliers.data.length}) 
      }
    }


    render(){
      var ipAddress = localStorage.getItem("REACT_APP_EVIVESOMA_RH");
       
      var sections = "", students = 0, student = 0, studentTotal = 0, classes = 0, labels = [], data = [], labelBar = [], dataBar = [] ;
      
      sections = 
      this.state.sections.map((section) => {
        students = 0
        section.sectionclasses.sort((a, b) => a.class.short_entitled_class > b.class.short_entitled_class ? 1 : -1).map((sectionclasse) => {
            student = parseInt(sectionclasse.students.length)
            students = parseInt(students) + student
            labels.push(sectionclasse.class.short_entitled_class + "  " + sectionclasse.title + " " + section.short_entitled_section)
            data.push(student)
            
        })
        studentTotal = parseInt(studentTotal) + parseInt(students)
        classes = parseInt(classes) + parseInt(section.sectionclasses.length)
        labelBar.push(section.short_entitled_section)
        dataBar.push(students)

        // pie sections
        // labels.push(section.entitled_section)
        

        return(
          <div className="col-md-3" key={section.id}>
            <div className="card shadow overflow-hidden rounded rounded-3 mb-3">
              <div className="card-body py-3 px-auto">
                <div className="d-flex align-items-center">
                  <div className="flex-shrink-0">
                    <Link to={"/students"} className="text-decoration-none">
                      <FeatherIcon icon={"trello"} size="60" className={"text-dark"}></FeatherIcon>
                    </Link>
                  </div>
                  <div className="ms-1">
                    <Link to={"/classes"}  className="text-decoration-none text-dark">
                      <b className="font-monospace">{section.entitled_section}</b>
                    </Link><hr />
                    <Link to={"/students"}  className="text-decoration-none text-dark">
                      <span className="text-primary h3 m-0">{ students }</span> <span>élèves</span>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
        })

      var dataPie = { 
        labels : labelBar,
        datasets : [
          {
            label: 'Rainfall',
            backgroundColor: [
              '#B21F00',
              '#C9DE00',
              '#2FDE00',
              '#00A6B4',
              '#6800B4',
              '#045fc0',

              '#501800',
              '#4B5000',
              '#175000',
              '#003350',
              '#35014F',
              '#013f50'
              
            ],
            hoverBackgroundColor: [
            '#501800',
            '#4B5000',
            '#175000',
            '#003350',
            '#35014F',
            '#013f50',

            '#B21F00',
            '#C9DE00',
            '#2FDE00',
            '#00A6B4',
            '#6800B4',
            '#045fc0'
            
            ],
            data: dataBar
          }
        ]
      };

      var state = {
        labels: ['Eleves', 'Personnel', 'Parent', 'Fournisseur'],
        datasets: [
          {
            label: 'Rainfall',
            backgroundColor: [
              '#B21F00',
              '#C9DE00',
              '#2FDE00',
              '#00A6B4'
            ],
            hoverBackgroundColor: [
            '#501800',
            '#4B5000',
            '#175000',
            '#003350'
            ],
            data: [studentTotal, this.state.staffs, this.state.parentfamilies, this.state.suppliers]
          }
        ]
      }


      

        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | dashboard</title>
                </Helmet>
                <div className="d-flex justify-content-between"><span></span><a href={  ipAddress } className="text-small small font-11"><i>{ ipAddress }</i></a></div>
                <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 border-bottom mb-2">
                    <h5 className="h5 font-monospace">Dashboard </h5>
                    <div className="btn-toolbar mb-2 mb-md-0">
                        
                        <button type="button" className="btn btn-sm btn-outline-teal btn-outline-secondary me-1 dropdown-toggle">
                            <FeatherIcon icon="calendar"  size="17" className={"text-dark"}></FeatherIcon>
                            Année actuelle
                        </button>
                        <ReactToPrint
                            trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                            content={() =>  this.componentRef}
                        />
                    </div>
                </div>

                <div  ref={el => (this.componentRef = el)}>
                  <Headerpdf />
                  <UserIdentity title={"Statistique"} /> 
                      
                  <div className="row ">
                    <div className="col-md-6">
                      <div className="row print">
                      
                        <div className="col-md-6">
                          <div className="card shadow overflow-hidden rounded rounded-3 mb-3">
                            <div className="card-body py-3 px-auto">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                <Link to={"/students"}  className="text-decoration-none text-dark">
                                  <FeatherIcon icon={"users"} size="60" className={"text-dark"}></FeatherIcon>
                                </Link>
                                </div>
                                <div className="ms-1">
                                <Link to={"/students"}  className="text-decoration-none text-dark">
                                  <span className="text-primary h3 m-0">{ studentTotal }</span> <span>élèves</span>
                                </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card shadow overflow-hidden rounded rounded-3 mb-3">
                            <div className="card-body py-3 px-auto">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                <Link to={"/parentfamilies"}  className="text-decoration-none text-dark">
                                  <FeatherIcon icon={"users"} size="60" className={"text-dark"}></FeatherIcon>
                                </Link>
                                </div>
                                <div className="ms-1">
                                  <Link to={"/parentfamilies"}  className="text-decoration-none text-dark">
                                    <span className="text-primary h3 m-0">{ this.state.parentfamilies }</span> <span>Parents</span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card shadow overflow-hidden rounded rounded-3 mb-3">
                            <div className="card-body py-3 px-auto">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                  <Link to={"/suppliers"}  className="text-decoration-none text-dark">
                                    <FeatherIcon icon={"users"} size="60" className={"text-dark"}></FeatherIcon>
                                  </Link>
                                </div>
                                <div className="ms-1">
                                <Link to={"/suppliers"}  className="text-decoration-none text-dark">
                                  <span className="text-primary h3 m-0">{ this.state.suppliers }</span> <span>fournisseurs</span>
                                </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card shadow overflow-hidden rounded rounded-3 mb-3">
                            <div className="card-body py-3 px-auto">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                <Link to={"/staffs"}  className="text-decoration-none text-dark">
                                  <FeatherIcon icon={"users"} size="60" className={"text-dark"}></FeatherIcon>
                                  </Link>
                                </div>
                                <div className="ms-1">
                                <Link to={"/staffs"}  className="text-decoration-none text-dark">
                                  <span className="text-primary h3 m-0">{ this.state.staffs}</span> <span>personnels</span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card shadow overflow-hidden rounded rounded-3 mb-3">
                            <div className="card-body py-3 px-auto">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                <Link to={"/sections"}  className="text-decoration-none text-dark">
                                  <FeatherIcon icon={"credit-card"} size="60" className={"text-dark"}></FeatherIcon>
                                </Link>
                                </div>
                                <div className="ms-1">
                                <Link to={"/sections"}  className="text-decoration-none text-dark">
                                  <span className="text-primary h3 m-0">{ this.state.sections.length}</span> <span>Sections</span>
                                </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="card shadow overflow-hidden rounded rounded-3 mb-3">
                            <div className="card-body py-3 px-auto">
                              <div className="d-flex align-items-center">
                                <div className="flex-shrink-0">
                                <Link to={"/classes"}  className="text-decoration-none text-dark">
                                  <FeatherIcon icon={"box"} size="60" className={"text-dark"}></FeatherIcon>
                                  </Link>
                                </div>
                                <div className="ms-1">
                                <Link to={"/classes"}  className="text-decoration-none text-dark">
                                  <span className="text-primary h3 m-0">{ classes }</span> <span>Classes</span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                    {/* {charts} */}
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-6">
                          {
                            dataPie.labels.length > 0 && (
                                <Pie
                                    data={ dataPie }
                                    options={{
                                        title:{
                                        display:true,
                                        text:'Average Rainfall per month',
                                        fontSize:20
                                        },
                                        legend:{
                                        display:true,
                                        position:'right'
                                        }
                                    }}
                                />
                              )
                            }
                        </div>
                        <div className="col-6">
                            <Doughnut
                                data={state}
                                options={{
                                    title:{
                                    display:true,
                                    text:'Average Rainfall per month',
                                    fontSize:20
                                    },
                                    legend:{
                                    display:true,
                                    position:'right'
                                    }
                                }}
                            />
                        </div>
                      </div>
                    </div>
                    {/* end charts */}
                  </div>
                    {/* {end row} */}
                  <div className="row print">
                    {/* establishment section */}
                    {
                      sections
                    }
                  </div>
                </div>
                
                {/* start store and update */}

                <div className="row">

                  <div className="col-md-6">
                    <StudentList orderBy={"Ajouter"} title="Derniers élèves enregistrés" />
                  </div>

                  <div className="col-md-6">
                    <StudentList orderBy={"Editer"}  title="Derniers élèves modifiés"/>
                  </div>

                  <div className="col-md-12">
                      <div className="card shadow overflow-hidden mb-4">
                          <div className="card-body">
                          <div className="h6 border-bottom pb-2 font-monospace">Elèves par classe</div>
                          {
                             labels.length > 0 && ( <StudentLine labels={labels} data={data} title={"Elèves par classe"} />)
                          }
                          </div>
                      </div>
                  </div>
                
                  <div className="col-md-6">
                    <StudentList orderBy={"Archiver"} title="Derniers élèves archivés"/>
                  </div>

                  <div className="col-md-6">
                    <StudentList orderBy={"Supprimer"} title="Derniers élèves supprimés"/>
                  </div>

                </div>

                <div className="row">

                  <div className="col-md-12">
                      <div className="card shadow overflow-hidden mb-4">
                        <div className="card-body">
                          <div className="h6 border-bottom pb-2 font-monospace">Elèves par section</div>
                          {
                             labelBar.length > 0 && (
                                <StudentBar labels={labelBar} data={dataBar} title={"Elèves par section"}  />
                             )
                          }
                          </div>
                      </div>
                  </div> 
                </div>

            </div>
        )
    }
}
export default Dashboard;