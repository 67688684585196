import React, {Component} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import StudentForm from "./StudentForm";
import FeatherIcon from 'feather-icons-react';
import EstablishmentprofileData from "../../../components/rh/establishmentprofiles/EstablishmentprofileData";
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import EstabsectschoolfeecatstudcategoryForm from "./EstabsectschoolfeecatstudcategoryForm";
import Loading from "../../Loading";
import { toast } from "react-toastify";

class   StudentIdentification extends Component{

    state = {
        isModal : false,
        isModalProfile : false,
        isModalCategory : false,
        user : "",
        matricule : "",
        userestablishprofile : "",
        formData : "",
        students : [],
        classe : "",
        columns : [
            {
                label: 'N°',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Sexe',
                field: 'genre',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Classe',
                field: 'class',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Lieu,date naissance',
                field: 'adress',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Père',
                field: 'father',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Profession',
                field: 'fatherWork',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Mère',
                field: 'monther',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Profession',
                field: 'montherWork',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Téléphone',
                field: 'tel',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Quartier',
                field: 'quartier',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Commune, Groupement',
                field: 'groupment',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Territoire',
                field: 'territory',
                sort: 'asc',
                width: 150
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getStudents();
    }

    async getStudents(){
        var students = await axios.get('students');
        if(students.status === 200)
        {
            this.setState({
                students : []
            });
            this.setState({
                students : students.data
            })
        }
    }


    showModal = (student = null, classe=false) => {
        this.setState({
            students : !this.state.isModal ? this.state.students : [],
        })
        this.getStudents()
        this.setState({
            isModal : !this.state.isModal,
            formData : student,
            classe : classe ? student : null
        })
    }

    delete = (student) =>{
        axios.delete("students/"+student).then((response) => {
            this.setState({
                students : []
            })
            this.getStudents()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    showModalProfile = (userestablishprofile = null, user = null) => {
        this.setState({
            isModalProfile : !this.state.isModalProfile,
            userestablishprofile : userestablishprofile,
            user : user,
        })
        this.getStudents()
    }

    showModalCategory = (student = null) => {
        this.setState({
            students : !this.state.isModalCategory ? this.state.students : []
        })
        this.getStudents()
        this.setState({
            isModalCategory : !this.state.isModalCategory,
            formData : student,
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){

        var matricule = 1, count = 0, rows = [],  students = "", section = [], sectionclasses = [];

        this.state.students.map((student) => {
            count = section.filter(data => data.id == student.sectionclass.section.id).length;
            if(count === 0)
                section.push( student.sectionclass.section);
        });

        this.state.students.map((student) => {

            count = sectionclasses.filter(data => data.id == student.sectionclass.id).length;
            if(count === 0)
                sectionclasses.push(student.sectionclass);
        });

        students = 
        section.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1).map((section) => {
            return(
                <div className="card mb-2 shadow rounded  overflow-hidden rounded-3 " key={section.id}>
                    <div className="card-body row">
                        <h6 className="h6 font-monospace font-bolder">{section.entitled_section + " ("+section.short_entitled_section + ")"}</h6>
                        
                        {
                            sectionclasses.filter(data => data.section.id == section.id).sort((a, b) => a.class.short_entitled_class > b.class.short_entitled_class ? 1 : -1).map((sectionclasse) => {
                                
                                return(
                                    <div className="col-md-12 mb-4" key={sectionclasse.id}>
                                        <div className="card  mb-2 rounded rounded-3">
                                            <div className="card-body p-0 m-0 mt-1">
                                                <div className="text-center font-monospace "> 
                                                    <button className="btn btn-sm btn-outline-secondary me-1">{sectionclasse.class.short_entitled_class} {sectionclasse.title} <small>{section.entitled_section} </small> </button> 
                                                    {
                                                        this.handleCheck("Ajouter élève") == true && (
                                                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(sectionclasse.id, true)}><FeatherIcon icon="user-plus" size={20}></FeatherIcon> élève</button>
                                                        )
                                                    }
                                                </div>
                                                
                                                {
                                                    this.state.students.filter(data => data.sectionclass.id === sectionclasse.id).map((student) =>{
                                                        var groupment = student.groupment ? student.groupment.name : null;
                                                        var commune = student.commune ? student.commune.name : "";
                                                        commune += groupment ?  ", "+ groupment : "";
                                                        matricule = matricule + 1;
                                                        var adress = student.user ? student.user.adress ? student.user.adress : "" : "";
                                                        var commitment_date = new Date(student.user.datebirth);
                                                        commitment_date = student.user.datebirth ? commitment_date.toLocaleDateString('en-GB') : null;
                                                        var last_name = student.user ? student.user.last_name : "";
                                                        var first_name = student.user ? student.user.first_name : "";
                                                        var family_name = student.user ? student.user.family_name : "";
                                                        var tel = student.monther ?  student.monther.tel : "";
                                                        tel +=  student.father ? ", "+ student.father.tel : "";
                                                        rows.push(
                                                                {
                                                                    matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none">{student.matricule}</Link>),
                                                                    matricule : student.user && (student.matricule),
                                                                    student : last_name + " " + family_name + " " + first_name,
                                                                    genre : student.user ? student.user.genre : "",
                                                                    class :  student.sectionclass ? student.sectionclass.class.short_entitled_class + "" + student.sectionclass.section.short_entitled_section : "",
                                                                    adress : commitment_date ? adress + ", " + commitment_date : adress,
                                                                    father : student.father && (student.father && (student.father.first_name + " " +student.father.last_name)),
                                                                    fatherWork : student.father && (student.father && (student.father.profession)),
                                                                    monther : student.monther && (student.monther && (student.monther.first_name + " " +student.monther.last_name)),
                                                                    montherWork : student.monther && (student.monther && (student.monther.profession)),
                                                                    tel : tel,
                                                                    quartier : student.quartier ? student.quartier.name : "" ,
                                                                    groupment : commune ,
                                                                    territory : student.territory ? student.territory.name : "" ,
                                                                    action : <span className="table_action">   
                                                                    {/* {
                                                                        this.handleCheck("Modifier élève") == true && ( 
                                                                            <button onClick={ () => this.showModal(student)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={"13"}></FeatherIcon> </button>
                                                                        )
                                                                    } */}
                                                                    {
                                                                        this.handleCheck("Afficher élève") == true && (
                                                                            <Link to={"/studentfile/" + student.id} className="btn btn-outline-primary border-primary py-0 px-1 rounded rounded-3 text-decoration-none"><FeatherIcon icon="file" size={"13"}></FeatherIcon> </Link>
                                                                        )
                                                                    }
                                                                    </span>,
                                                                }
                                                            )
                                                        })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>

            )
        })
         

        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | students</title>
                </Helmet>
            <div className=" align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Fiche d'identification  des élèves
                {
                    this.handleCheck("Ajouter élève") == true && (
                        <button type="button" className="btn btn-sm ms-3 btn-outline-primary" onClick={() => this.showModal(null, true)}><FeatherIcon icon="user-plus" size={20}></FeatherIcon> élève</button>
                    )
                }
                </h5>
            </div>                
                

            <div>
                {this.state.students.length > 0 ? 

                    <div style={{  fontSize : 14 +"px" }} className="table">
                                                
                        <Listprint columns={this.state.columns} rows={rows} title={"Fiche d'identification  des élèves" }/>
                        
                    </div>

                 : <Loading/>} 
            </div>
            
            {
                this.state.isModal && (
                    <StudentForm 
                        isModal={this.state.isModal} 
                        formData={this.state.formData}
                        classe={this.state.classe}
                        matricule = {matricule}
                        showModal={this.showModal}
                    />
                )
            }

            {
                this.state.isModalCategory && (
                    <EstabsectschoolfeecatstudcategoryForm
                        isModal={this.state.isModalCategory} 
                        formData={this.state.formData}
                        classe={this.state.classe}
                        showModal={this.showModalCategory}
                    />
                )
            }

             {
                this.state.isModalCategory && (
                    <EstablishmentprofileData
                        isModal={this.state.isModalProfile} 
                        formData={this.state.userestablishprofile}
                        fromUser = {this.state.user}
                        showModal={this.showModalProfile}
                    />
                )
             }

        </div>
        )
    }
}
export default StudentIdentification;