import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import Section from "./Section";
import { Helmet } from 'react-helmet';
import { toast } from "react-toastify";

class Trash extends Component{

    state = {
        rowstudents : [],
        rowstaffs : [],
        rowparents : [],
        rowsuppliers : [],
        rowsprofiles : [],
        error : "",
        columns : [
            {
                label: 'Num',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: "Nom",
                field: 'name',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Année',
                field: 'classe',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'updated_at',
                sort: 'asc',
                width: 120
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getDatastudents()
        this.getDataparentfamilies()
        this.getDatastaffs()
        this.getDatasuppliers()
        this.getDataprofiles()
    }

    async getDatastudents(){
        var data = await axios.get("students/operation/Supprimer");
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((student) => {
                var commitment_date = new Date(student.updatedAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                var last_name = student.user ? student.user.last_name : "";
                var first_name = student.user ? student.user.first_name : "";
                var family_name = student.user ? student.user.family_name : "";
                rows.push(
                    {
                        matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none">{student.matricule}</Link>),
                        name : last_name + " " + family_name + " " + first_name,
                        classe : <small className="fw-light size-13">{ student.sectionclass.class.short_entitled_class + " "+ student.sectionclass.title + " " +student.sectionclass.section.short_entitled_section  }</small>,
                        updated_at : <small className="fw-light size-13">{ commitment_date}</small>,
                        action : 
                        <span className="table_action">    
                            {
                                this.handleCheck("Modifier élève") == true && (
                                    <button onClick={ () => this.restaure("students", student.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="user-check" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer élève") == true && (
                                    <button onClick={ () => this.delete("students", student.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            </span>,
                    }
                )
            })

            this.setState({
                rowstudents : rows
            })
        }
    }

    async getDataparentfamilies(){
        var data = await axios.get("parentfamilies/operation/Supprimer");
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((parentfamily) => {
                var commitment_date = new Date(parentfamily.updatedAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                var last_name = parentfamily.user ? parentfamily.user.last_name : "";
                var first_name = parentfamily.user ? parentfamily.user.first_name : "";
                
                rows.push(
                    {
                        matricule : parentfamily.user.tel,
                        name : last_name + " " +first_name,
                        classe : <small className="fw-light size-13">{ parentfamily.year.year}</small>,
                        updated_at : <small className="fw-light size-13">{ commitment_date }</small>,
                        action : 
                        <span className="table_action">    
                            {
                                this.handleCheck("Modifier parent") == true && (
                                    <button onClick={ () => this.restaure("parentfamilies", parentfamily.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="user-check" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer parent") == true && (
                                    <button onClick={ () => this.delete("parentfamilies", parentfamily.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                )
                            }
                        </span>,
                    }
                )
            })

            this.setState({
                rowparents : rows
            })
        }
    }

    async getDatastaffs(){
        var data = await axios.get("staffs/operation/Supprimer");
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((staff) => {
                var commitment_date = new Date(staff.updatedAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                var last_name = staff.user ? staff.user.last_name : "";
                var first_name = staff.user ? staff.user.first_name : "";
                
                rows.push(
                    {
                        matricule : staff.user && (<Link to={'/staffs/'+staff.id}  className="text-decoration-none">{staff.matricule}</Link>),
                        name : last_name + " " + first_name,
                        classe : <small className="fw-light size-13">{ staff.year.year}</small>,
                        updated_at : <small className="fw-light size-13">{ commitment_date }</small>,
                        action : 
                        <span className="table_action">    
                            {
                                this.handleCheck("Modifier personnel") == true && (
                                    <button onClick={ () => this.restaure("staffs", staff.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="user-check" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer personnel") == true && (
                                    <button onClick={ () => this.delete("staffs", staff.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                )
                            }
                        </span>,
                    }
                )
            })

            this.setState({
                rowstaffs : rows
            })
        }
    }

    async getDatasuppliers(){
        var data = await axios.get("suppliers/operation/Supprimer");
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((supplier) => {
                var commitment_date = new Date(supplier.updatedAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                var last_name = supplier.user ? supplier.user.last_name : "";
                var first_name = supplier.user ? supplier.user.first_name : "";
                
                rows.push(
                    {
                        matricule : supplier.user.tel,
                        name : last_name + " " + first_name,
                        classe : <small className="fw-light size-13">{ supplier.year.year}</small>,
                        updated_at : <small className="fw-light size-13">{ commitment_date }</small>,
                        action : 
                        <span className="table_action">    
                            {
                                this.handleCheck("Modifier fournisseur") == true && (
                                    <button onClick={ () => this.restaure("suppliers", supplier.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="user-check" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer fournisseur") == true && (
                                    <button onClick={ () => this.delete("suppliers", supplier.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                )
                            }
                        </span>,
                    }
                )
            })

            this.setState({
                rowsuppliers : rows
            })
        }
    }

    async getDataprofiles(){
        var data = await axios.get("profiles/operation/Supprimer");
        if(data.status === 200)
        {
            var rows = [];
            data.data.map((profile) => {
                var commitment_date = new Date(profile.updatedAt);
                commitment_date = commitment_date.getDate() + "-" + commitment_date.getMonth() + "-" + commitment_date.getFullYear();
                
                rows.push(
                    {
                        profile : profile.profile,
                        updated_at : commitment_date,
                        action : 
                        <span className="table_action">    
                            {
                                this.handleCheck("Modifier profil") == true && (
                                    <button onClick={ () => this.restaure("profiles", profile.id)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Restaurer cet enregistrement"><FeatherIcon icon="corner-up-left" size={"13"}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer profil") == true && (
                                    <button onClick={ () => this.delete("profiles", profile.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="x-octagon" size={"13"}></FeatherIcon> </button>
                                )
                            }       
                    </span>,
                    }
                )
            })

            this.setState({
                rowsprofiles : rows
            })
        }
    }

    delete = (model, data) =>{
        axios.delete(model+"/"+data).then((response) => {
            this.setState({
                rowstudents : [],
                rowstaffs : [],
                rowparents : [],
                rowsuppliers : [],
                rowsprofiles : [],
            })
            this.getDatastudents()
            this.getDataparentfamilies()
            this.getDatastaffs()
            this.getDatasuppliers()
        }).catch((error)=>{
            this.setState({
                error : error.response.data.msg
            })
        })
    }


    restaure = (model, data) =>{
        axios.put("restaure/"+ model +"/" +data).then((response) => {
            this.setState({
                rowstudents : [],
                rowstaffs : [],
                rowparents : [],
                rowsuppliers : [],
                rowsprofiles : [],
            })
            this.getDatastudents()
            this.getDataparentfamilies()
            this.getDatastaffs()
            this.getDatasuppliers()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | trash</title>
                </Helmet>
                <div className="row">
                    <div className="col-md-12">
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h5 className="h5 font-monospace">Corbeille élèves</h5>
                                    <div className="text-center text-danger">{ this.state.error }</div>
                                </div>
                                {
                                    this.state.rowstudents.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowstudents} 
                                            columns={this.state.columns} 
                                            title={"Corbeille élèves"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-4">
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h5 className="h5 font-monospace">Corbeille profils</h5>
                                </div>
                                {
                                    this.state.rowsprofiles.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowsprofiles} 
                                            columns={[
                                                {
                                                    label: 'Profil',
                                                    field: 'profile',
                                                    sort: 'asc',
                                                    width: 150
                                                },
                                                {
                                                    label: 'Date',
                                                    field: 'updated_at',
                                                    sort: 'asc',
                                                    width: 120
                                                },
                                                {
                                                    label: '#',
                                                    field: 'action',
                                                    sort: 'asc',
                                                    width: 120
                                                }
                                            ]} 
                                            title={"Corbeille profil"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-8">
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h5 className="h5 font-monospace">Corbeille parents</h5>
                                </div>
                                
                                {
                                    this.state.rowparents.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowparents} 
                                            columns={this.state.columns} 
                                            title={"Corbeille parents"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h5 className="h5 font-monospace">Corbeille personnel</h5>
                                </div>
                                {
                                    this.state.rowstaffs.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowstaffs} 
                                            columns={this.state.columns} 
                                            title={"Corbeille personnel"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>

                    <div className="col-md-6">
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="align-items-center pt-3 pb-2 border-bottom mb-2">
                                    <h5 className="h5 font-monospace">Corbeille fournisseurs</h5>
                                </div>
                                {
                                    this.state.rowsuppliers.length > 0 && (
                                        <Listprint 
                                            rows={this.state.rowsuppliers} 
                                            columns={this.state.columns} 
                                            title={"Corbeile fournisseurs"} 
                                        />
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <Section />
                
            </div>
        )
    }
}

export default Trash;