import axios from "axios";
import React, {Component} from "react";
import LeaveForm from "./LeaveForm";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class   Leave extends Component{

    state = {
        isModal : false,
        loading : true,
        leaves : [],
        columns : [
            {
                label: 'Matricule',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Personnel',
                field: 'staff',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Raison',
                field: 'reason',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Début',
                field: 'date_start',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Fin',
                field: 'date_end',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Action',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getLeaves();
    }

    async getLeaves(){
        
        this.setState({
            leaves : [],
        })

        var leaves = await axios.get('leaves');
        if(leaves.status === 200)
        {
            this.setState({
                leaves : leaves.data,
                loading : false
            })
        }
    }

    showModal = (leave = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : leave,
        })
        this.getLeaves()
    }

    delete = (leave) =>{
        axios.delete("leaves/"+leave).then((response) => {
            this.getLeaves()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        
        var rows  = [];
        this.state.leaves.map((leave) => {
            var family_name = leave.staff ? leave.staff .user.family_name ? leave.staff.user.family_name : "" : "";
            var first_name = leave.staff ? leave.staff .user.first_name ? leave.staff.user.first_name : "" : "";
            var date_start =  new Date(leave.date_start);
            date_start = date_start.getDate() + "-" + (date_start.getMonth() + 1) + "-" + date_start.getFullYear();
            var date_end = new Date(leave.date_end);
            date_end = date_end.getDate() + "-" + (date_end.getMonth() + 1) + "-" + date_end.getFullYear();
            
            rows.push({
                matricule : leave.staff && (<Link to={'/staffs/'+ leave.staff.id}  className="text-decoration-none">{leave.staff.matricule} <img src={leave.staff && (leave.staff.user.avatar)} className="rounded img-fluid" width={leave.staff && (leave.staff.user.avatar && ("30"))} /></Link>),
                staff : leave.staff && (leave.staff.user.last_name + " " + family_name + " " + first_name),
                reason : leave.reason,
                date_start : date_start,
                date_end : date_end,
                action : <span className="table_action"> 
                            {
                                this.handleCheck("Modifier congé") == true && (   
                                    <button onClick={ () => this.showModal(leave)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={"13"}></FeatherIcon> </button>
                                    )
                            }
                            {
                                this.handleCheck("Supprimer congé") == true && (
                                    <button onClick={ () => this.delete(leave.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"13"}></FeatherIcon> </button>
                                )
                            }       
                            </span>,

            })
        })
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | suppliers</title>
                </Helmet>
            <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste des congés</h5>
                {
                    this.handleCheck("Ajouter congé") == true && (
                        <div className="btn-toolbar mb-2 mb-md-0">
                            <div className="btn-group me-2">
                                <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus"></FeatherIcon> Nouveau congé</button>
                            </div>
                        </div>
                    )
                }
            </div>

            <div className="overflow-hidden">
                {
                    rows.length > 0 && (
                        <Listprint columns={this.state.columns} rows={ rows} title={"Liste des congés"}/>
                    )
                }
            </div>

            <LeaveForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
             

        </div>
        )
    }
}
export default Leave;