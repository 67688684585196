import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   ParentfamilyForm extends Component{

    state = {
        id : "",
        user : "",
        matricule : null,
        first_name : "",
        last_name : "",
        family_name : "",
        genre : "Maculin",
        profession : "",
        email : "",
        tel : null,
        identity_number : null,
        title : "",
        password : "",
        yearId : null,
        userId : "",
        years : [],
        profileId : "",
        responsible : "",
        responsibles : [],
        profiles : [],
        error : "",
    }

    handleInput = (e) => {
        this.setState({
            userId : null,
            [e.target.name]: e.target.value
        }); 
    }

    storeParentfamily = () =>{
        var data = {
            profileId : this.state.profileId ,
            userId : this.state.userId,
            matricule : this.state.matricule ? this.state.matricule : null,
            first_name : this.state.first_name,
            adress : this.state.adress,
            last_name : this.state.last_name,
            family_name : this.state.family_name,
            genre :  this.state.genre,
            profession : this.state.profession,
            email : this.state.email ? this.state.email : null,
            tel : this.state.tel ? this.state.tel : null,
            identity_number : this.state.identity_number ? this.state.identity_number : null,
            operation : this.state.id ? "Editer" : "Ajouter",
            yearId : this.state.yearId > 0 ? this.state.yearId : null,
            password :  "123"
        }

        var request = this.state.id ? axios.put('parentfamilies/'+this.state.id, data) : axios.post('parentfamilies', data);
        request.then((response) =>{
            this.props.showModal()
            this.form()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async profiles(){
        var request = await axios.get('profiles');
        if(request.status === 200)
            this.setState({profiles : request.data})
            request.data.map((profile) => {
                if(profile.model == "Parentfamily")
                    this.setState({ profileId : profile.id})
            });
    }

    async responsibles(){
        var request = await axios.get('staffs');
        if(request.status === 200)
            this.setState({responsibles : request.data})
    }

    async getYear(){
        var data=[], years = await axios.get('years');
        
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    edit=()=>  {
        this.profiles()
        this.form()
        this.getYear();
        this.responsibles()
    }

    form=()=>{
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            matricule : this.props.formData ? this.props.formData.user ? this.props.formData.user.matricule : "" : "",
            adress : this.props.formData ? this.props.formData.user ? this.props.formData.user.adress : "" : "",
            first_name : this.props.formData ? this.props.formData.user ? this.props.formData.user.first_name : "" : "",
            last_name : this.props.formData ? this.props.formData.user ? this.props.formData.user.last_name : "" : "",
            family_name : this.props.formData ? this.props.formData.user ? this.props.formData.user.family_name : "" : "",
            genre : this.props.formData ? this.props.formData.user ? this.props.formData.user.genre : "Maculin" : "",
            profession : this.props.formData ? this.props.formData.user ? this.props.formData.user.profession : "" : "",
            email : this.props.formData ? this.props.formData.user ? this.props.formData.user.email : "" : "",
            tel : this.props.formData ? this.props.formData.user ? this.props.formData.user.tel : "" : "",
            identity_number : this.props.formData ? this.props.formData.user ? this.props.formData.user.identity_number : "" : "",
            datebirth : this.props.formData ? this.props.formData.user ? this.props.formData.user.datebirth : "" : "",
            yearId : this.props.formData  ? this.props.formData.user ? this.props.formData.user.yearId : null : null,
            title : this.props.formData ? "Informations du parent" : "Ajout d'un parent",
            error : "",
        })
    }

    render(){
        var optionresponsibles = [];
        var optionprofiles = [];

        this.state.responsibles.map((responsible) => {
            optionresponsibles.push({value : responsible, label : responsible.user.last_name + "  "+ responsible.user.first_name +" "+responsible.user.tel})
        });
        
        this.state.profiles.map((profile) => {
            if(profile.model == "Parentfamily")
                optionprofiles.push({value : profile.id, label : profile.profile })
        });

        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <div className="form-group col-md-12 mt-2">
                            <label className="font-monospace fw-normal">Parmis le Personnel</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionresponsibles.filter(option => 
                                        option.value == this.state.responsible)
                                    }
                                    defaultValue={this.state.responsible}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                responsible : select.value,
                                                userId : select.value.user.id,
                                                last_name : select.value.user.last_name,
                                                first_name : select.value.user.first_name,
                                                family_name : select.value.user.family_name,
                                                tel : select.value.user.tel,
                                                email : select.value.user.email,
                                                genre : select.value.user.genre,
                                                identity_number : select.value.user.identity_number,
                                                yearId : select.value.user.yearId,
                                            });
                                        }
                                    }
                                    options={optionresponsibles}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="nom-id-icon">Nom </label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="last_name"  value={this.state.last_name} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="prenom-id-icon">Prénom </label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="first_name"  value={this.state.first_name} id="prenom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="postnom-id-icon">Post-nom </label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="family_name"  value={this.state.family_name} id="postnom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="email-id-icon">Email </label>
                                <div className="position-relative mt-1">
                                    <input type="email" className="form-control form-control-sm"  onChange={this.handleInput} name="email"  value={this.state.email} id="email-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="mail"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">N° d'identité</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm"  onChange={this.handleInput} name="identity_number"  value={this.state.identity_number} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">N° de téléphone</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="tel"  value={this.state.tel} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="phone-call"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">Adresse</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="adress"  value={this.state.adress} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="flag"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 col-6 mt-2">
                            <div className="form-group has-icon-left">
                                <label htmlFor="numero-id-icon">Profession</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="profession"  value={this.state.profession} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="box"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Année scolaire</label>
                            <div className="input-group p-0 m-0">
                                <Select
                                    className="form-control form-control-sm p-0 m-0"
                                    value = {
                                        this.state.years.filter(option => 
                                        option.value == this.state.yearId)
                                    }
                                    defaultValue={this.state.yearId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                yearId : select.value
                                            });
                                        }
                                    }
                                    options={this.state.years}
                                />
                            </div>
                        </div>

                        <div className="form-group col-6 mt-2 d-none">
                            <label className="font-monospace fw-normal">Profile</label>
                            <div className="input-group">
                                <Select
                                    className="form-control form-control-sm p-0"
                                    value = {
                                        optionprofiles.filter(option => 
                                        option.value == this.state.profileId)
                                    }
                                    defaultValue={this.state.profileId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                profileId : select.value,
                                            });
                                        }
                                    }
                                    options={optionprofiles}
                                />
                            </div>
                        </div>
                        
                        <div className=" col-6 mt-2">
                            <label className="font-monospace fw-normal">Sexe</label>
                            <div className="input-group pt-2">
                                <input type={"radio"} name="genre" value={"Masculin"} checked={this.state.genre === "Masculin" ? true : false} onChange={this.handleInput} id="masculin" className="rounded m-2" /> <label htmlFor="masculin">Masculin</label>
                                <input type={"radio"} name="genre" value={"Feminin"} checked={this.state.genre == "Feminin" ? true : false} onChange={this.handleInput} id="feminin" className="rounded m-2" /> <label htmlFor="feminin">Féminin</label>
                            </div>
                        </div>
                        <hr />
                        
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeParentfamily()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
        </div>
        )
    }
}
export default ParentfamilyForm;