import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';

class Footer extends Component{

    render(){
        return(
        
          <div className="text-center fixed-bottom bg-white pb-2">
              <p className="mb-0 small">&copy; <span className="fw-bolder me-3"> 2022 { process.env.REACT_APP_NAME }</span>  
              Made <FeatherIcon icon={"heart"} className="text-danger"></FeatherIcon> by <a className="text-decoration-none ms-1" href="http://evivedigital.net" target="_blank">EviveDigital</a></p>
          </div>
   
        )
    }
}
export default Footer;