import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import Listprint from "../../Listprint";
import StudentLine from "../students/StudentLine";

class   Evaluation extends Component{

    state = { 
        columns : [
            {
                label: 'Cours',
                field: 'lesson',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Pourcentage',
                field: 'percentage',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Année',
                field: 'scolary_year',
                sort: 'asc',
                width: 50
            },
        ],
        schedules : [],
        labelevaluations : [],
        dataevaluations : [],

        rowsEvaluations : [],
        
        evaluations : [],
    }

    componentDidMount(){
        this.getSchedules();
    }
    
    async getSchedules(){
        var schedules = await axios.get('schedules/staff/' + this.props.staff);
        if(schedules.status === 200)
        {
            this.setState({schedules : schedules.data}) 
        }
    }


    render(){
        var scheduleEvaluation = "", labelevaluations = [], dataevaluations = [], evaluations = [], lessons = [], count = 0,
  
        scheduleEvaluation =
        this.state.schedules.filter(data => this.props.periodes.filter(val => val == data.periode.id).length > 0 ).map((schedule) => {

            
            evaluations = [];
            var noteS = 0, max = 0, lesson="";

            var id = schedule.sectionclasselesson.sectionclass.id;

            count = lessons.filter(data => data == id).length;
            
            if(count == 0) { 
                lessons.push(id);

                this.state.schedules.filter(data => data.sectionclasselesson.sectionclass.id == id && this.props.periodes.filter(val => val == data.periode.id).length > 0 ).map(schedule_val => {
                    
                    schedule_val.evaluations.map((evaluation) => {
                        max = parseFloat(evaluation.max)  * parseFloat(evaluation.notes.length);
                        evaluation.notes.map((note) => {
                            noteS = noteS + note.note
                        })
                    })
    
                    lesson = schedule_val.sectionclasselesson.lesson.entitled_lesson;
                                                        
                    if(evaluations.length > 0)
                    {
                        if(evaluations.filter(row => row.lesson == lesson).length == 0)
                        {
                            evaluations.push({
                                lesson : lesson,
                                scolary_year : schedule_val.year.year,
                                percentage : noteS,
                                max : max,
                            })
                        }
                        else{
                            
                            evaluations.map((row, index) => {
                                var summe = noteS + parseFloat(evaluations[index].percentage);
                                var summeMax = max + parseFloat(evaluations[index].max);
                                if(row.lesson == lesson)
                                {
                                    evaluations[index].percentage = summe
                                    evaluations[index].max = summeMax
                                }
                            })
                        }
                        noteS = 0;
                    }
                    else{
                        evaluations.push({
                            lesson : lesson,
                            scolary_year : schedule_val.year.year,
                            percentage : noteS,
                            max : max
                        })
                        noteS = 0;
                    }
                })
        
                    evaluations.map((row, val) => {
                                            
                        if(val < evaluations.length)
                        {
                            var sum = parseFloat(evaluations[val].max)
                            var div = parseFloat(evaluations[val].percentage)  * 100;
                            var summR = div / sum;
                            
                            evaluations[val].percentage =  summR.toFixed(1) > 0 ? summR.toFixed(1) + "%" : ""
                            labelevaluations.push(evaluations[val].lesson)
                            dataevaluations.push(summR)
                            
                        }
                    })

                return(
                    <div className="widget-todo-item list-group-item m-2 mb-4 border rounded-3" key={schedule.id}>
                        <div
                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                            <div className="widget-todo-title-area d-flex align-items-center">
                                <FeatherIcon icon="box" className='cursor-move'></FeatherIcon>
                                <label htmlFor={schedule.id}><span className="widget-todo-title ml-50">{schedule.sectionclasselesson.sectionclass.class.short_entitled_class} {schedule.sectionclasselesson.sectionclass.class.entitled_class} <span className="text-primary">{schedule.sectionclasselesson.sectionclass.title}</span> { schedule.sectionclasselesson.sectionclass.section.short_entitled_section} </span></label>
                            </div>
                            <div className="widget-todo-item-action d-flex align-items-center">
                                {schedule.sectionclasselesson.sectionclass.section.short_entitled_section} <FeatherIcon icon="list" size="16" className='cursor-move text-dark'></FeatherIcon>
                            </div>
                        </div>
                        
                            {
                                evaluations.length > 0 && (
                                    <Listprint columns={this.state.columns} rows={evaluations} name={this.props.name} matricule={this.props.matricule} classe={schedule.sectionclasselesson.sectionclass.class.short_entitled_class + " "+ schedule.title} title={ this.props.category + " " + schedule.sectionclasselesson.sectionclass.section.short_entitled_section}/> 
                                )
                            }
                    </div>
                )
            }
        })

        return(
            <div>

                <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                    <div className="card-body ">
                        <div className="fw-bolder text-center font-monospace">{ this.props.category }</div>
                        { scheduleEvaluation }
                    </div>
                </div>

                <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                    <div className="card-body ">
                        <div className="fw-bolder text-center font-monospace">Statistic { this.props.category } </div>
                        {
                            labelevaluations.length > 0 && (
                                <StudentLine 
                                    data={dataevaluations}  
                                    labels={labelevaluations}
                                    title={"Statistique " + this.props.category }
                                    matricule ={this.props.matricule} 
                                    name={this.props.name}
                                 />)
                            }
                    </div>
                </div>
             
        </div>
        )
    }
}
export default Evaluation;