import axios from "axios";
import React, {Component} from "react";
import Listprint from "../../Listprint";
import StudentLine from "../students/StudentLine";

class Expense extends Component{

    state = {
        expenses: [],
        columns : [
            
            {
                label: 'Montant',
                field: 'mount',
                sort: 'asc',
                width: 50,
                align : "rigth"
            },
            {
                label: 'Type',
                field: 'type',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Mois',
                field: 'month',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Année',
                field: 'year',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Opérateur',
                field: 'staff',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 50
            },
        ],
        rows : [],
        data : [],
    }

    componentDidMount(){
        this.geExpenses()
    }

    async geExpenses(){
        var  rows = [], data = [], note = 0, month = 0;
        var expenses = await axios.get('expenses/staff/' + this.props.staff);

        if(expenses.status === 200)
        {
            var index = 0;
            expenses.data.map((expense) =>{

                if(month > 0 && month != expense.month.id)
                {
                    var value = 0;
                    expense.expensedetails.map((expensedetail) =>{
                        value += expensedetail.amount
                    })
                    

                    data.push(note)
                    note = value;
                    month = expense.month.id;
                }
                else{
                    var val = 0;
                    expense.expensedetails.map((expensedetail) =>{
                        val = expensedetail.amount
                    })
                    
                    if(val > 0)
                        note = parseInt(note) + parseInt(val);
                    
                    month = expense.month.id
                }

                if(expenses.data.length - 1 === index)
                {
                    data.push(note)
                }
                
                expense.expensedetails.map((detail) => {
                    
                    rows.push({
                        // profile : expense.profile ? <span className="px-1 rounded-3 border border-1 border-primary size-13">{  expense.profile.profile } </span> : "",
                        mount : detail.amount + " " + detail.chartaccount.currency,
                        type : detail.typesalary ? detail.typesalary.type : detail.reason,
                        month : expense.month.month,
                        year : expense.year.year,
                        staff : <span>
                                    <img src={detail.staff && (detail.staff.user.avatar)} className="rounded img-fluid" width={detail.staff && (detail.staff.user.avatar && ("30"))} />
                                    {detail.staff && (detail.staff.user.last_name +" "+ detail.staff.user.first_name)}
                                </span>,
                        created_at : detail.createdAt.slice(0, 10)
                    })
                })
                index += 1
            })

            this.setState({
                data : data,
                rows : rows
            })
        }
    }

    render(){
        return(
            <div>
                <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                    <div className="card-body ">
                        <div className="fw-bolder text-center font-monospace">{this.props.title}</div>
                        {
                            this.state.rows.length > 0 && (
                                <Listprint 
                                    columns={this.state.columns}
                                    rows={this.state.rows}
                                    name={this.props.name}
                                    matricule={this.props.matricule}
                                    title={this.props.title}
                                />
                            )
                        }
                    </div>
                </div>

                <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                    <div className="card-body ">
                        <div className="fw-bolder text-center font-monospace">Statistique {this.props.title}</div>
                        {
                            this.state.data.length > 0 && (    
                                <StudentLine
                                    data={this.state.data}
                                    name={this.props.name}
                                    matricule={this.props.matricule}
                                    title={this.props.title} 
                                />
                            )
                        }
                        
                    </div>
                </div>
            </div>
        )
    }
}
export default Expense