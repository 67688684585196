import axios from "axios";
import React, {Component} from "react";
import { Helmet } from 'react-helmet';
import FeatherIcon from 'feather-icons-react';
import Year from "../years/Year";
import Modal from "react-bootstrap/Modal";
import Resizer from "react-image-file-resizer";
import { toast } from "react-toastify";

class Establishment extends Component{

    state = {
        establishment : {},
        image : [],
        isModal : false,
        dataImage : null,
        btn : false,
        name : "",
        short_name : "",
        adresse : "",
        postbox : "",
        tel : "",
        email : "",
        link : "",
        slogan : "",
        type : "",
        identifiant_agree : "",
        logo : "",
        error : ""
    }

    componentDidMount(){
        this.getEstablishment()
    }
    async getEstablishment(){
        var profile = JSON.parse(localStorage.getItem('profile'));
        var id = profile.profile.establishment.id
        var establishment = await axios.get('establishments/' + id)
        
        if(establishment.status === 200)
        {
            this.setState({
                establishment : establishment.data,
                name : establishment.data.name,
                short_name : establishment.data.short_name,
                adresse : establishment.data.adresse,
                postbox : establishment.data.postbox,
                tel : establishment.data.tel,
                email : establishment.data.email,
                link : establishment.data.link,
                slogan : establishment.data.slogan,
                type : establishment.data.type,
                identifiant_agree : establishment.data.identifiant_agree,
                logo : establishment.data.logo,
            })
            localStorage.setItem("establishment", JSON.stringify(establishment.data[0]));
        }
    }
    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
        if(!this.state.isModal)
        this.getEstablishment()
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeEstablishment = async () =>{

        this.setState({
            errorImg : "",
            loading : true,
            btn : !this.state.btn
        })

        let formData = new FormData()
        formData.append('files', this.state.dataImage)
        formData.append('name', this.state.name)
        formData.append('short_name', this.state.short_name)
        formData.append('adresse', this.state.adresse)
        formData.append('city', this.state.city)
        formData.append('region', this.state.region)
        formData.append('postbox', this.state.postbox)
        formData.append('tel', this.state.tel)
        formData.append('email', this.state.email)
        formData.append('link', this.state.link)
        formData.append('slogan', this.state.slogan)
        formData.append('type', this.state.type)
        formData.append('identifiant_agree', this.state.identifiant_agree);
        
        var user = localStorage.getItem("user") ?  JSON.parse(localStorage.getItem("user")) : {};
        const response = await fetch(process.env.REACT_APP_APIEVIVESOMA +'/establishments/' + this.state.establishment.id, {
            method: "PUT",
            headers : { "x-access-token" : user.refresh_token ? user.refresh_token : "" },
            body: formData 
        })
        
        if (response.status === 200)
        {
            this.setState({
                loading : false,
                btn : !this.state.btn
            })
            toast('Opération avec succes', { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.showModal()
        }
        else{
            this.setState({
                loading : false,
                error : response.data.msg,
                btn : !this.state.btn
            })
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }
         
    }

    handleInputFile = (e) => {

        this.setState({
            image : [],
            btn : false,
            dataImage : e.target.files[0],
        })
        for(var i = 0 ; i < e.target.files.length; i++)
        {
            this.setState({
                loading : true
            })
            var img = "";
            try {
            img =  Resizer.imageFileResizer(
                  e.target.files[i],
                  5000,
                  5000,
                  "JPEG",
                  80,
                  0,
                  (uri) => {
                        this.state.image.push(uri);
                    this.setState({ image: this.state.image });
                  },
                  "base64",
                  700,
                  700
                );
            } catch (err) {
                console.log(err);
            }
        }
        this.setState({
            loading : false,
            loadingForm : false
        })
    }


  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | establishment data</title>
                </Helmet>

                <div>
                    <div className="d-flex align-items-center overflow-hidden">
                        <div className="flex-shrink-0">
                            {
                                this.state.establishment.logo && (
                                    <img src={this.state.establishment.logo} alt=""  className="rounded img-fluid" style={{ width:100 + "px" }} />
                                )
                            }
                            {
                                !this.state.establishment.logo && (
                                    <FeatherIcon icon={"home"} size="80" className={"text-dark"}></FeatherIcon>
                                )
                            }
                            
                        </div>
                        <div className="ms-1">
                            <span className="text-primary h2 m-0 font-monospace">
                                { this.state.establishment.name }
                                <span className="text-dark"> { this.state.establishment.short_name }</span> 
                            </span>
                            <div>
                                <FeatherIcon icon={"map-pin"} size="16" className={"text-warning ms-2"}></FeatherIcon>
                                <span className="text-warning">{ this.state.establishment.adresse}</span>
                                <FeatherIcon icon={"phone"} size="16" className={"text-primary ms-2"}></FeatherIcon>
                                <span className="text-primary">{ this.state.establishment.tel}</span><br />
                                <FeatherIcon icon={"mail"} size="16" className={"text-info ms-2"}></FeatherIcon>
                                <span className="text-info">{ this.state.establishment.email}</span>
                                <FeatherIcon icon={"smartphone"} size="16" className={"text-danger ms-2"}></FeatherIcon>
                                <span className="text-danger">{ this.state.establishment.postbox}</span>
                            </div>
                        </div>
                        <div className="text-end"><FeatherIcon icon={"edit-2"} size="16" type="button" onClick={() => this.showModal()} className={"text-primary ms-2"}></FeatherIcon>editer</div>
                    </div>
                    {
                        ( this.handleCheck("Afficher année") == true && this.state.establishment.id )&& (
                            <div className="mt-3 ">
                                <hr />
                                <Year />
                            </div>
                        )
                    }
                    
                    <Modal 
                        show={this.state.isModal} 
                        onHide={this.showModal}
                        size="lg"
                        backdrop={"static"}>
                        <Modal.Header>
                            <Modal.Title className="h6 font-monospace">Editer votre etablissement</Modal.Title>
                        </Modal.Header>
                        <Modal.Body  >
                            <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                            <div className="row">
                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Nom complet</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Abreviation</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="short_name"  value={this.state.short_name} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Ville</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="city"  value={this.state.city} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Region</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="region"  value={this.state.region} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Adresse</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="adresse"  value={this.state.adresse} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Boite postal</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="postbox"  value={this.state.postbox} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Téléphone</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="tel"  value={this.state.tel} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Email</label>
                                    <div className="position-relative mt-1">
                                        <input type="email" className="form-control" placeholder=""  onChange={this.handleInput} name="email"  value={this.state.email} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Slogan</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="slogan"  value={this.state.slogan} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Type d'etablissement</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="type"  value={this.state.type} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6 has-icon-left">
                                    <label htmlFor="nom-id-icon">Agrée sous</label>
                                    <div className="position-relative mt-1">
                                        <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="identifiant_agree"  value={this.state.identifiant_agree} id="nom-id-icon" />
                                        <div className="form-control-icon">
                                            <FeatherIcon icon="box" className="text-primary"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-group col-md-6">
                                    <label>Logo</label>
                                    {
                                        this.state.image.map((img, index) => {
                                            return(
                                                <img src={img} alt="" key={index} className="rounded img-fluid" width={"150px"} />
                                            )
                                        })
                                    }
                                    <input type={"file"} className="form-control form-control-sm" onChange={this.handleInputFile} />
                            </div>

                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <button onClick={this.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                            <button onClick={() => this.storeEstablishment()}  disabled={this.state.btn} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                        </Modal.Footer>
                    </Modal>
                </div>
            </div>
        )
    }
}
export default Establishment