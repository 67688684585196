import axios from "axios";
import React, {Component} from "react";
import StudentLine from "../students/StudentLine";

class   LessonStaff extends Component{

    state = {
        labels : [],
        data : [],
        dataHour : [],
    }

    componentDidMount(){
        this.getStaffs();
    }

    async getStaffs(){
        var staffs = await axios.get('staffs');
        var data = [], labels = [], dataHour =[];
        if(staffs.status === 200)
        { 
            staffs.data.map((staff) => { 
                var week_hour = 0;
                data.push(staff.sectionclasselessons.length)
                staff.sectionclasselessons.map(sectionclasselesson => {
                    week_hour += sectionclasselesson.week_hour > 0 ? sectionclasselesson.week_hour : 0;
                })
                dataHour.push(week_hour)
                labels.push(staff.matricule + " " + staff.user.last_name)
            })
        }
        
        this.setState({
            data : data,
            dataHour : dataHour,
            labels : labels
        })
    }

    render(){
        return(
            <div>
               {
                    this.state.labels.length > 0 && (
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="border-bottom pb-2 font-monospace">Heures dispensées par semaine</div>
                                <StudentLine 
                                    labels={this.state.labels} 
                                    data={this.state.dataHour} 
                                    matricule={this.props.matricule} 
                                    name={this.props.name} 
                                    title={"Heures dispensées par semaine"}
                                />
                            </div>
                        </div>
                    )
                }  


                {
                    this.state.labels.length > 0 && (
                        <div className="card shadow overflow-hidden mb-4">
                            <div className="card-body">
                                <div className="border-bottom pb-2 font-monospace">Statistique nombre des cours par Personnel</div>
                                <StudentLine 
                                    labels={this.state.labels} 
                                    data={this.state.data} 
                                    matricule={this.props.matricule} 
                                    name={this.props.name} 
                                    title={"Statistique nombre des cours par Personnel"}
                                />
                            </div>
                        </div>
                    )
                }  
        </div>
        )
    }
}
export default LessonStaff;