import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import Listprint from "../../Listprint";

class   StudentList extends Component{

    state = {
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Responsable',
                field: 'responsible',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Classe',
                field: 'classe',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            }
        ],
        rows : []
    }

    componentDidMount(){
        this.orderBy()
    }

    async orderBy(){
        var students = await axios.get("students/operation/"+this.props.orderBy);
        
        if(students.status === 200)
        {
            var data = [], border="";
            students.data.map((student) => {
                
                var dateFormat = new Date(student.createdAt);
                    dateFormat = dateFormat.toLocaleDateString('en-GB');

                var dateFormatU = new Date(student.updatedAt);
                dateFormatU = dateFormatU.toLocaleDateString('en-GB');

                border = student.operation === "Archiver" ? "border-success text-success" : (student.operation === "Supprimer" ? "border-danger text-danger" : "border-primary text-primary")
                data.push(
                    {
                        matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none">{student.matricule}</Link>),
                        student : student.user && (student.user.last_name +" "+ student.user.family_name +" "+ student.user.first_name),
                        responsible : student.user && (student.user.user && (student.user.user.first_name + " " +student.user.user.last_name)),
                        classe : <small className="size-10">{ student.sectionclass.class.short_entitled_class + " " + student.sectionclass.title + " " +  student.sectionclass.section.short_entitled_section}</small>,
                        created_at : <small className="size-10">{this.props.orderBy ==="Ajouter" ? dateFormat : dateFormatU }  </small>
                    }
                )
            })
            this.setState({
                columns : this.state.columns,
                rows : data
            })
        }
    }


    render(){
        return(
        <div className='card shadow overflow-hidden mb-4'>
            <div className='card-body'>
                <div className="h6 border-bottom pb-2 font-monospace">{this.props.title}</div>
                {
                    this.state.rows.length > 0 && (<Listprint columns={this.state.columns} rows={this.state.rows} title={this.props.title}/> )
                }   
            </div>
        </div>
        )
    }
}
export default StudentList;