import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class  SectionclasseForm extends Component{

    state = {
        id : "",
        title : "",
        classeId : "",
        sectionId : "",
        reportcardcategoryId : "",
        sections : [],
        classes : [],
        error : "",
        yearId : null,
        years : [],

        isModal : false,
        reportcardcategories : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeSectionclasse = () =>{
        var data = {
                title : this.state.title,
                classeId : this.state.classeId,
                classId : this.state.classeId,
                sectionId : this.state.sectionId,
                reportcardcategoryId : this.state.reportcardcategoryId,
                operation : this.state.id ? "Editer" : "Ajouter",
                yearId : this.state.yearId > 0 ? this.state.yearId : null,
            }

        var request = this.state.id ? axios.put('sectionclasses/'+this.state.id, data) : axios.post('sectionclasses', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    
    async sections (){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({
                sections : sections.data
            })
        }
    }
    async classes (){
        var classes = await axios.get('classes');
        if(classes.status === 200)
        {
            this.setState({
                classes : classes.data
            })
        }
    }

    async reportcardcategories (){
        var reportcardcategories = await axios.get('reportcardcategories');
        if(reportcardcategories.status === 200)
        {
            this.setState({
                reportcardcategories : reportcardcategories.data
            })
        }
    }

    edit=()=>  {
        this.form()
        this.sections();
        this.classes()
        this.getYear()
        this.reportcardcategories();
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : "",
            title: this.props.formData ? this.props.formData.title : "",
            classeId: this.props.formData ? this.props.formData.class ? this.props.formData.class.id : "" : "",
            sectionId: this.props.formData ? this.props.formData.sectionId : "",
            reportcardcategoryId: this.props.formData ? this.props.formData.reportcardcategory  ? this.props.formData.reportcardcategory.id : "" : "",
            yearId : this.props.formData  ? this.props.formData.year  ? this.props.formData.year.id  : "" : "",
            error : "",
        })
    }

    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
            var optionsections = [], optionclasses = [], optionreportcards = [];
            this.state.sections.map((section) => {
                optionsections.push({ value : section.id, label : section.short_entitled_section + " "+section.entitled_section})
            })
            this.state.classes.map((classe) => {
                optionclasses.push({ value : classe.id, label : classe.short_entitled_class + " "+classe.entitled_class})
            })

            this.state.reportcardcategories.sort((a, b) => (a.id > b.id ? 1 : -1)).map((reportcardcategory) => {
                optionreportcards.push({ value : reportcardcategory.id, label : reportcardcategory.category })
            })

        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">Attacher la classe à la section</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row">
                    <span className="text-danger text-small">{ this.state.error }</span>
                    <div className="form-group col-md-12 mt-2">
                        <label className="font-weight-bolder">Section / option</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    optionsections.filter(option => 
                                    option.value == this.state.sectionId)
                                }
                                defaultValue={this.state.sectionId}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            sectionId : select.value
                                        });
                                    }
                                }
                                options={optionsections}
                            />
                        </div>
                        
                    </div>

                    <div className="form-group col-md-7 mt-2">
                        <label className="font-weight-bolder">Classe</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    optionclasses.filter(option => 
                                    option.value == this.state.classeId)
                                }
                                defaultValue={this.state.classeId}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            classeId : select.value
                                        });
                                    }
                                }
                                options={optionclasses}
                            />
                        </div>
                    </div>

                    <div className="form-group col-md-5 mt-2">
                        <label className="font-weight-bolder">Bulletin</label>
                        <div className="input-group">
                            <Select className="form-control form-control-sm p-0"
                                value = {
                                    optionreportcards.filter(option => 
                                    option.value == this.state.reportcardcategoryId)
                                }
                                defaultValue={this.state.reportcardcategoryId}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            reportcardcategoryId : select.value
                                        });
                                    }
                                }
                                options={optionreportcards}
                            />
                        </div>
                    </div>

                    <div className="col-5 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Titre</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder="A, B, C ..."  onChange={this.handleInput} name="title"  value={this.state.title} id="nom-id-icon" />
                                <div className="form-control-icon">
                                    <FeatherIcon icon="box"></FeatherIcon>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className=" col-7 mt-2">
                        <label className="font-monospace fw-normal">Année scolaire</label>
                        <div className="input-group">
                            <Select
                                className="form-control form-control-sm p-0"
                                value = {
                                    this.state.years.filter(option => 
                                    option.value == this.state.yearId)
                                }
                                defaultValue={this.state.yearId}
                                onChange={
                                    (select) => {
                                        this.setState({
                                            yearId : select.value
                                        });
                                    }
                                }
                                options={this.state.years}
                            />
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                    <button onClick={() => this.storeSectionclasse()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default SectionclasseForm;