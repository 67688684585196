import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import EstablishmentprofileData from "../../../components/rh/establishmentprofiles/EstablishmentprofileData";
import Chat from "../../users/Chat";
import StaffForm from "./StaffForm";
import Expense from "./Expense";
import Schedule from "./Schendule";
import Lesson from "./Lesson";
import Evaluation from "./Evaluation";
import Listprint from "../../Listprint";
import { toast } from "react-toastify";

class Profile extends Component{

    state = {
        staff : {
            user : {
            }
        },
        rows : [],
        columns : [],
        isModal : false,
        formData : {},
        userestablishprofile : "",
        isModalProfile : false,
    }
    
    componentDidMount(){
        this.getUser()
        this.getLeaves();
    }

    async getUser(){
        var staff = await axios.get("staffs/"+this.props.match.params.staff_id);
        if(staff.status === 200)
        {
            this.setState({
                staff : staff.data
            })
        }
    }

    async getLeaves(){
        var leaves = await axios.get("leaves/staff/" + this.props.match.params.staff_id)
        if(leaves.status == 200)
        {
            var tab = [];
            leaves.data.map(leave =>{
                var date_start =  new Date(leave.date_start);
                date_start = date_start.getDate() + "-" + (date_start.getMonth() + 1) + "-" + date_start.getFullYear();
                var date_end = new Date(leave.date_end);
                date_end = date_end.getDate() + "-" + (date_end.getMonth() + 1) + "-" + date_end.getFullYear();
                tab.push({
                    reason : leave.reason,
                    date_start : date_start,
                    date_end : date_end,
                })
            })

            this.setState({
                rows : tab,
                columns : [
                    {
                        label: 'Raison',
                        field: 'reason',
                        sort: 'asc',
                        width: 120
                    },
                    {
                        label: 'Début',
                        field: 'date_start',
                        sort: 'asc',
                        width: 150
                    },
                    {
                        label: 'Fin',
                        field: 'date_end',
                        sort: 'asc',
                        width: 120
                    },
                ]
            })
        }
    }

    showModalProfile = (userestablishprofile = null, user = null) => {
        this.setState({
            isModalProfile : !this.state.isModalProfile,
            userestablishprofile : userestablishprofile,
            user : user,
        })
    }

    showModal = (student = null, classe=false) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : student,
            classe : classe ? student : ""
        })
        
        this.getUser()
    }

    delete = (student) =>{
        axios.delete("students/"+student).then((response) => {
            this.getUser()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    
  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        var staff = this.state.staff.id;
        var matricule =this.state.staff.matricule; 
        var name= this.state.staff.user.last_name +" "+this.state.staff.user.first_name;
        return(
            
        <div className="container">
                <h5 className="h5 font-monospace font-monospace">Profil du personnel</h5>
            <div className="row">
                <div className="col-md-5">
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="d-md-flex">
                                <div className="image mr-3">
                                    <img src={this.state.staff && (this.state.staff.user.avatar)} className="rounded-circle img-fluid" width={this.state.staff && (this.state.staff.user.avatar && ("100"))} /> 
                                </div>
                                <div className="mt-4">
                                    <div className="fw-bolder font-monospace">
                                        {this.state.staff && (this.state.staff.matricule)} # { this.state.staff && (this.state.staff.user.identity_number)} <br />
                                        {this.state.staff && (this.state.staff.user.last_name + " "+ this.state.staff.user.first_name)} 
                                    </div>
                                    
                                    <div className=" m-1">

                                    {
                                        this.state.staff && (
                                            this.state.staff.user.userprofiles &&(
                                                this.state.staff.user.userprofiles.map((userprofile) =>{
                                                    if(userprofile.status == 1)
                                                    {
                                                        return(
                                                            <span key={userprofile.profile.id} className="badge bg-success small rounded-3 mx-1" type="button" onClick={() => this.showModalProfile(userprofile, this.state.staff.user)}>{userprofile.profile.profile}, </span>
                                                        )
                                                    }
                                                })
                                            )
                                        )
                                    }
                                    </div>
                                </div>
                            </div>
                            
                            <div className="">
                                <div className="fw-bolder text-center font-monospace">Apropos du personnel</div><hr />
                                <div className="">
                                    <div>
                                        <a href={"tel:"+this.state.staff && (this.state.staff.user.tel)} className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon>{this.state.staff && (this.state.staff.user.tel)}</a> </div>
                                    <div>
                                        <a href={"mailto:"+this.state.staff && (this.state.staff.user.email)} className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.state.staff && (this.state.staff.user.email)}</a> </div>
                                
                                    <div>
                                        <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.state.staff && (this.state.staff.user.adress)}bavi
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-end me-2 d-end mt-2">
                            {
                                this.handleCheck("Modifier personnel") == true && (
                                    <button onClick={ () => this.showModal(this.state.staff)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="user-plus" className={"d-inline"} size="13"></FeatherIcon><span className="d-inline">Editer</span></button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer personnel") == true && (
                                    <button onClick={ () => this.delete(this.state.staff.user.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" className={"d-inline"} size="13"></FeatherIcon><span className="d-inline">Supprimer</span></button>
                                )
                            }
                            </div>
                    </div>

                    {/* cours */}
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Cours</div>
                            {
                                staff && (
                                   <Lesson 
                                        staff={staff}
                                        matricule ={matricule} 
                                        name={name}
                                        title="Cours" 
                                    />
                                )
                            }
                        
                        </div>
                    </div>

                    {/* congés */}
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Congés</div>
                            {
                                this.state.rows.length > 0 && (
                                   <Listprint 
                                        columns={this.state.columns} 
                                        rows={this.state.rows} 
                                        name={name} 
                                        matricule={matricule} 
                                        title={ "Congé"}
                                     />
                                )
                            }
                        
                        </div>
                    </div>
                    

                    {/* staff */}
                    {
                        staff && (
                    <div>
            
                        <Expense 
                            staff={staff}
                            title={"Globalité des paiements mensuels"}
                            matricule ={matricule} 
                            name={name} 
                        />

                        <div className="col-md-12">
                            <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                                <div className="card-body ">
                                    <div className="fw-bolder text-center font-monospace">Notifier le Personnel</div>
                                    { this.state.staff && ( <Chat userRecever={this.state.staff.user} /> ) }
                                </div>
                            </div>
                        </div>
                    </div>
                    )
                }
                    
                </div>

                <div className="col-md-7">

                    {/* Horaire */}
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Horaire</div>
                            {
                                staff && (
                                    <Schedule 
                                        staff={staff}
                                        title={"Horaire"}
                                        matricule ={matricule} 
                                        name={name}
                                    />
                                )
                            }
                        </div>
                    </div>

                    {/* evaluation */}
                    
                    {
                        staff && (
                            <Evaluation
                                staff={staff}
                                category={"Evaluation"}
                                matricule ={matricule} 
                                name={name}
                                periodes={[1,2,4,5]}
                            />
                        )
                    }

                    {
                        staff && (
                            <Evaluation
                                staff={staff}
                                category={"Examen"}
                                matricule ={matricule} 
                                name={name}
                                periodes={[3, 6]}
                            />
                        )
                    }

                    {
                        staff && (
                            <Evaluation
                                staff={staff}
                                category={"Deuxieme session"}
                                matricule ={matricule} 
                                name={name}
                                periodes={[7]}
                            />
                        )
                    }
                </div>

            </div>

            <StaffForm
                isModal={this.state.isModal} 
                formData={this.state.formData}
                classe={this.state.classe}
                showModal={this.showModal}
            />

            <EstablishmentprofileData
                isModal={this.state.isModalProfile} 
                formData={this.state.userestablishprofile}
                fromUser = {this.state.user}
                showModal={this.showModalProfile}
             />

        </div>
        )
    }
}
export default  Profile