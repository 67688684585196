import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import Select from 'react-select';
import { toast } from "react-toastify";

class   EstabsectschoolfeecatstudcategoryForm extends Component{

    state = {
        estabsectschoolfeecatstudentcat_id : "",
        errors : {},
        isModal : false,
        estsectschoolcatstudcats : [],
    }


    store = (val = null) =>{
        
        var data = {
                estabsectschoolfeecatstudentcat_id : this.state.estabsectschoolfeecatstudentcat_id,
                student_id : this.props.formData.id,
            }

        var request = axios.post('estabsectschcatstudcaties', data);
        request.then((response) =>{
            this.estsectschoolcatstudcats()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                errors : error.response.data.errors
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }
    

    edit=()=>  {
        this.form()
        this.estsectschoolcatstudcats()
    }

    form=()=>{
        
        this.setState({
            title :"Attacher une categorie de paiement à l'élève " + this.props.formData.user.last_name,
            errors : {},
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    async estsectschoolcatstudcats () {
        var classe = this.props.formData ? this.props.formData.class ? this.props.formData.class.id : this.props.classe : this.props.classe;
        var estsectschoolcatstudcats = await axios.get('estsectschoolcatstudcats/estblsectclass/'+classe);
        this.setState({
            estsectschoolcatstudcats : []
        })
        if(estsectschoolcatstudcats.status == 200)
        {
            this.setState({
                estsectschoolcatstudcats : estsectschoolcatstudcats.data
            })
        }
    }

    deleteData = (estabsectschcatstudcat) =>{
        axios.delete("estabsectschcatstudcaties/"+estabsectschcatstudcat).then((response) => {
            this.props.showModal()
        }).catch((error)=>{})
    }

    render(){
        
        var optioncategorystudents = [] ; 
        this.state.estsectschoolcatstudcats.map((estsectschoolcatstudcat) => {
            if(this.props.formData.categorystudent.id == estsectschoolcatstudcat.categorystudent.id)
            {
                optioncategorystudents.push({
                    value : estsectschoolcatstudcat.id,
                    label : estsectschoolcatstudcat.price + "" + estsectschoolcatstudcat.establishmentsectionschoolfeecategory.currency + " "+ estsectschoolcatstudcat.categorystudent.category_student + " " + estsectschoolcatstudcat.establishmentsectionschoolfeecategory.schoolfeecategory.category
                })
            }
        })
        
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="">

                    <div className="table-responsive">
                        <table className="table table-striped table-sm">
                            <thead>
                                <tr>
                                    <td>Catégorie</td>
                                    <td>Prix</td>
                                    <td>Paiement</td>
                                    <td></td>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                this.props.formData.estabsectschoolfeecatstudcategories.map((data) =>{
                                    return(
                                        <tr>
                                            <td>{ data.estabsectschoolfeecatstudentcat.categorystudent.category_student }</td>
                                            <td> { data.estabsectschoolfeecatstudentcat.price + "" + data.estabsectschoolfeecatstudentcat.establishmentsectionschoolfeecategory.currency }</td>
                                            <td>{ data.estabsectschoolfeecatstudentcat.establishmentsectionschoolfeecategory.schoolfeecategory.category } </td>
                                            <td>
                                                <button onClick={ () => this.deleteData(data.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={16}></FeatherIcon> </button>
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            </tbody>
                        </table>
                    </div>
                    <div className="form-group col-md-12 mt-2">
                        <label className="font-monospace fw-normal">Catégorie</label>
                        <Select
                            closeMenuOnSelect={false}
                                    
                            defaultValue={[ ]}
                            isMulti
                            options={optioncategorystudents}
                            onChange={
                                (select) => {
                                    this.setState({
                                        estabsectschoolfeecatstudentcat_id : select
                                    });
                                }
                            }
                            className="form-control"
                        />
                        <span className="text-danger text-small small">{ this.state.errors.categorystudent_id }</span>
                    </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.store()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default EstabsectschoolfeecatstudcategoryForm;