import React, {Component} from "react";
import ReactToPrint from "react-to-print";
import { MDBDataTable } from 'mdbreact';
import FeatherIcon from 'feather-icons-react';
import UserIdentity from "./users/UserIdentity";
import Headerpdf from "./users/Headerpdf";

class Listprint extends Component {
    state = {
        columns : [],
        rows : [],
    }

    componentDidMount(){
        this.setState({
            columns : this.props.columns,
            rows : this.props.rows,
        })
        
    }
    
    render(){
        return(
            <div id="containerpdf">
                <div className="text-end mb-2 mb-md-0">
                    <div className="btn-group me-2">
                        <ReactToPrint
                            trigger={() => <button className="btn btn-sm btn-outline-primary" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                            content={() =>  this.componentRef}
                        />
                    </div>
                </div>
                <div className="row" ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    <UserIdentity matricule ={this.props.matricule} name={this.props.name} classe={this.props.classe} title={this.props.title} /> 
                    
                    <div className="row">
                        <MDBDataTable
                            className=" "
                            paging={true}
                            striped
                            bordered
                            small
                            data={this.state}
                        />
                    </div>
                   

                </div>
            </div>
        )
    }
}
export default Listprint;