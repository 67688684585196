import React, {Component} from "react";
import { Bar } from 'react-chartjs-2';
import ReactToPrint from "react-to-print";
import FeatherIcon from 'feather-icons-react';
import UserIdentity from "../../users/UserIdentity";
import { Chart, registerables } from 'chart.js'
import Headerpdf from "../../users/Headerpdf";
Chart.register(...registerables)

class StudentBar extends Component{

    state = {
        labels: ['Janvier', 'Fevrier', 'Mars', 'Avril', 'Mai', 'Juin', 'juillet', 'Aout', 'Septembre', 'Octobre', 'Novembre', 'Decembre'],
        datasets: [
          {
            label: 'Rainfall',
            backgroundColor: '#045fc0',
            borderColor: 'rgba(0,0,0,1)',
            borderWidth: 2,
            data: [47, 35, 66, 52, 33, 44, 20, 66, 12, 22, 44, 59]
          }
        ]
      }

      componentDidMount(){
        if(this.props.data)
        {
          this.setState({
              data : this.props.data,
              datasets: [
                {
                  label: 'Données',
                  fill: false,
                  lineTension: 0.5,
                  backgroundColor: 'rgb(0, 108, 189)',
                  borderColor: 'rgba(0,0,0,1)',
                  borderWidth: 2,
                  data: this.props.data
                }
              ]
          })
        }

        if(this.props.labels)
        {
          this.setState({
            labels : this.props.labels
          })
        }
      }

    render(){
        return(
            <div>
                <div className="text-end">
                    <ReactToPrint
                        trigger={() => <button className="btn btn-sm btn-outline-primary rounded rounded-3" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <small className="small"> Imprimer</small></button>}
                        content={() =>  this.componentRef}
                    />
                </div>
                <div ref={el => (this.componentRef = el)}>
                  <Headerpdf />
                    {
                       <UserIdentity matricule ={this.props.matricule} name={this.props.name} classe={this.props.classe} title={this.props.title}/> 
                    }
                    <Bar
                        data={this.state}
                        options={{
                        plugins: {
                            title: {
                            display: true,
                            text: "Données"
                            },
                            legend: {
                            display: true,
                            position: "bottom"
                        }
                        }
                        }}
                    />
                </div>

            </div>
        )
    }
}
export default StudentBar;