import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import NotFound from './pages/NotFound';
import Dashboard from './pages/Dashboard';
import Student from "./pages/rh/students/Student";
import Supplier from "./pages/rh/suppliers/Supplier";
import Staff from "./pages/rh/staffs/Staff";
import Parentfamily from "./pages/rh/parentfamilies/Parentfamily";
import Profiles from "./pages/rh/profiles/Profile";
import Section from "./pages/rh/sections/Section";
import StudentProfile from "./pages/rh/students/Profile";
import StudentProfilefile from "./pages/rh/students/Profilefile";
import StaffProfile from "./pages/rh/staffs/Profile";
import Establishment from "./pages/rh/establishments/Establishment";
import Country from "./pages/rh/countries/Country";
import Year from "./pages/rh/years/Year";
import Trash from "./pages/rh/trashs/Trash";
import Archive from "./pages/rh/archives/Archive";
import Notification from "./pages/users/Notification";
import Profile from "./pages/users/Profile";
import Login from "./partials/Login";
import Report from "./pages/rh/reports/Report";
import Remind from "./pages/rh/reminds/Remind";
import Search from "./pages/users/Search";
import ArchiveDetail from "./pages/rh/archives/ArchiveDetail";
import Sectionclasse from "./pages/rh/sectionclasses/Sectionclasse";
import Leave from "./pages/rh/leaves/Leave";
import Sms from "./pages/rh/sms/Sms";
import StudentIdentification from "./pages/rh/students/StudentIdentification";

var permissions = JSON.parse(localStorage.getItem("permissions"))
function handleCheck(val) {
    return  (permissions ?? []).some(item => val === item.permission);
}

function RouterPage(){

    return(
        <Switch>

            {
                handleCheck("Afficher tableau de bord") == true && (
                    <Route exact path="/" component={Dashboard} />
                )
            }

            {
                handleCheck("Afficher élève") == true && (
                    <Route  path="/students/:studentId" component={StudentProfile} />
                )
            }

            {
                handleCheck("Afficher élève") == true && (
                    <Route  path="/identifications" component={StudentIdentification} />
                )
            }

            {
                handleCheck("Afficher élève") == true && (
                    <Route  path="/studentfile/:studentId" component={StudentProfilefile} />
                )
            }

            {
                handleCheck("Profil admin") == true && (
                    <Route  path="/reminds" component={Remind} />
                )
            }

            {
                handleCheck("Afficher élève") == true && (
                    <Route  path="/students" component={Student} />
                )
            }

            {
                handleCheck("Afficher fournisseur") == true && (
                    <Route  path="/suppliers" component={Supplier} />
                )
            }

            {
                handleCheck("Afficher personnel") == true && (
                    <Route  path="/staffs/:staff_id" component={StaffProfile} />
                )
            }

            {
                handleCheck("Afficher personnel") == true && (
                    <Route  path="/staffs" component={Staff} />
                )
            }

            {
                handleCheck("Afficher parent") == true && (
                    <Route  path="/parentfamilies" component={Parentfamily} />
                )
            }

            {
                handleCheck("Afficher profil") == true && (
                    <Route  path="/profiles" component={Profiles} />
                )
            }

            {
                handleCheck("Afficher etablissement") == true && (
                    <Route  path="/establishments" component={Establishment} />
                )
            }

            {
                handleCheck("Afficher section") == true && (
                    <Route  path="/sections" component={Section} />
                )
            }

            {
                handleCheck("Afficher classe") == true && (
                    <Route  path="/classes" component={Sectionclasse} />
                )
            }

            {
                handleCheck("Afficher localisation") == true && (
                    <Route  path="/localisations" component={Country} />
                )
            }

            {
                handleCheck("Afficher année") == true && (
                    <Route  path="/years" component={Year} />
                )
            }

            {
                handleCheck("Afficher congé") == true && (
                    <Route  path="/leaves" component={Leave} />
                )
            }

            {
                handleCheck("Ajouter élève") == true && (
                    <Route  path="/sms" component={Sms} />
                )
            }

            {
                handleCheck("Afficher corbeille") == true && (
                    <Route  path="/trash" component={Trash} />
                )
            }

            {
                handleCheck("Afficher rapport") == true && (
                    <Route  path="/reports" component={Report} />
                )
            }

            {
                handleCheck("Afficher archive") == true && (
                    <Route  path="/archives/student/:year" component={ArchiveDetail} />
                )
            }

            {
                handleCheck("Afficher archive") == true && (
                    <Route  path="/archives" component={Archive} />
                )
            }
            
            <Route  path="/search/:search" component={ Search } />
            <Route  path="/notifications" component={ Notification } />
            <Route  path="/profile" component={ Profile } />
            <Route  path="/get/:user_id" component={ Login } />
.            <Route  path="/login" component={ Login } />
            
            <Route component={NotFound} />
        </Switch>
    )
}

export default RouterPage;