import React, {Component} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import StudentForm from "./StudentForm";
import FeatherIcon from 'feather-icons-react';
import EstablishmentprofileData from "../../../components/rh/establishmentprofiles/EstablishmentprofileData";
import Listprint from "../../Listprint";
import { Helmet } from 'react-helmet';
import EstabsectschoolfeecatstudcategoryForm from "./EstabsectschoolfeecatstudcategoryForm";
import Loading from "../../Loading";
import { toast } from "react-toastify";

class   Student extends Component{

    state = {
        isModal : false,
        isModalProfile : false,
        isModalCategory : false,
        user : "",
        matricule : "",
        userestablishprofile : "",
        formData : "",
        displayArray : [],
        students : [],
        classe : "",
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Responsable',
                field: 'responsible',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Sexe',
                field: 'genre',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Statut',
                field: 'status',
                sort: 'asc',
                width: 150
            },
            // {
            //     label: 'Categorie',
            //     field: 'categorystudent',
            //     sort: 'asc',
            //     width: 150
            // },
            {
                label: 'Année',
                field: 'year',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Date inscr.',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
            {
                label: '#',
                field: 'action',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getStudents();
    }

    async getStudents(){
        var students = await axios.get('students');
        if(students.status === 200)
        {
            this.setState({
                students : []
            });
            this.setState({
                students : students.data
            })
        }
    }


    showModal = (student = null, classe=false, operation = false) => {
        if(operation == 1){
            this.setState({
                students : !this.state.isModal ? this.state.students : [],
            })
            this.getStudents()
        }
        this.setState({
            isModal : !this.state.isModal,
            formData : student,
            classe : classe ? student : null
        })
    }

    delete = (student) =>{
        axios.delete("students/"+student).then((response) => {
            this.setState({
                students : []
            })
            this.getStudents()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    showModalProfile = (userestablishprofile = null, user = null) => {
        this.setState({
            isModalProfile : !this.state.isModalProfile,
            userestablishprofile : userestablishprofile,
            user : user,
        })
        this.getStudents()
    }

    showModalCategory = (student = null) => {
        this.setState({
            students : !this.state.isModalCategory ? this.state.students : []
        })
        this.getStudents()
        this.setState({
            isModalCategory : !this.state.isModalCategory,
            formData : student,
        })
    }

    checkArray = (section) => {
        let displayArray = this.state.displayArray
        if(displayArray.filter(val => val == section)?.length > 0)
            displayArray = displayArray.filter(val => val != section)
        else
            displayArray.push(section)
        this.setState({
            displayArray : displayArray
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){

        var matricule = 1, count = 0, rows = [],  students = "", section = [], sectionclasses = [];

        this.state.students.map((student) => {
            count = section.filter(data => data.id == student.sectionclass.section.id).length;
            if(count === 0)
                section.push( student.sectionclass.section);
        });

        this.state.students.map((student) => {

            count = sectionclasses.filter(data => data.id == student.sectionclass.id).length;
            if(count === 0)
                sectionclasses.push(student.sectionclass);
        });

        students = 
        section.sort((a, b) => a.createdAt > b.createdAt ? 1 : -1).map((section) => {
            return(
                <div className="card mb-4 mt-4 shadow rounded  overflow-hidden rounded-3 " key={section.id}>
                    <div className="card-body row">
                        <h6 className="h6 font-monospace font-bolder">{section.entitled_section + " ("+section.short_entitled_section + ")"} <input type="checkbox" value={ this.state.displayArray?.filter(val => val == section?.id)?.length } onClick={ () => this.checkArray(section.id)} /> </h6>
                        
                        {
                            sectionclasses.filter(data => data.section.id == section.id).sort((a, b) => a.class.short_entitled_class > b.class.short_entitled_class ? 1 : -1).map((sectionclasse) => {
                                
                                rows = []
                                return(
                                    <div className={ this.state.displayArray?.filter(val => val == section.id)?.length > 0 ? "col-md-12 mb-4" : 'd-none'} key={sectionclasse.id}>
                                        <div className="card  mb-2 rounded rounded-3">
                                            <div className="card-body p-0 m-0 mt-1">
                                                <div className="text-center font-monospace "> 
                                                    <button className="btn btn-sm btn-outline-secondary me-1">{sectionclasse.class.short_entitled_class} {sectionclasse.title} <small>{section.entitled_section} </small> </button> 
                                                    {
                                                        this.handleCheck("Ajouter élève") == true && (
                                                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(sectionclasse.id, true)}><FeatherIcon icon="user-plus" size={20}></FeatherIcon> élève</button>
                                                        )
                                                    }
                                                </div>
                                                
                                                {
                                                    this.state.students.filter(data => data.sectionclass.id === sectionclasse.id).map((student) =>{
                                                        matricule = matricule + 1;
                                                        var commitment_date = new Date(student.createdAt);
                                                        commitment_date = commitment_date.toLocaleDateString('en-GB');
                                                        var last_name = student.user ? student.user.last_name : "";
                                                        var first_name = student.user ? student.user.first_name : "";
                                                        var family_name = student.user ? student.user.family_name : "";
                                                        rows.push(
                                                                {
                                                                    matricule : student.user && (<Link to={'/students/'+student.id}  className="text-decoration-none">{student.matricule}</Link>),
                                                                    student : last_name + " " + family_name + " " + first_name,
                                                                    responsible : student.user && (student.user.user && (student.user.user.first_name + " " +student.user.user.last_name)),
                                                                    staff_student : student.staff_student,
                                                                    genre : student.user ? student.user.genre : "",
                                                                    year : <small className="fw-light small">{student.year ? student.year.year : ""}</small>,
                                                                    status : student.status == 1 ? <span className="badge text-primary alert-primary">Actif</span> : <span className={ student.status == 0 ? "badge text-danger alert-danger" : "badge text-warning alert-warning"} > { student.status == 0 ? "Abandon" : "Non actif" }</span>,
                                                                    created_at : commitment_date,
                                                                    action : <span className="table_action">   
                                                                    {
                                                                        this.handleCheck("Modifier élève") == true && ( 
                                                                            <button onClick={ () => this.showModal(student)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={"10"}></FeatherIcon> </button>
                                                                        )
                                                                    }
                                                                    {
                                                                        this.handleCheck("Afficher élève") == true && (
                                                                            <Link to={"/studentfile/" + student.id} className="btn btn-outline-primary border-primary py-0 px-1 rounded rounded-3 text-decoration-none"><FeatherIcon icon="file" size={"10"}></FeatherIcon> </Link>
                                                                        )
                                                                    }
                                                                    {
                                                                        this.handleCheck("Supprimer élève") == true && (
                                                                            <button onClick={ () => this.delete(student.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" size={"10"}></FeatherIcon> </button>
                                                                        )
                                                                    }
                                                                    </span>,
                                                                }
                                                            )
                                                        })
                                                }
                                                
                                                <div style={{  fontSize : 14 +"px" }} className="table">
                                                
                                                 <Listprint columns={this.state.columns} rows={rows} title={"Eleves de " + sectionclasse.class.short_entitled_class + " "+ sectionclasse.title + "  "+ section.short_entitled_section}/>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                        
                    </div>
                </div>

            )
        })
         

        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | students</title>
                </Helmet>
            <div className=" align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Liste des élèves
                {
                    this.handleCheck("Ajouter élève") == true && (
                        <button type="button" className="btn btn-sm ms-3 btn-outline-primary" onClick={() => this.showModal(null, true)}><FeatherIcon icon="user-plus" size={20}></FeatherIcon> élève</button>
                    )
                }
                </h5>
            </div>                
                

            <div>
                {this.state.students.length > 0 ? students : <Loading/>} 
            </div>
            
            {
                this.state.isModal && (
                    <StudentForm 
                        isModal={this.state.isModal} 
                        formData={this.state.formData}
                        classe={this.state.classe}
                        matricule = {matricule}
                        showModal={this.showModal}
                    />
                )
            }

            {
                this.state.isModalCategory && (
                    <EstabsectschoolfeecatstudcategoryForm
                        isModal={this.state.isModalCategory} 
                        formData={this.state.formData}
                        classe={this.state.classe}
                        showModal={this.showModalCategory}
                    />
                )
            }

             {
                this.state.isModalCategory && (
                    <EstablishmentprofileData
                        isModal={this.state.isModalProfile} 
                        formData={this.state.userestablishprofile}
                        fromUser = {this.state.user}
                        showModal={this.showModalProfile}
                    />
                )
             }

        </div>
        )
    }
}
export default Student;