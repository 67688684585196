import axios from "axios";
import React, {Component} from "react";
import StaffShendule from "./StaffShendule";

class   Schedule extends Component{

    state = {
        isModal : false,
        formData : "",
        schedules : [],
        days : [],
        sections : [],
    }

    componentDidMount(){
        this.getSections();
        this.getDays();
        this.getSchedules();
    }

    async getSchedules(){
        var schedules = await axios.get('schedules/staff/'+this.props.staff);
        if(schedules.status === 200)
        {
            this.setState({schedules : schedules.data}) 
        }
    }

    async getSections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data}) 
        }
    }

    async getDays(){
        var days = await axios.get('days');
        if(days.status === 200)
        {
            this.setState({days : days.data}) 
        }
    }

    render(){
        var schedules =
        this.state.sections.map((section) =>{
             
            return(
                <StaffShendule schedules={this.state.schedules} section={section} user={this.props.staff} days={this.state.days} key={section.id} />
            )
        })
        return(
            <div className="col-md-12">
                <ul className="widget-todo-list-wrapper p-0">
                    { schedules }
                </ul>
            </div>
        )
    }
}
export default Schedule;