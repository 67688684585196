import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   ProfileForm extends Component{

    state = {
        id : null,
        profile : "",
        error : "",
        profilepermissions : [],
        isModal : false,
        title : "",
        permissionId : [],
        permissions : [],
        btn  : false
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async permissions () {
        this.setState({
            permissionId : []
        })
        var permissions = await axios.get('permissions');
        if(permissions.status == 200){
            this.setState({
                permissions : permissions.data
            });
            var permissionId = [];
            permissions.data.map((permission) => {

                
                var count = this.state.profilepermissions.filter(data => data.permissionId == permission.id).length;
                if(count > 0)
                permissionId.push(permission.id);
            })

            this.setState({
                permissionId : permissionId
            })
        }
    };

    storeProfile = () =>{
        
        var permissionId = [];

        this.setState({
            btn : !this.state.btn
        })

        for (let x in this.state.permissionId) {
                permissionId.push({ permissionId : this.state.permissionId[x]})
          }

        var data = {
                profile : this.state.profile,
                model : this.props.model,
                permissionId : permissionId,
        }
        
        var request = this.state.id ? axios.put('profiles/'+this.state.id, data) : axios.post('profiles', data);
        request.then((response) =>{
            this.setState({
                btn : !this.state.btn
            })
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg,
                btn : !this.state.btn
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
            
    }

    edit=()=>  {
        this.form();
        this.permissions()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            profile: this.props.formData ? this.props.formData.profile : "",
            title : this.props.formData ? "Modifier profil des utilisateurs" : "Ajouter profil des utilisateurs",
            profilepermissions : this.props.formData ? this.props.formData.profilepermissions : [],
            error : "",
        })
    }

    handleInputPermission = (e) => {
        var permissionId = this.state.permissionId;
        var permissions = this.state.permissions;
        this.setState({
            permissionId : [],
            permissions : []
        })
        var exist = permissionId.filter(data => data == e.target.name).length;
        if(exist > 0)
            permissionId = permissionId.filter(data => data != e.target.name);
        else
            permissionId.push(e.target.name);

        this.setState({
            permissionId : permissionId,
            permissions : permissions
        })
        
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    delete = (profile) =>{
        axios.delete("profiles/" + profile).then((response) => {
            this.getProfiles()
        }).catch((error)=>{})
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){

        return(
            <div>
            <Modal
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row" style={{  fontSize : 13 + "px" }}>
                    <div className="text-center text-danger text-small small">{ this.state.error }</div>
                    <div className="col-12 mt-2 mt-2">
                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon">Profile</label>
                            <div className="position-relative mt-1">
                                <input type="text" className="form-control form-control-sm" placeholder=""  onChange={this.handleInput} name="profile"  value={this.state.profile} id="nom-id-icon" />
                            </div>
                        </div>

                        <div className="form-group has-icon-left">
                            <label htmlFor="nom-id-icon text-primary">Accès du profil { this.state.profile} </label><br />
                            {
                                this.state.permissions.map((permission) => {
                                    return(
                                        <label className="px-1 rounded rounded-3 border border-1 mt-2 ms-2 mx-2">
                                            <input type="checkbox" className="round round-3" onChange={this.handleInputPermission} name={ permission.id }  checked={this.state.permissionId.filter(data => data == permission.id).length}  id={permission.permission} />
                                            <label htmlFor={permission.permission}>{ permission.permission }</label>
                                        </label>
                                    )
                                })
                            }
                        </div>
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {
                    (this.handleCheck("Supprimer profil") == true && this.state.id) && (
                        <a href="#" onClick={() => this.delete(this.state.id)} className="btn btn-sm btn-outline-danger"><FeatherIcon icon="delete" size={13}></FeatherIcon> Supprimer</a>
                    )}
                    <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left" size="13"></FeatherIcon> Fermer</button>
                    <button onClick={() => this.storeProfile()} disabled={ this.state.btn } className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square" size="13"></FeatherIcon> Sauvegarder</button>
                </Modal.Footer>
            </Modal>


        </div>
        )
    }
}
export default ProfileForm;
