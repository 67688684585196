import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";

class   QuartierForm extends Component{

    state = {
        id : null,
        name : "",
        communeId : "",
        groupmentId : "",
        error : "",
        communes : [],
        quartiers : [],
        groupments : [],
        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        });
    }

    async communes(){
        var request = await axios('communes');
        if(request.status == 200)
            this.setState({ communes : request.data  })
    }

    async quartiers(){
        var request = await axios('quartiers');
        if(request.status == 200)
            this.setState({ quartiers : request.data  })
    }

    async groupments(){
        var request = await axios('groupments');
        if(request.status == 200)
            this.setState({ groupments : request.data  })
    }

    storeTerritory = () =>{
        var data = {
                groupmentId : this.state.groupmentId,
                communeId : this.state.communeId,
                name : this.state.name,
            }
        var request = this.state.id ? axios.put('quartiers/'+this.state.id, data) : axios.post('quartiers', data);
        request.then(async(response) =>{
            this.props.showModal(response.data.data);
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    edit=()=>  {
        this.form()
        this.groupments()
        this.communes()
        this.quartiers()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            communeId: this.props.formData ? this.props.formData.communeId : null,
            groupmentId: this.props.formData ? this.props.formData.groupmentId : null,
            name: this.props.formData ? this.props.formData.name : "",
            error : "",
        })
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){

        return(
            <div>
            <Modal
                className="bg-white"
                show={this.props.isModal}
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header >
                    <Modal.Title  className="h6 font-monospace">{ this.state.id ? "Modifier" : "Quartier" }</Modal.Title>
                </Modal.Header>
                <Modal.Body className="row p-0 m-0">
                    <div className="col-md-6 mt-2">
                        {
                            this.state.quartiers.map((quartier) => {
                                return(
                                    <label className="border border-1 rounded-3 px-2 mx-1" onClick={() => this.setState({ id : quartier.id, communeId : quartier.communeId, groupmentId : quartier.groupmentId, name : quartier.name}) }>{ quartier.name }</label>
                                )
                            })
                        }
                    </div>

                    <div className="col-md-6 mt-2">
                        
                        <div className="text-center text-danger text-small small">{ this.state.error }</div>
                        <div className="form-group ">
                            <label htmlFor="nom-id-icon">Commune</label>
                            <select name="communeId" className="form-control form-control-sm" onChange={this.handleInput}>
                                <option value={""}>...</option>
                                {
                                    this.state.communes.map((commune) =>{
                                        return(
                                            commune.id == this.state.communeId ? 
                                            <option value={ commune.id} selected={true} >{commune.name}</option>
                                            :
                                            <option value={ commune.id}>{commune.name}</option>

                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group ">
                            <label htmlFor="nom-id-icon">Groupment</label>
                            <select name="groupmentId" className="form-control form-control-sm" onChange={this.handleInput}>
                                <option value={""}>...</option>
                                {
                                    this.state.groupments.map((groupment) =>{
                                        return(
                                            groupment.id == this.state.groupmentId ? 
                                            <option value={ groupment.id} selected={true} >{groupment.name}</option>
                                            :
                                            <option value={ groupment.id}>{groupment.name}</option>

                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="form-group ">
                            <label htmlFor="nom-id-icon">Quartier</label>
                            <input type="text" className="form-control" placeholder=""  onChange={this.handleInput} name="name"  value={this.state.name} id="nom-id-icon" />
                        </div>
                        <Modal.Footer>
                            <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><i className="ti ti-corner-down-left"></i> Fermer</button>
                            <button onClick={() => this.storeTerritory()} className="btn btn-sm btn-outline-primary"><i className="ti ti-check"></i> Sauvegarder</button>
                        </Modal.Footer>
                    </div>

                </Modal.Body>
                
            </Modal>
        </div>
        )
    }
}
export default QuartierForm;
