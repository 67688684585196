import React, {Component, useRef} from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import SchoolfeeDetail from "../../../components/rh/students/SchoolfeeDetail";
import ReactToPrint from "react-to-print";
import UserIdentity from "../../users/UserIdentity";
import Headerpdf from "../../users/Headerpdf";

// import PropTypes from "prop-types";

class Schoolfee extends Component {
    state = {
        studentschoolfees : {
            schoolfees : []
        },
        student : {},
        isModal : false,
    }

    componentDidMount(){
        this.getStudentschoolfees()
    }
    async getStudentschoolfees(){
        var studentschoolfees = await axios.get('/students/schoolfees/'+this.props.user)
        if(studentschoolfees.status === 200)
        {
            this.setState({
                studentschoolfees : studentschoolfees.data
            })
        }
    }

    showModal = (student = null, classe=false) => {
        this.setState({
            isModal : !this.state.isModal,
            student : student,
        })
        
    }


    render(){
        var somme = 0,  dataStudents = 
        this.state.studentschoolfees.schoolfees.map((student, index) => {
             
                somme = somme + student.pay_mount
                return(
                    <span key={student.id}>
                    <li className={" widget-todo-item list-group-item m-2 border rounded-3"}>
                        <div
                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                            <div className="widget-todo-title-area d-flex align-items-center">
                                <FeatherIcon icon="chevrons-right" className='cursor-move'></FeatherIcon>
                                <label htmlFor={student.id}><span className="widget-todo-title ml-50">{this.state.studentschoolfees.sectionclass && (this.state.studentschoolfees.sectionclass.class.short_entitled_class)} {this.state.studentschoolfees.sectionclass && (this.state.studentschoolfees.sectionclass.title)} # {this.state.studentschoolfees.sectionclass && (this.state.studentschoolfees.sectionclass.section.short_entitled_section)} # {student.year.year + " " + student.month.month}</span></label>
                            </div>
                            <Link to={"#"} onClick={() => this.showModal(student)} className="text-decoration-none"><span className={ student.remaining_amount > 0 ? "widget-todo-title ml-50 text-danger" : "widget-todo-title ml-50 text-primary" }>{student.pay_mount} {student.sectionschoolfeecategory.currency} </span></Link>
                            <div className="widget-todo-item-action d-flex align-items-center">
                                <Link to={"#"} onClick={() => this.showModal(student)} className={ student.remaining_amount > 0 ? "text-decoration-none text-danger" : "text-decoration-none text-primary" }>
                                    {student.sectionschoolfeecategory.schoolfeecategory.category}
                                    <FeatherIcon icon="chevrons-right" size="16" className='cursor-move text-primary' type="button"></FeatherIcon>  <span className="text-dark small me-2">({student.invoice})</span>
                                </Link>
                            </div>
                        </div>
                    </li>
                    {
                        index == this.state.studentschoolfees.schoolfees.length -1 && (
                            <li className={" widget-todo-item list-group-item m-2 border border-primary rounded-3 d-none"} >
                                <div
                                    className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                                    <div className="widget-todo-title-area d-flex align-items-center">
                                        <FeatherIcon icon="briefcase" className='cursor-move'></FeatherIcon>
                                        <label htmlFor={index}><span className="widget-todo-title ml-50"> Total</span></label>
                                    </div>
                                    <a href={"#https://evivesomarh.vercel.app/schoolfees/list/"+student.id} className="text-decoration-none text-primary"><span className="text-decoration-none"><span className={"widget-todo-title ml-50 text-primary" }>{somme} {student.sectionschoolfeecategory.schoolfeecategory.currency} </span></span></a>
                                    <div className="widget-todo-item-action d-flex align-items-center">
                                        <a href={"#https://evivesomarh.vercel.app/schoolfees/list/"+student.id} className="text-decoration-none text-primary">
                                            
                                            <FeatherIcon icon="chevrons-right" size="16" className='cursor-move' type="button"></FeatherIcon>
                                        </a>
                                    </div>
                                </div>
                            </li> 
                        )
                    }
                    </span>
                )
        })
        return(
            <div>
                <div className="text-end">
                    <ReactToPrint
                        trigger={() => <button className="btn btn-sm btn-outline-primary rounded rounded-3" type="button"><FeatherIcon icon={"printer"} size="22"></FeatherIcon> <span className="d-none d-sm-inline">Imprimer</span></button>}
                        content={() =>  this.componentRef}
                    />
                </div>
                <ul className="widget-todo-list-wrapper p-0" ref={el => (this.componentRef = el)}>
                    <Headerpdf />
                    {
                        <UserIdentity matricule ={this.props.matricule} name={this.props.name} classe={this.props.classe} title={this.props.title} /> 
                    }
                    <div className="print-customer">
                        { dataStudents }
                    </div>
                </ul>
                {
                    this.state.isModal && ( 
                        <SchoolfeeDetail 
                            isModal={this.state.isModal} 
                            student={this.state.student}
                            showModal={this.showModal}
                        />
                    )
                }
            </div>
        )
    }
}
export default Schoolfee;