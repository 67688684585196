import axios from "axios";
import React, {Component} from "react";
import SectionForm from "./SectionForm";
import FeatherIcon from 'feather-icons-react';
import StudentBar from "../students/StudentBar";
import Loading from "../../Loading";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";

class   Section extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        sections : [],
    }

    componentDidMount(){
        this.getSections();
    }

    async getSections(){
        var sections = await axios.get('sections');
        if(sections.status === 200)
        {
            this.setState({sections : sections.data, loading : true}) 
        }
    }

    showModal = (section = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : section,
        })
        this.getSections()
    }

    delete = (section) =>{
        axios.delete("sections/"+section).then((response) => {
            this.getSections()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        var somme= 0, labels = [], dataT=[], sections =
        this.state.sections.map((section) => {
            return(
                <li className="widget-todo-item  list-group-item m-2 border rounded-3" key={section.id}>
                    <div
                        className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                        <div className="widget-todo-title-area d-flex align-items-center">
                            <FeatherIcon icon="credit-card" size={"20"}></FeatherIcon>
                            <span className="widget-todo-title ms-1"><span className="h6 font-monospace">{section.short_entitled_section}</span> {section.entitled_section}</span>
                        </div>
                        {
                            this.handleCheck("Modifier section") == true && (
                                <div className="widget-todo-item-action d-flex align-items-center">
                                    <div className="avatar">
                                        <FeatherIcon icon="edit-2" type="button" className={"text-primary"} size={13}onClick={ () => this.showModal(section)} title="Modifier cet enregistrement"></FeatherIcon>
                                        {/* <button onClick={ () => this.delete(section.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={13}></FeatherIcon> </button> */}
                                    </div>
                                </div>
                            )
                        }
                        </div>
                </li>
            )
        })

        var data =
        this.state.sections.map((section) => {
            somme = 0;
            section.sectionclasses.map((sectionclasse) => {
                somme += sectionclasse.students.length
            })
            labels.push(section.short_entitled_section)
            dataT.push(somme)

            return(
                <div className="col-md-4" key={section.id}>
                    <div className="card shadow overflow-hidden mb-2 rounded rounded-3">
                        <div className="card-body">
                            <div>
                                <div className="text-center mb-2 h6">{section.entitled_section} </div>
                                <div className="text-center m-2"> <span className="h6 rounded-3 border border-1 border-primary p-1 mm-1">{section.short_entitled_section} </span> </div>
                                <div className="text-center mt-2"> <span className="  "><b>{ section.sectionclasses.length}</b> classes </span> </div>
                                <div className="text-center"> <span className=""> 
                                
                                <b>{somme}</b> Elève </span> </div>
                                {/* <div className="text-center"><span className="text-secondary size-13">{scolary_year} </span> </div> */}
                                <div className="text-center"><span className="text-secondary size-13">{section.status} </span> </div>
                            </div>
                            <div className="  text-end me-2">
                            {
                                this.handleCheck("Modifier section") == true && (
                                    <button onClick={ () => this.showModal(section)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="edit-3" size={13}></FeatherIcon> </button>
                                )
                            }
                            {
                                this.handleCheck("Supprimer section") == 1 && (
                                    <button onClick={ () => this.delete(section.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="delete" size={13}></FeatherIcon> </button>
                                )
                            }
                            </div> 
                        </div>
                    </div>
                </div>
            )
        })

        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | sections</title>
                </Helmet>

            <div className=" align-items-center pt-3 pb-2 mb-3 border-bottom">
                <h5 className="h5 font-monospace">Sections et options</h5>
                {
                    this.handleCheck("Ajouter section") == true && (
                        <div className="text-end me-2">
                            <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><FeatherIcon icon="plus-circle" ></FeatherIcon> Section</button>
                        </div>
                    )
                }
            </div>
            
            <div className="row">
            
                <div className="col-md-3">
                    <div className="card shadow overflow-hidden mb-2">
                        <div className="card-body">
                            <div className="col-lg-12">
                                <ul className="widget-todo-list-wrapper p-0">
                                    {sections}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                        
                <div className="col-md-9">
                    <div className="row">
                        {
                            this.state.loading ? data : <Loading />
                        }
                    </div>
                </div>
               
                <div className="col-md-12 text-center">
                    <div className="card shadow overflow-hidden mb-4">
                        <div className="card-body">
                        <div className="h6 border-bottom pb-2 font-monospace">Les élèves encours par section</div>
                        {
                            labels.length > 0 && (
                            <StudentBar
                                data={dataT}  
                                labels={labels}
                                title={"Les élèves par section"}
                            />)
                        }
                        </div>
                    </div>
                </div>
            </div>

             
            
            <SectionForm 
                isModal={this.state.isModal} 
                formData={this.state.formData} 
                showModal={this.showModal}
            />
        </div>
        )
    }
}
export default Section;