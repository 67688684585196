import React, {Component} from "react"; 
import axios from "axios"; 
import Listprint from "../../Listprint";
import StudentLine from "./StudentLine";

class Reportcardnote extends Component{
    state = {
        rows : [],
        labels : [],
        data : [],
    }

    componentDidMount(){
        this.getReportcards()
    }

    async getReportcards(){
        var evaluations = await axios.get('/student/reportcards/' + this.props.student);
        if(evaluations.status === 200)
        {
            var data = [], max = "", rows = [] , labels = [];
            if(evaluations.data.reportcards.length > 0)
            {
                evaluations.data.reportcards.filter(data => this.props.category == "Cours" && data.periode.id != 3 && data.periode.id != 6 && data.periode.id !=  7 || this.props.category == "Examen" && data.periode.id == 3  || this.props.category == "Examen" && data.periode.id == 6 || this.props.category == "Deuxieme session" && data.periode.id == 7).map((evaluation) => {
                    var noteD = 0, max = 1;
                    
                    evaluation.notes.map(note => {
                        noteD += note.note > 0 ? note.note : noteD;
                        max = (evaluation.periode.id != 3 || evaluation.periode.id != 6 || evaluation.periode.id != 7 ) ? evaluation.sectionclasselesson.max_evaluation : evaluation.sectionclasselesson.max_exam;
                            rows.push(
                                {
                                    Periode : evaluation.periode.id + "P",
                                    lesson : evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.short_entitled_lesson),
                                    note : note.note +" / "+ max,
                                    staff : evaluation.sectionclasselesson && (evaluation.sectionclasselesson.staff && (evaluation.sectionclasselesson.staff.user.first_name + " " +evaluation.sectionclasselesson.staff.user.last_name)),
                                    created_at : <small>{note.createdAt.slice(0, 10)}</small>
                                }
                            )
                    })
                    if(max != 1 && evaluation.sectionclasselesson.lesson && evaluation.sectionclasselesson.lesson.short_entitled_lesson)
                    {
                        if(labels.filter(data => data == evaluation.sectionclasselesson.lesson.short_entitled_lesson).length == 0)
                        {
                            noteD = (noteD * 100) /max;
                            labels.push(evaluation.sectionclasselesson.lesson && ( evaluation.sectionclasselesson.lesson.short_entitled_lesson))
                            data.push(noteD);
                        }
                        else{
                            labels.map((val, index) => {
                                if( val == evaluation.sectionclasselesson.lesson.short_entitled_lesson)
                                {
                                    noteD = (noteD * 100) /max;
                                    data[index] = data[index] ? data[index] > 0 ? ((data[index] + noteD) /2 ) : noteD  /2 : noteD ;
                                    labels[index] = evaluation.sectionclasselesson.lesson.short_entitled_lesson;
                                }
                            })
                        } 

                    }
                    
                    
                })

            }
            
            if(rows.length > 0)
            {
                this.setState({
                    columns : this.state.data.columns,
                    rows : rows,
                    labels : labels,
                    data : data
                })
            }
        }
    }


    render(){
        var columns = [
            {
                label: 'Periode',
                field: 'Periode',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Cours',
                field: 'lesson',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Note',
                field: 'note',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Titulaire',
                field: 'staff',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            }
        ];
        
        return(
            <div>
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Points {this.props.title}</div>
                            {
                                this.state.rows.length > 0 && (
                                    <Listprint 
                                        columns={columns} 
                                        rows={this.state.rows} 
                                        title={this.props.title}
                                        matricule={this.props.matricule}
                                        name={this.props.name}
                                        classe={this.props.classe}
                                    />
                                )
                            }
                        </div>
                    </div>

                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Statistique {this.props.category} en %</div>
                            {
                                this.state.labels.length > 0 && (
                                <StudentLine
                                    data={this.state.data}  
                                    labels={this.state.labels}
                                    title={"Statistique " + this.props.title + " en %"}
                                    matricule={this.props.matricule}
                                    name={this.props.name}
                                    classe={this.props.classe} 
                                 />)
                            }
                            
                        </div>
                    </div>

            </div>
        )
    }

}
export default Reportcardnote