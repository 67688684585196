import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import ParentfamilyForm from "../parentfamilies/ParentfamilyForm";
import ProfilImage from "../../users/ProfileImage";
import TerritoryForm from "../territories/TerritoryForm";
import CommuneForm from "../communes/CommuneForm";
import GroupmentForm from "../groupments/GroupmentForm";
import QuartierForm from "../quartiers/QuartierForm";
import { toast } from "react-toastify";

class   StudentForm extends Component{

    state = {
        id : "",
        avatar : false,
        responsible: "",
        sectionId : null,
        motherId : null,
        fatherId : null,
        territoryId : null,
        communeId : null,
        groupmentId : null,
        quartierId : null,
        isTerritory : false,
        isCommune : false,
        isGroupment : false,
        isQuartier : false,
        class : "",
        matricule : "",
        userId : "",
        first_name : "",
        last_name : "",
        family_name : "",
        genre : "",
        staff_student : "Non",
        status : 1,
        email : null,
        tel : null,
        identity_number : "",
        password : "",
        datebirth : null,
        title : "",
        adress : "",
        profiles : [],
        profileId : "",
        error : "",
        yearId : null,
        number_perm : "",
        numberId : "",
        category_student : "",
        number_house : "",
        code : "",
        name_category : "",
        sectschoolcatstudcat : "",

        isModal : false,
        sections : [],
        section :"",
        responsibles : [],
        territories : [],
        communes : [],
        groupments : [],
        quartiers : [],
        years : [],
        categorystudents : [],
        sectschoolcatstudcats : [],
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    handleInputAvatar = (e) => {
        this.setState({
            avatar : !this.state.avatar
        }); 
    }

    storeStudent = ( val = null) =>{
        var data = {
                user : {
                    userId: this.state.responsible,
                    first_name : this.state.first_name,
                    last_name : this.state.last_name,
                    family_name : this.state.family_name,
                    genre : this.state.genre,
                    email : this.state.email ? this.state.email : null,
                    tel : this.state.tel ? this.state.tel : null,
                    identity_number : this.state.identity_number ? this.state.identity_number : null,
                    datebirth : this.state.datebirth ? this.state.datebirth : null,
                    adress : this.state.adress,
                    yearId : this.state.yearId > 0 ? this.state.yearId : null,
                },
                student : {
                    territoryId : this.state.territoryId,
                    communeId : this.state.communeId,
                    groupmentId : this.state.groupmentId,
                    quartierId : this.state.quartierId,
                    number_house : this.state.number_house,
                    sectionclasseId : this.state.class,
                    motherId : this.state.motherId ? this.state.motherId : null,
                    fatherId : this.state.fatherId ? this.state.fatherId : null,
                    matricule : this.state.matricule ? this.state.matricule : null,
                    number_perm : this.state.number_perm ? this.state.number_perm : null ,
                    numberId : this.state.numberId ? this.state.numberId : null,
                    code : this.state.code ? this.state.code : null,
                    operation : this.state.id ? (val === 1 ? "Archiver" : "Editer" ) : "Ajouter",
                    yearId : this.state.yearId > 0 ? this.state.yearId : null,
                    staff_student : this.state.staff_student,
                    status : this.state.status,
                },
                profileId : this.state.profileId ? this.state.profileId : null,
                category_student : this.state.category_student,
                sectschoolcatstudcat : this.state.sectschoolcatstudcat,
            }
        
        var request = this.state.id ? axios.put('students/'+this.state.id, data) : axios.post('students', data);
        request.then((response) =>{
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            this.props.showModal(null, false, 1)
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async responsibles(){
        var request = await axios.get('parentfamilies');
        if(request.status === 200)
            this.setState({responsibles : request.data})
    }

    async categorystudents(){
        var request = await axios.get('categorystudents');
        if(request.status === 200)
            this.setState({categorystudents : request.data})
    }

    async sections(){
        var request = await axios.get('sections');
        if(request.status === 200)
            this.setState({sections : request.data})
    }

    async territories(){
        var request = await axios.get('territories');
        if(request.status === 200)
            this.setState({territories : request.data})
    }

    async quartiers(){
        var request = await axios.get('quartiers');
        if(request.status === 200)
            this.setState({quartiers : request.data})
    }

    async communes(){
        var request = await axios.get('communes');
        if(request.status === 200)
            this.setState({communes : request.data})
    }

    async groupments(){
        var request = await axios.get('groupments');
        if(request.status === 200)
            this.setState({groupments : request.data})
    }
    
    async getYear(){
        var data=[], years = await axios.get('years');
        if(years.status === 200)
        {
            years.data.map((year) => {
                data.push({value : year.id, label : year.year})
            })
            
            this.setState({
                years : data
            })
        }
    }

    async profiles(){
        var request = await axios.get('profiles');
        if(request.status === 200){
            this.setState({profiles : request.data})
            request.data.map((profile) => {
                if(profile.model == "Student")
                    this.setState({ profileId : profile.id})
            });
        }
    }

    edit = () =>  {
        this.form()
        this.getYear()
        this.responsibles()
        this.profiles();
        this.sections()
        this.territories()
        this.groupments()
        this.communes()
        this.quartiers()
        
    }

    form=()=>{
        
        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            sectionId : this.props.formData ? this.props.formData.sectionclass ? this.props.formData.sectionclass.sectionId : null : null,
            class : this.props.formData ? this.props.formData.sectionclass ? this.props.formData.sectionclass.id : null : null,
            motherId : this.props.formData ? this.props.formData.motherId : null,
            fatherId : this.props.formData ? this.props.formData.fatherId : null,
            territoryId : this.props.formData ? this.props.formData.territoryId : null,
            groupmentId : this.props.formData ? this.props.formData.groupmentId : null,
            communeId : this.props.formData ? this.props.formData.communeId : null,
            quartierId : this.props.formData ? this.props.formData.quartierId : null,
            number_house : this.props.formData ? this.props.formData.number_house : null,
            category_student: this.props.formData ? this.props.formData.categorystudent ?  this.props.formData.categorystudent.id : "" : "",
            responsible: this.props.formData ? this.props.formData.user ?  this.props.formData.user.user.id : "" : "",
            class : this.props.formData ? this.props.formData.sectionclass ? this.props.formData.sectionclass.id : this.props.classe : this.props.classe,
            matricule : this.props.formData ?  this.props.formData.matricule ? this.props.formData.matricule : this.props.matricule : this.props.matricule,
            userId : this.props.formData ? this.props.formData.user ?  this.props.formData.user.id : "" : "",
            first_name : this.props.formData ? this.props.formData.user ?  this.props.formData.user.first_name : "" : "",
            last_name : this.props.formData ? this.props.formData.user ?  this.props.formData.user.last_name : "" : "",
            family_name : this.props.formData ? this.props.formData.user ?  this.props.formData.user.family_name : "" : "",
            genre : this.props.formData ? this.props.formData.user ?  this.props.formData.user.genre : "" : "",
            number_perm : this.props.formData ? this.props.formData.number_perm : null,
            staff_student : this.props.formData ? this.props.formData.staff_student : "Non",
            status : this.props.formData ? this.props.formData.status ? this.props.formData.status : 1 : 1,
            numberId : this.props.formData ? this.props.formData.numberId : null,
            code : this.props.formData ? this.props.formData.code : null,
            yearId : this.props.formData ? this.props.formData.year ? this.props.formData.year.id : this.state.yearId: this.state.yearId,
            email : this.props.formData ? this.props.formData.user ?  this.props.formData.user.email : null : null,
            tel : this.props.formData ? this.props.formData.user ?  this.props.formData.user.tel : null : null,
            identity_number : this.props.formData ? this.props.formData.user ?  this.props.formData.user.identity_number : "" : "",
            datebirth : this.props.formData ? this.props.formData.user ?  this.props.formData.user.datebirth : null : null,
            adress : this.props.formData ? this.props.formData.user ?  this.props.formData.user.adress : "" : "",
            title : this.props.formData && this.props.formData.id ? ( this.props.isArchived ? "Désarchiver l'élève" : "Modifier l'élève" ) : "Ajouter un élève",
            avatar : false,
            error : "",
        });
    }

    async profiles(){
        var request = await axios.get('profiles');
        if(request.status === 200){
            this.setState({profiles : request.data})
            request.data.map((profile) => {
                if(profile.model == "Student")
                    this.setState({ profileId : profile.id})
            });
        }
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
            responsible : null,
        })
        this.responsibles()
    }

    showModalTerritory = (data = null) => {
        this.setState({
            isTerritory : false,
            territoryId : data ? data.id : this.state.territoryId
        })
        this.territories()
    }

    showModalCommune = (data = null) => {
        this.setState({
            isCommune : false,
            communeId : data ? data.id : this.state.communeId
        })
        this.communes()
    }

    showModalGroupment= (data = null) => {
        this.setState({
            isGroupment: false,
            groupmentId : data ? data.id : this.state.groupmentId
        })
        this.groupments()
    }

    showModalQuartier = (data = null) => {
        this.setState({
            isQuartier : false,
            quartierId : data ? data.id : this.state.quartierId
        })
        this.quartiers()
    }

    async sectschoolcatstudcats () {
        var classe = this.props.formData ? this.props.formData.class ? this.props.formData.class.id : this.props.classe : this.props.classe;
        var sectschoolcatstudcats = await axios.get('sectschoolcatstudcats/estblsectclass/'+classe);
        
        if(sectschoolcatstudcats.status == 200)
        {
            this.setState({
                sectschoolcatstudcats : sectschoolcatstudcats.data
            })
        }
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }

    render(){
        
        var optionsections = [], optionsectionclasses = [], optionresponsibles= [], optioncategorystudents = [], optionsectschoolcatstudcats = [], optionterritories = [], optionscommunes = [], optiongroupments = [], optionquarties = [];
        
        this.state.sections.map((section) => {
            optionsections.push({value : section, label :  section.entitled_section + " (" + section.short_entitled_section + ")"})
        });

        optionterritories.push({ value : null, label : "Nouvelle territoire"})
        optiongroupments.push({ value : null, label : "Nouveau groupment"})
        optionquarties.push({ value : null, label : "Nouveau quartier"})
        optionscommunes.push({ value : null, label : "Nouveau commune"})
        
        this.state.territories.map((terrirory) => {
            optionterritories.push({value : terrirory.id, label :  terrirory.name })
        });

        this.state.groupments.filter(data => data.territoryId == this.state.territoryId).map((groupment) => {
            optiongroupments.push({value : groupment.id, label :  groupment.name })
        });

        this.state.communes.filter(data => data.territoryId == this.state.territoryId).map((commune) => {
            optionscommunes.push({value : commune.id, label :  commune.name })
        });

        this.state.quartiers.filter(data => data.groupmentId == this.state.groupmentId || data.communeId == this.state.communeId).map((quartier) => {
            optionquarties.push({value : quartier.id, label :  quartier.name })
        });

        this.state.responsibles.map((responsible) => {
            optionresponsibles.push({value : responsible.user.id, label : responsible.user.last_name + "  "+ responsible.user.first_name +" "+responsible.user.tel, family_name : responsible.user.last_name})
        });

        this.state.categorystudents.map((categorystudent) => {
            optioncategorystudents.push({ value : categorystudent.id, label : categorystudent.category_student })
        });
        
        if(this.state.sectionId && optionsections.length > 0)
        {
            if(this.state.sections.filter(dat => dat.id == this.state.sectionId).length > 0 )
            {
                this.state.sections.filter(dat => dat.id == this.state.sectionId)[0].sectionclasses.map((sectionclasse) =>{
                    optionsectionclasses.push({value : sectionclasse.id, label : sectionclasse.class.short_entitled_class + "  "+ sectionclasse.title})
                })
            }
        }

        this.state.sectschoolcatstudcats.map((sectschoolcatstudcat) => {
            if(this.state.category_student == sectschoolcatstudcat.categorystudent.id)
            {
                optionsectschoolcatstudcats.push({
                    value : sectschoolcatstudcat.id,
                    label : sectschoolcatstudcat.price + "" + sectschoolcatstudcat.sectionschoolfeecategory.currency + " "+ sectschoolcatstudcat.categorystudent.category_student + " " + sectschoolcatstudcat.sectionschoolfeecategory.schoolfeecategory.category
                })
            }
        })
        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row">
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                    {
                        !this.props.classe && !this.props.isArchived && ( <>
                            <div className="form-group col-md-4 mt-2">
                                <label className="font-monospace fw-normal">Section<span className="text-danger">*</span></label>
                                <Select
                                    className="form-control  form-control-sm p-0"
                                    defaultValue={this.state.sectionId}
                                    value = {
                                            optionsections.filter(option => 
                                            option.value.id == this.state.sectionId)
                                        }
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                sectionId : select.value.id
                                            });
                                        }
                                    }
                                    options={optionsections}
                                />

                            </div>

                            <div className="form-group col-md-4 mt-2">
                                <label className="font-monospace fw-normal">Classe<span className="text-danger">*</span></label>
                                <Select
                                    className="form-control  form-control-sm p-0"
                                    defaultValue={this.state.class}
                                    value = {
                                            optionsectionclasses.filter(option => 
                                            option.value == this.state.class)
                                    }
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                class : select.value
                                            });
                                        }
                                    }
                                    options={optionsectionclasses}
                                />
                            </div>

                            <div className=" col-md-4 mt-2">
                                <label className="font-monospace fw-normal">Année scolaire<span className="text-danger">*</span></label>
                                <div className="input-group">
                                    <Select
                                        required={true}
                                        className="form-control  form-control-sm p-0"
                                        value = {
                                            this.state.years.filter(option => 
                                            option.value == this.state.yearId)
                                        }
                                        defaultValue={this.state.yearId}
                                        onChange={
                                            (select) => {
                                                this.setState({
                                                    yearId : select.value
                                                });
                                            }
                                        }
                                        options={this.state.years}
                                    />
                                </div>
                            </div>
                        </>)
                    }
                    
                        <div className="form-group col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Responsable<span className="text-danger">*</span></label>
                            <div className="input-group">
                                <Select
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optionresponsibles.filter(option => 
                                        option.value == this.state.responsible)
                                    }
                                    defaultValue={this.state.responsible}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                responsible : select.value,
                                                family_name : this.state.family_name ? this.state.family_name : select.family_name
                                            });
                                        }
                                    }
                                    options={optionresponsibles}
                                />
                                <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.showModal()}><FeatherIcon icon="user-plus"></FeatherIcon></button>
                            </div>
                        </div>

                        <div className="form-group col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Père</label>
                            <div className="input-group">
                                <Select
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optionresponsibles.filter(option => 
                                        option.value == this.state.fatherId)
                                    }
                                    defaultValue={this.state.fatherId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                fatherId : select.value,
                                            });
                                        }
                                    }
                                    options={optionresponsibles}
                                />
                                <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.showModal()}><FeatherIcon icon="user-plus"></FeatherIcon></button>
                            </div>
                        </div>

                        <div className="form-group col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Mère</label>
                            <div className="input-group">
                                <Select
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optionresponsibles.filter(option => 
                                        option.value == this.state.motherId)
                                    }
                                    defaultValue={this.state.motherId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                motherId : select.value,
                                            });
                                        }
                                    }
                                    options={optionresponsibles}
                                />
                                <button className="btn btn-outline-primary" type="button" id="button-addon2" onClick={() => this.showModal()}><FeatherIcon icon="user-plus"></FeatherIcon></button>
                            </div>
                        </div>

                        <div className="form-group col-md-4 mt-2 d-none">
                            <label className="font-monospace fw-normal">Catégorie</label>
                            <div className="input-group">
                                <Select
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optioncategorystudents.filter(option => 
                                        option.value == this.state.category_student)
                                    }
                                    defaultValue={this.state.category_student}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                category_student : select.value,
                                                name_category : select.label,
                                            });
                                        }
                                    }
                                    options={optioncategorystudents}
                                />
                            </div>
                        </div>

                        <div className="col-md-4 mt-2 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="nom-id-icon">Nom<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="last_name"  value={this.state.last_name} id="nom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="postnom-id-icon">Post-nom<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="family_name"  value={this.state.family_name} id="postnom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="prenom-id-icon">Prénom</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm"  onChange={this.handleInput} name="first_name"  value={this.state.first_name} id="prenom-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="user-plus"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="matricule-id-icon">Matricule / N°<span className="text-danger">*</span></label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm"  onChange={this.handleInput} name="matricule"  value={this.state.matricule} id="matricule-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="numero-id-icon">N° d'identité</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm"  onChange={this.handleInput} name="identity_number"  value={this.state.identity_number} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="trello"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 mt-2 d-none">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="email-id-icon">Email</label>
                                <div className="position-relative mt-1">
                                    <input type="email" className="form-control form-control-sm"  onChange={this.handleInput} name="email"  value={this.state.email} id="email-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="mail"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="numero-id-icon">N° de téléphone</label>
                                <div className="position-relative mt-1">
                                    <input type="number" className="form-control form-control-sm" placeholder="" onChange={this.handleInput} name="tel"  value={this.state.tel} id="numero-id-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="phone-call"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="first-name-icon">Lieu de naissance</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" onChange={this.handleInput} name="adress" value={this.state.adress} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="flag"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="first-name-icon">Date de naissance</label>
                                <div className="position-relative mt-1">
                                    <input type="date" className="form-control form-control-sm" onChange={this.handleInput} name="datebirth" value={this.state.datebirth} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="calendar"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="first-name-icon">N° ID</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" onChange={this.handleInput} name="numberId" value={this.state.numberId} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="file"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="first-name-icon">N° permanant</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" onChange={this.handleInput} name="number_perm" value={this.state.number_perm} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="box"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="first-name-icon">Code</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" onChange={this.handleInput} name="code" value={this.state.code} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="file"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Statut élève<span className="text-danger">*</span></label>
                            <div className="input-group">
                                <Select
                                    required={true}
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        [{value : 1, label : "Actif"}, {value : 2, label : "non actif"}, {value : 0, label : "Abandon"}].filter(option => 
                                        option.value == this.state.status)
                                    }
                                    defaultValue={this.state.status}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                status : select.value
                                            });
                                        }
                                    }
                                    options={[{value : 1, label : "Actif"}, {value : 2, label : "non actif"}, {value : 0, label : "Abandon"}]}
                                />
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Teritoire</label>
                            <div className="input-group">
                                <Select
                                    required={true}
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optionterritories.filter(option => 
                                        option.value == this.state.territoryId)
                                    }
                                    defaultValue={this.state.territoryId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                territoryId : select.value,
                                                isTerritory : select.value ? false : true,
                                            });
                                        }
                                    }
                                    options={ optionterritories }
                                />
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Commune</label>
                            <div className="input-group">
                                <Select
                                    required={true}
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optionscommunes.filter(option => 
                                        option.value == this.state.communeId)
                                    }
                                    defaultValue={this.state.communeId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                communeId : select.value,
                                                isCommune : select.value ? false : true,
                                            });
                                        }
                                    }
                                    options={ optionscommunes }
                                />
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Groupement</label>
                            <div className="input-group">
                                <Select
                                    required={true}
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optiongroupments.filter(option => 
                                        option.value == this.state.groupmentId)
                                    }
                                    defaultValue={this.state.groupmentId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                groupmentId : select.value,
                                                isGroupment : select.value ? false : true,
                                            });
                                        }
                                    }
                                    options={ optiongroupments }
                                />
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Quartiers</label>
                            <div className="input-group">
                                <Select
                                    required={true}
                                    className="form-control  form-control-sm p-0"
                                    value = {
                                        optionquarties.filter(option => 
                                        option.value == this.state.quartierId)
                                    }
                                    defaultValue={this.state.quartierId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                quartierId : select.value,
                                                isQuartier : select.value ? false : true,
                                            });
                                        }
                                    }
                                    options={ optionquarties }
                                />
                            </div>
                        </div>

                        <div className="col-md-4 mt-2">
                            <div className="form-group has-icon-left">
                                <label className="font-monospace fw-normal" htmlFor="first-name-icon">Numéro</label>
                                <div className="position-relative mt-1">
                                    <input type="text" className="form-control form-control-sm" onChange={this.handleInput} name="number_house" value={this.state.number_house} id="first-name-icon" />
                                    <div className="form-control-icon text-primary">
                                        <FeatherIcon icon="file"></FeatherIcon>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className=" col-md-4 mt-2">
                            <label className="font-monospace fw-normal">Sexe<span className="text-danger">*</span></label>
                            <div className="input-group pt-2">
                                <input type={"radio"} name="genre" value={"Masculin"} checked={this.state.genre === "Masculin" ? true : false} onChange={this.handleInput} id="masculin" className="rounded m-2" /> <label htmlFor="masculin">Masculin</label>
                                <input type={"radio"} name="genre" value={"Feminin"} checked={this.state.genre == "Feminin" ? true : false} onChange={this.handleInput} id="feminin" className="rounded m-2" /> <label htmlFor="feminin">Féminin</label>
                            </div>
                        </div>


                        {
                            this.state.category_student && (
                                <div className=" col-md-12 mt-2">
                                    <label className="font-monospace fw-normal">Catégorie de paiement { this.state.name_category } </label>
                                    <div className="input-group p-0">
                                        <Select
                                            className="form-control form-control-sm p-0"
                                            closeMenuOnSelect={false}
                                            defaultValue={[ ]}
                                            isMulti
                                            options={optionsectschoolcatstudcats}
                                            onChange={
                                                (select) => {
                                                    this.setState({
                                                        sectschoolcatstudcat : select
                                                    });
                                                }
                                            }
                                        />
                                    </div>
                                </div>
                            )
                        }

                        <div className={ this.state.id ? " mt-2" : "d-none"}>
                            <div className="input-group pt-2">
                                <input type={"checkbox"} name="avatar" onChange={this.handleInputAvatar} className="rounded m-2" id="avatar" /> <label htmlFor="avatar">Modifier la photo de profil</label>
                            </div>
                            <div className={ this.state.avatar ? "" : "d-none"} >
                                <ProfilImage userId={this.state.userId} />
                            </div>
                        </div>
                        
                        <hr />

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        {/* {
                            !this.props.isArchived && this.state.id && this.handleCheck("Afficher archive") == true &&(
                                <button onClick={() => this.storeStudent(1)} className="btn btn-outline-success btn-sm"><FeatherIcon icon="archive"></FeatherIcon> Archiver</button>
                            )
                        } */}
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeStudent()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> { this.props.isArchived ? "Desarchiver" : "Sauvegarder" }</button>
                    </Modal.Footer>
            </Modal>
            

            <ParentfamilyForm 
                isModal={this.state.isModal} 
                formData={{}} 
                showModal={this.showModal}
            />

            <TerritoryForm
                isModal={this.state.isTerritory}
                showModal={this.showModalTerritory}
            />

            <CommuneForm
                isModal={this.state.isCommune}
                showModal={this.showModalCommune}
            />

            <GroupmentForm
                isModal={this.state.isGroupment}
                showModal={this.showModalGroupment}
            />

            <QuartierForm
                isModal={this.state.isQuartier}
                showModal={this.showModalQuartier}
            />

        </div>
        )
    }
}
export default StudentForm;