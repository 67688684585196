import axios from "axios";
import React, {Component} from "react";
import Select from 'react-select';
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import { toast } from "react-toastify";

class   LeaveForm extends Component{

    state = {
        id : "",
        reason : "",
        date_start : "",
        date_end : "",
        staffId : "",
        staffs : [],
        error : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    storeLeave = () =>{
        var data = {
            staffId : this.state.staffId ,
            reason : this.state.reason,
            date_start : this.state.date_start,
            date_end : this.state.date_end
        }
        
        var request = this.state.id ? axios.put('leaves/'+this.state.id, data) : axios.post('leaves', data);
        request.then((response) =>{
            this.props.showModal()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error) => {
            this.setState({
                error : error.response.data.msg
            })
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    async getStaffs(){
        var data=[], staffs = await axios.get('staffs');
        if(staffs.status === 200)
        {
            staffs.data.map((staff) => {
                data.push({value : staff.id, label : staff.matricule + " " + staff.user.last_name + " " + staff.user.first_name})
            })
            
            this.setState({
                staffs : data
            })
        }
    }

    edit=()=>  {
        this.form()
        this.getStaffs();
    }

    form=()=>{
        
        var date_start =  new Date(this.props.formData ? this.props.formData.date_start : null);
        var mois = date_start.getMonth()+1;
            mois = mois >= 10 ? mois : "0"+mois;
        var jour = date_start.getDate() >= 10 ? date_start.getDate() : "0"+date_start.getDate();
        date_start = date_start.getFullYear()  + "-" + (mois) + "-" + jour;

        var date_end = this.props.formData ?  new Date(this.props.formData.date_end) : new Date();
        mois = date_end.getMonth()+1;
            mois = mois >= 10 ? mois : "0"+mois;
        jour = date_end.getDate() >= 10 ? date_end.getDate() : "0"+date_end.getDate();
        date_end = date_end.getFullYear()  + "-" + (mois) + "-" + jour;

        this.setState({
            id : this.props.formData ? this.props.formData.id : "",
            reason : this.props.formData ? this.props.formData.reason : "",
            date_start : this.props.formData ? date_start : "",
            date_end : this.props.formData ? date_end : "",
            staffId : this.props.formData  ? this.props.formData.staff.id : "",
            title : this.props.formData ? "Modifier les infomations du congé" : "Ajout du congé",
            error : "",
        })
    }


    render(){

        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                    <Modal.Body className="row" style={{  fontSize :  14 + "px" }}>
                        <div className="text-center"><span className="text-danger text-small small">{ this.state.error }</span></div>
                        
                        <div className=" col-md-12 mt-2">
                            <label className="font-monospace fw-normal">Personnel</label>
                            <div className="input-group">
                                <Select
                                    className="form-control fomr-control-sm p-0"
                                    value = {
                                        this.state.staffs.filter(option => 
                                        option.value == this.state.staffId)
                                    }
                                    defaultValue={this.state.staffId}
                                    onChange={
                                        (select) => {
                                            this.setState({
                                                staffId : select.value
                                            });
                                        }
                                    }
                                    options={this.state.staffs}
                                />
                            </div>
                        </div>

                        <div className="col-md-6 col-6 mt-2">
                            <div className="form-group">
                                <label htmlFor="email-id-icon">Raison du congé</label>
                                <div className="position-relative mt-1">
                                    <textarea className="form-control form-control-sm" rows={4} onChange={this.handleInput} name="reason"  value={this.state.reason}></textarea>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-6 row">
                            <div className="col-md-12 mt-2">
                                <div className="form-group has-icon-left">
                                    <label htmlFor="numero-id-icon">Date début</label>
                                    <div className="position-relative mt-1">
                                        <input type="date" className="form-control form-control-sm"  onChange={this.handleInput} name="date_start"  value={this.state.date_start} id="numero-id-icon" />
                                        <div className="form-control-icon text-primary">
                                            <FeatherIcon icon="calendar"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-12 mt-2">
                                <div className="form-group has-icon-left">
                                    <label htmlFor="numero-id-icon">Date fin</label>
                                    <div className="position-relative mt-1">
                                        <input type="date" className="form-control form-control-sm" placeholder="" onChange={this.handleInput} name="date_end"  value={this.state.date_end} id="numero-id-icon" />
                                        <div className="form-control-icon text-primary">
                                            <FeatherIcon icon="calendar"></FeatherIcon>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <div className="text-center"><span className="text-danger text-small">{ this.state.error }</span></div>
                        <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm p-0 px-1"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                        <button onClick={() => this.storeLeave()} className="btn btn-sm btn-outline-primary p-0 px-1"><FeatherIcon icon="check-square"></FeatherIcon> Sauvegarder</button>
                    </Modal.Footer>
            </Modal>
            
        </div>
        )
    }
}
export default LeaveForm;