import axios from "axios";
import React, {Component} from "react";
import { Link } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import StudentLine from "../students/StudentLine";
import StudentForm from "../students/StudentForm";
import { Helmet } from 'react-helmet';
import Loading from "../../Loading";

class Archive extends Component{

    state = {
        isModal : false,
        loading : false,
        formData : "",
        archives : [],
        isModal : false,
        formData : {},
        classe : "",
        columns : [
            {
                label: 'Matr',
                field: 'matricule',
                sort: 'asc',
                width: 50
            },
            {
                label: 'Eleve',
                field: 'student',
                sort: 'asc',
                width: 200
            },
            {
                label: 'Responsable',
                field: 'responsible',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Année',
                field: 'year',
                sort: 'asc',
                width: 150
            },
            {
                label: 'Date',
                field: 'created_at',
                sort: 'asc',
                width: 120
            },
            {
                label: 'Status',
                field: 'status',
                sort: 'asc',
                width: 120
            }
        ]
    }

    componentDidMount(){
        this.getArchives();
    }

    async getArchives(){
        var archives = await axios.get('yearslist');
        if(archives.status === 200)
        {
            this.setState({
                archives : [],
                loading : false,
            })
            this.setState({archives : archives.data, loading : true}) 
        }
    }

    showModal = (student = null, classe=false) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : student,
            classe : classe ? student : null
        })
        this.getArchives()
    }


    render(){
        var students = 0;
        var labels = [], data = [], rows = []

        var archives =
        this.state.archives.map((year) => {
            students = year.students.length
            labels.push(year.year)
            data.push(students)
            return(
                <div  key={year.id} className="overflow-hidden">
                    <li className="widget-todo-item list-group-item m-2 border border-secondary rounded-3 shadow mb-2">
                        <div
                            className="widget-todo-title-wrapper d-flex justify-content-between align-items-center mb-50">
                            <div className="widget-todo-title-area d-flex align-items-center">
                                <FeatherIcon icon="package" className='cursor-move text-primary'></FeatherIcon> 
                                 <label htmlFor={year.id}><span className="widget-todo-title ml-50 fw-bolder" ><Link to={"/archives/student/"+year.id} className="text-dark text-decoration-none"> {year.year} <smal className="size-10 text-primary">({students} élèves)</smal></Link></span></label>
                            </div>
                            <div className="widget-todo-item-action d-flex align-items-center">
                                <Link to={"/archives/student/"+year.id} className="text-dark text-decoration-none">
                                    <span className="text-primary">Voir le detail </span>
                                    <b><FeatherIcon icon="book-open" size="18" className='cursor-move'></FeatherIcon></b>
                                </Link>
                            </div>
                        </div>
                    </li>
                    
                </div>
            )
        })
        return(
            <div>
                <Helmet>
                    <title>{ process.env.REACT_APP_NAME } | archived data</title>
                </Helmet>
            <div className=" pt-3 pb-2 mb-3 border-bottom">
                <h6 className="h5 font-monospace">Archive scolaire resources</h6>
            </div>

            <div className=" mb-4 overflow-auto">
                <div className="">
                    <ul className="widget-todo-list-wrapper p-0">
                        {this.state.loading > 0 ? archives : <Loading/>} 
                    </ul>
                </div>
            </div>

            <div className="mt-4">
                <div className="h5 font-monospace">Statistique annuelle des archives des élèves</div>
                {
                    labels.length > 0 && (
                        <StudentLine
                            labels={labels}
                            data={data}
                            title={"Archive des élèves"}
                        />
                       
                    )
                }
                
            </div>

            <StudentForm
                isArchived = {true}
                isModal={this.state.isModal} 
                formData={this.state.formData}
                classe={this.state.classe}
                showModal={this.showModal}
            />
        </div>
        )
    }
}
export default Archive;