import axios from "axios";
import React, {Component} from "react";
import Modal from "react-bootstrap/Modal";
import FeatherIcon from 'feather-icons-react';
import ReactLoading from "react-loading";
import { toast } from "react-toastify";

class  ArchiveForm extends Component{

    state = {
        id : "",
        yearId : null,
        error : "",
        sectionclasses : [],

        sectionclass : [],
        sectionclasseId : [],
        sectionclasse : [],
        years : [],
        archive : false,
        isModal : false,
        title : "",
    }

    handleInput = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        }); 
    }

    inputSelect = (e) => {
        var data = this.state.sectionclasseId;
        if(data.filter(data => e.target.value == data.value).length == 0)
        {
            data.push({
                name : e.target.name,
                value : e.target.value,
            })

            this.setState({
                sectionclasseId : data
            })
        
        }
        else{
            data.map((dat, index) => {
                if(dat.value == e.target.value){
                    data[index].name = e.target.name
                    data[index].value = e.target.value
                }
            })
            this.setState({
                sectionclasseId : data
            })
        }
    }
    
    async sectionclasses(){
        var sectionclasses = await axios.get('sectionclasses');
        if(sectionclasses.status == 200)
        {
            this.setState({
                sectionclasses : sectionclasses.data,
                sectionclasse : sectionclasses.data,
            })
        }
    }

    archive = () => {
        this.setState({
            archive : true
        })
        var data = {
            sectionclasseId : this.state.sectionclasseId,
            yearId : this.state.yearId
        }
        if(this.state.yearId > 0){
            axios.post('years/archive/'+this.state.id, data).then((response) =>{
                this.props.showModal()
                toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
            }).catch((err) => { })
        }else{
            this.setState({
                error : "L'année suivante est obligatoire"
            })
            toast('L année suivante est obligatoire', { theme : "light", style : { backgroundColor : "red", color : "white"}})
        }

    }

    desarchive = () => {
        this.setState({
            archive : true
        })
        axios.post('years/archiver/'+this.state.id).then((response) =>{
            this.props.showModal()
        }).catch((err) => {  })
    }
    
    async years(){
        var years = await axios.get("years");
        if(years.status === 200)
        {
            this.setState({
                years : years.data
            })
        }
    }
    
    edit=()=>  {
        this.form();
        this.years()
    }

    form=()=>{
        this.setState({
            id: this.props.formData ? this.props.formData.id : null,
            scolary_year: this.props.formData ? this.props.formData.scolary_year : null,
            title : "Archiver les données",
            archive : false,
            error : "",
        })
        this.sectionclasses()
    }

    showModal = () => {
        this.setState({
            isModal : !this.state.isModal,
        })
    }

    render(){
        

        return(
            <div>
            <Modal 
                show={this.props.isModal} 
                onHide={this.props.showModal}
                size="lg"
                onEntered={this.edit}
                backdrop={"static"}>
                <Modal.Header>
                    <Modal.Title className="h6 font-monospace">{ this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body  >
                    <div className="text-danger text-center h6">Attention cette action est irreverible <br /> {this.state.error} </div>
                    
                    <div className="row">
                    {
                        this.state.sectionclasses.map((classe, index) => {
                            return(
                                <div className="row border  rounded rounded-2 mb-2 p-1 m-1">
                                    <div className="col-4">
                                        <input type={"text"} className="form-control form-control-sm bg-white border border-0" value={classe.id} placeholder={ classe.class.short_entitled_class + " " + classe.title + " "+ classe.section.short_entitled_section } readOnly={true} name="start" />
                                    </div>
                                    <div className="col-4">
                                        { this.state.sectionclasses[index].class.short_entitled_class } { this.state.sectionclasses[index].title } { this.state.sectionclasses[index].section.entitled_section  }
                                    </div>
                                    <div className="col-4">
                                        <select onChange={this.inputSelect} name={classe.id} className="form-control form-control-sm">
                                            <option value={""}>....</option>
                                            {
                                                this.state.sectionclasse.map((next) => {
                                                    return(
                                                        <option value={next.id}>{ next.class.short_entitled_class } { next.title } { next.section.short_entitled_section }</option>
                                                    )
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                            )
                        })
                    }
                    <div className="col-md-6">
                        <select name="yearId" className="form-control form-control-sm" value={this.state.yearId} onChange={ (select) =>{
                                this.setState({
                                    yearId : select.target.value
                                })
                            }}>
                            <option value={""}>....</option>
                            {
                                this.state.years.map(year => {
                                    return( <option value={year.id}>{ year.year }</option> )
                                })
                            }
                        </select>
                    </div>
                    {
                        this.state.archive && (
                            <div>
                                <div className="h5 font-monospace text-center">Archivage en cours ...</div>
                                <div className="d-flex justify-content-center pt-4">
                                    <ReactLoading type="spokes" color="blue" height={200} width={150} />
                                </div>
                            </div>
                        )
                    }
                    </div>

                </Modal.Body>
                <Modal.Footer>
                    {
                        this.props.archive && (
                            <>
                                <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                                <button onClick={() => this.desarchive()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="database"></FeatherIcon> Désarchiver</button>
                            </>
                        )
                    }
                    {
                        !this.props.archive && (<>
                            <button onClick={this.props.showModal} className="btn btn-outline-secondary btn-sm"><FeatherIcon icon="corner-down-left"></FeatherIcon> Fermer</button>
                            <button onClick={() => this.archive()} className="btn btn-sm btn-outline-primary"><FeatherIcon icon="database"></FeatherIcon> Archiver</button>
                        </>)
                    }
                </Modal.Footer>
            </Modal>
            
 
        </div>
        )
    }
}
export default ArchiveForm;