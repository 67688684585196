import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import StudentForm from "./StudentForm";
import Chat from "../../users/Chat";
import StudentLine from "./StudentLine";
import Reportcard from "./Reportcard";
import Schoolfee from "./Schoolfee";
import Reportcardnote from "./Reportcardnote";
import StudentBar from "./StudentBar";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

class Profile extends Component{

    state = {
        student : {
            user : {
                user : {}
            }
        },
        isModal : false,
        formData : {},
        classe : false,
        schoolfeelabels : [],
        schoolfeedata : [],
        reportlabels : [],
        reportdata : [],
        reportexamenlabels : [],
        reportexamendata : [],
        reportcourslabels : [],
        reportcoursdata : [],
    }
    
    componentDidMount(){
        this.getUser()
    }

    async getStudentreports(){
        var studentreports = await axios.get('users/students/'+this.state.student.user.id)
        if(studentreports.status === 200)
        {
            var labels = [], data = [];
            studentreports.data.students.map((studentreport, index) =>{
                labels.push(studentreport.year ? studentreport.year.year : '')
                data.push(studentreport.studentmax > 0 ? ((studentreport.studentsuccess * 100 ) / studentreport.studentmax).toFixed(1) : 0)
            })
            this.setState({
                reportlabels : labels,
                reportdata : data
            })
        }
    }

    async getStudentreportexamens(){
        var evaluations = await axios.get('/student/reportcards/' + this.state.student.id);

        if(evaluations.status === 200)
        {
            var reportexamendata = [], reportexamenlabels = [], lessonExamen = null, noteExamen = 0, 
                reportcoursdata = [], reportcourslabels = [], lessonCours = null, noteCours = 0;
            evaluations.data.reportcards.map((evaluation, index) => {
                if(evaluation.periodeId === 3 || evaluation.periodeId === 6 || evaluation.periodeId === 7)
                {
                    if(evaluation.sectionclasslesson.lesson &&  evaluation.sectionclasslesson.lesson.short_entitled_lesson != lessonExamen)
                    {
                        if(lessonExamen != null)
                        {
                            reportexamenlabels.push(lessonExamen);
                            reportexamendata.push(noteExamen);
                            lessonExamen = null;
                            noteExamen = 0;
                        }
                        lessonExamen = evaluation.sectionclasslesson.lesson.short_entitled_lesson;
                    }
                    else if(evaluations.data.length-1 == index)
                    {
                        reportexamenlabels.push(evaluation.sectionclasslesson.lesson.short_entitled_lesson);
                        reportexamendata.push(noteExamen + evaluation.note.note);
                    }
    
                    noteExamen = noteExamen + evaluation.note.note;
                }
                else
                {
                    if(evaluation.sectionclasslesson.lesson &&  evaluation.sectionclasslesson.lesson.short_entitled_lesson != lessonCours)
                    {
                        if(lessonCours != null)
                        {
                            reportcourslabels.push(lessonCours);
                            reportcoursdata.push(noteCours);
                            lessonCours = null;
                            noteCours = 0;
                        }
                        lessonCours = evaluation.sectionclasslesson.lesson.short_entitled_lesson;
                    }
                    else if(evaluations.data.length-1 == index)
                    {
                        reportcourslabels.push(evaluation.sectionclasslesson.lesson.short_entitled_lesson);
                        reportcoursdata.push(noteCours + evaluation.note.note);
                    }
    
                    noteCours = noteCours + evaluation.note.note;
                }
            })
        }
        
        this.setState({
            reportexamendata : reportexamendata,
            reportexamenlabels : reportexamenlabels,
            reportcoursdata : reportcoursdata,
            reportcourslabels : reportcourslabels
        })
   
    }

    async getStudentschoolfees(){
        var schoolfees =  await axios.get('/students/schoolfees/'+this.state.student.id)
        if(schoolfees.status === 200)
        {
            var month = 0, mount = 0, schoolfeedata = [];
            schoolfees.data.map((schoolfee, index) => {
                 
                if(month != schoolfee.month.month)
                {
                    if(month != 0)
                    {
                        schoolfeedata.push(mount);
                        mount = 0;
                    }
                    month = schoolfee.month.month;
                }
                
                mount = mount + schoolfee.pay_mount;
                
                if(schoolfees.data.length - 1 == index)
                {
                    schoolfeedata.push(mount);
                }

            })
            
        }
        
        for (let index = schoolfeedata.length; index < 12; index++) {
            schoolfeedata.push(0);
        }
        
        this.setState({
            schoolfeedata : schoolfeedata
        })
        
    }

    async getUser(){
        var student = await axios.get("students/"+this.props.match.params.studentId);
        
        if(student.status === 200)
        {
            this.setState({
                student : student.data
            })
            this.getStudentreports()
            // this.getStudentschoolfees()
            // this.getStudentreportexamens()
        }
    }

    showModal = (student = null, classe=false) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : student,
            classe : classe ? student : null
        })
        
        this.getUser()
    }

    delete = (student) =>{
        axios.delete("students/"+student).then((response) => {
            this.setState({ student : []})
            this.getUser()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

  handleCheck(val) {
    var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
     return  permissions.some(item => val === item.permission);
 }


    render(){
        var matricule =this.state.student.matricule; 
        var last_name = this.state.student.user ? this.state.student.user.last_name : "";
        var first_name = this.state.student.user ? this.state.student.user.first_name : "";
        var family_name = this.state.student.user ? this.state.student.user.family_name : "";
        var classe= this.state.student && (this.state.student.sectionclass && (this.state.student.sectionclass.class.short_entitled_class + " " + this.state.student.sectionclass.title +" # "+ this.state.student.sectionclass.section.short_entitled_section )); 
        return(
            
        <div className="container">
                <h5 className="h5 font-monospace font-monospace">Profil de l'élève </h5>
                
            <div className="row">
                <div className="col-md-5">
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="text-center text-primary"><Link to={"/studentfile/" + this.props.match.params.studentId} className="border border-primary py-0 px-1 rounded rounded-3 text-decoration-none"> Voir la fiche d'élève</Link></div>
                            <div className="d-md-flex">
                                <div className="image mr-3">
                                    <img src={this.state.student && (this.state.student.user.avatar)} className="rounded-circle img-fluid" width={this.state.student && (this.state.student.user.avatar && ("100"))} /> 
                                </div>
                                <div className="mt-4">
                                    <div  className="fw-bolder font-monospace">
                                        <div>{this.state.student && (this.state.student.matricule )} # {this.state.student && ( this.state.student.user.identity_number)}</div>
                                        <div>{ last_name + " " + family_name + " " + first_name} </div>
                                    </div>
                                    
                                    <div className=" m-1">
                                    
                                        <span className="badge bg-success small rounded-3 mx-1" type="button">élève</span>
                                             
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <div className="fw-bolder text-center font-monospace">Apropos de l'élève # { this.state.student ? this.state.student.year ? this.state.student.year.year : "" : "" } </div><hr />
                                <div className="col-md-6">
                                    <div>
                                        <a href={"tel:"+this.state.student && (this.state.student.user.tel)} rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.tel)}</a> </div>
                                    <div>
                                        <a href={"mailto:"+this.state.student && (this.state.student.user.email)} rel="noopener noreferrer" target="_blank"  className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.email)}</a> </div>
                                
                                    <div>
                                        <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.adress)}
                                    </div>
                                    <div>
                                        <FeatherIcon icon="home" size="16" className={"text-primary "}></FeatherIcon> { classe } 
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <FeatherIcon icon="users" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.user.first_name + " "+ this.state.student.user.user.last_name)} 
                                    <div>
                                        <a href={"tel:"+this.state.student && (this.state.student.user.user.tel)} rel="noopener noreferrer" target="_blank" className="text-dark text-decoration-none"> <FeatherIcon icon="phone" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.user.tel)}</a> </div>
                                    <div>
                                        <a href={"mailto:"+this.state.student && (this.state.student.user.user.email)} rel="noopener noreferrer" target="_blank" className="text-dark text-capitalize text-decoration-none"> <FeatherIcon icon="mail" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.user.email)}</a> </div>
                                    <div>
                                        <FeatherIcon icon="map-pin" size="16" className={"text-primary "}></FeatherIcon> {this.state.student && (this.state.student.user.user.adress)}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="text-end me-2 d-end mt-2">
                        {
                            this.handleCheck("Modifier élève") == true && ( 
                                <button onClick={ () => this.showModal(this.state.student)} className="btn btn-outline-primary rounded-3 btn-sm mx-1" title="Modifier cet enregistrement"><FeatherIcon icon="user-plus" className={"d-inline"} size="13"></FeatherIcon><span className="d-inline">Editer</span></button>
                            )
                        }
                        {
                            this.handleCheck("Supprimer élève") == true && ( 
                                <button onClick={ () => this.delete(this.state.student.id)} className="btn btn-outline-danger rounded-3 btn-sm mx-1" title="supprimer definitivement cet enregistrement"><FeatherIcon icon="user-x" className={"d-inline"} size="13"></FeatherIcon><span className="d-inline">Supprimer</span></button>
                            )
                        }
                        </div>
                    </div>

                    {/* students */}
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Parcours de l'élève</div>
                            
                            { this.state.student.user.id && ( 
                                <Reportcard 
                                    user={this.state.student.user.id}
                                    title="Parcours de l'élève"
                                    matricule ={matricule}
                                    name={last_name + " " + family_name + " " + first_name} 
                                    classe={ classe }  
                                 />
                                )
                            }
                        </div>
                    </div>

                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Statistique parcours</div>
                            {
                                this.state.reportlabels.length > 0 && (
                                <StudentBar
                                    data={this.state.reportdata}  
                                    labels={this.state.reportlabels}
                                    title={"Statistique parcours"}
                                    matricule ={matricule} 
                                    name={ last_name + " " + family_name + " " + first_name } 
                                    classe={ classe }  
                                />)
                            }
                            
                        </div>
                    </div>
                     
                    <div className=" ">
                        { this.state.student.id && ( 
                            <Reportcardnote 
                                student={this.state.student.id} 
                                category={"Cours"} 
                                title={"evaluations"} 
                                matricule ={matricule} 
                                name={ last_name + " " + family_name + " " + first_name } 
                                classe={classe }
                            /> 
                            )
                        }
                    </div> 



                    <div className="col-md-12 d-none">
                        <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                            <div className="card-body ">
                                <div className="fw-bolder text-center font-monospace">Notifier l'élève</div>
                                { this.state.student.user.id && ( <Chat userRecever={this.state.student.user} /> ) }
                            </div>
                        </div>
                    </div>
                    
                </div>

                <div className="col-md-7">
                    {/* paiements */}
                    <div className="card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Paiements de l'élève</div>
                            { this.state.student.user.id && ( 
                                <Schoolfee 
                                    user={this.state.student.id}
                                    title={"Paiements de l'élève"}
                                    matricule ={matricule} 
                                    name={ last_name + " " + family_name + " " + first_name } 
                                    classe={ classe } 
                                />
                                )
                            }
                        </div>
                    </div>

                    <div className="d-none card shadow overflow-hidden mb-3 overflow-hidden rounded rounded-3">
                        <div className="card-body ">
                            <div className="fw-bolder text-center font-monospace">Statistique paiements</div>
                            
                            {
                                this.state.schoolfeedata.length === 12 && ( 
                                <StudentLine 
                                    data={this.state.schoolfeedata}
                                    title={"Statistique paiements"}
                                    matricule ={matricule} 
                                    name={ last_name + " " + family_name + " " + first_name } 
                                    classe={ classe }  
                                /> )
                            }
                        </div>
                    </div>


                    <div className=" ">
                        { this.state.student.id && ( 
                            <Reportcardnote 
                                student={this.state.student.id} 
                                category={"Examen"} 
                                title={"examen"} 
                                matricule ={matricule} 
                                name={ last_name + " " + family_name + " " + first_name } 
                                classe={classe }
                            /> 
                            )
                        }
                    </div> 

                    <div className=" ">
                        { this.state.student.id && ( 
                            <Reportcardnote 
                                student={this.state.student.id} 
                                category={"Deuxieme session"} 
                                title={"deuxieme session"} 
                                matricule ={matricule} 
                                name={ last_name + " " + family_name + " " + first_name } 
                                classe={classe }
                            /> 
                            )
                        }
                    </div> 


                </div>

            </div>

            <StudentForm
                isModal={this.state.isModal} 
                formData={this.state.formData}
                classe={this.state.classe}
                showModal={this.showModal}
            />

        </div>
        )
    }
}
export default  Profile