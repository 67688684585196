import axios from "axios";
import React, {Component} from "react";
import FeatherIcon from 'feather-icons-react';
import ProfileForm from "./ProfileForm";
import { toast } from "react-toastify";

class Profile extends Component{

    state = {
        isModal : false,
        formData : null,
        profiles : [],
        loading : false
    }

    componentDidMount(){
        this.getProfiles();
    }

    async getProfiles(){
        this.setState({
            loading : false
        })
        
        var profiles = await axios.get('profiles');
        
        if(profiles.status === 200)
        {
            this.setState({profiles : profiles.data, loading : true})
        }
    }

    showModal = (profile = null) => {
        this.setState({
            isModal : !this.state.isModal,
            formData : profile,
        })

        if(profile === null)
            this.getProfiles()
    }

    delete = (profile) =>{
        axios.delete("profiles/" + profile).then((response) => {
            this.getProfiles()
            toast(response?.data?.msg, { theme : "light", style : { backgroundColor : "blue", color : "white"}})
        }).catch((error)=>{
            toast(error.response?.data?.msg, { theme : "light", style : { backgroundColor : "red", color : "white"}})
        })
    }

    handleCheck(val) {
        var permissions = localStorage.getItem("permissions") ? JSON.parse(localStorage.getItem("permissions")) : [];
         return  permissions.some(item => val === item.permission);
     }

    render(){
        var count = 1, profiles =
        this.state.profiles.map((profile) => {
            if(profile.model === this.props.model)
            {
                return(
                    <tr key={profile.id}>
                        <td>{count++}</td>
                        <td>{ profile.profile }</td>
                        <td><span className="">{ profile.userprofiles.length } Utilisateur(s)</span></td>
                        <td className="text-end">
                            {
                                this.handleCheck("Modifier profil") == true && (
                                <a href="#" onClick={ () => this.showModal(profile)}><FeatherIcon icon="edit-3" className={"d-inline"} size="13"></FeatherIcon></a>
                            )}
                            {
                            this.handleCheck("Supprimer profil") == true && (
                                <a href="#" onClick={() => this.delete(profile.id)}><FeatherIcon icon="delete" className={"d-inline ms-3 text-danger"} size="13"></FeatherIcon></a>
                            )}
                        </td>
                    </tr>
                )
            }
        })
        return(
            <div className="col-12">
                <div className="card shadow bg-white mb-2">
                    <div className="card-header bg-white">
                        <span className="h6">Liste des profils des utilisateurs</span>
                        {
                            this.handleCheck("Afficher profil") == true && (
                                <div className="btn-toolbar mb-2 mb-md-0 d-end float-end">
                                    <button type="button" className="btn btn-sm btn-outline-primary" onClick={() => this.showModal(null)}><i className="ti ti-plus font-18"></i> Nouveau profil</button>
                                </div>
                            )
                        }
                    </div>
                    <div className="card-body">
                        <div className="table-responsive">
                            <table className="table table-striped mb-0" style={{  fontSize : 13 + "px" }}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Profile</th>
                                        <th>Nombre des utilisateurs</th>
                                        <th className="text-end">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    { profiles }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <ProfileForm
                    isModal={this.state.isModal}
                    formData={this.state.formData}
                    showModal={this.showModal}
                    model = {this.props.model}
                />
            </div>

        )
    }
}
export default Profile;
